import React from 'react'
import ServiceCompTwo from '../Service/ServiceCompTwo'
import IMG from "../Home/ServiceIMG/Block.svg"
function OtherService(props) {
    return (
        <>
            <div className="container-fluid px-4 service-casestudy-section">
                <div className="container text-center my-5 service-casestudy">
                    <h1 className="service-casestudy-heading mb-2">Looking for <span className="light-heading">other services?</span> </h1>
                    <p className=" para">Check out the other services that we have in our range. We deliver high-quality products on time. Hassle-free.</p>
                </div>

                <div className="container-fluid py-5">
                    <div className="row text-center">
                        <div className="col-lg-6 col-md-6 text-center mb-5">
                            <div className="container-fluid service-wrapper">
                                {props.fcode1}
                                <h1 className="my-4 service-sub-heading">{props.subTitleone}</h1>
                                <p className="my-2 mx-auto w-50 service-para">{props.paraone}</p>
                                <button className="btn btn-serv "><a href={props.linkone}>Learn More</a></button>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center mb-5">
                            <div className="container-fluid service-wrapper">
                                {props.fcode2}
                                <h1 className="my-4 service-sub-heading">{props.subTitletwo}</h1>
                                <p className="my-2 mx-auto w-50 service-para">{props.paratwo}</p>
                                <button className="btn btn-serv "><a href={props.linktwo}>Learn More</a></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OtherService
