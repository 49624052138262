
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
// import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function UX() {

    const [cardData, setcardData] = useState([
        {
            "header": "Improve conversion",
            "para": "Successfully increase the number of leads and sales from your website."
        },
        {
            "header": "Streamline user experience",
            "para": "Uncover bottlenecks in the user journey and fix them."
        },
        {
            "header": "Sell with relevant content",
            "para": "Give users the right information at the right time and in the right place."
        },
        {
            "header": "Experienced Team",
            "para": "With over 600 successful projects, we’re here to ensure your start is as frictionless as possible."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Maximize value across your business with UX Review</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">
                    Use powerful insights to build the right solution for your business, market, and end-user</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Accelerate growth with stunning user experiences
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Successful products and services are not only visually appealing to your audience but are also highly tailored to users’ needs. Using UX expert analysis based on widely renowned usability heuristics, established industry patterns, and the right design tools, UX Design Review can ensure you are providing your users with the ultimate user experience and increase business value.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >UX Review enhances your competitiveness</h1>
                                    <p className="para text-center">Use the analysis of the market and review of user data that bring you closer to the customers. Make informed decisions to optimize your products, services, and projects in the context of your market, and add value by looking at the wider business picture. UX Review provides recommendations about the way you can obtain the results listed above.
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Design processes based on best practices and proven methodologies that deliver
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">At Neurastats, we have spent years honing our processes and incorporating industry best practices to provide the highest standards of Design Review, UX and UI. While your users’ needs and behaviors may change over time, we have the proven tools and battle-tested methodologies to consistently deliver exceptional products and services, ensuring superior user experience every time.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">SThe UX Review process</h1>
                                            <p className="px-4 para">A user experience review is a professional analysis that helps you focus on building the right solution for your business, market, and end-user. A review can be carried out on products and services, such as your website, mobile app, or web application, and even extended to different types of projects or disciplines to maximize value creation. <br />
                                                The results of a user experience review are compared with your industry's established standards to determine whether your solution is effective. <br />
                                                When evaluating products like a mobile or web app, we ensure: that the app is usable and critical user paths are flawless; the interface is visually appealing, intuitive, and usable; the content is well-suited to the intended target users; and the app's copy is free of jargon.
                                                <br />
                                                Following the review, you will receive a comprehensive data-driven report assessing the potential of your app or project to meet your current and future needs. When looking at solutions like a web application or mobile app, our focus is on whether it: is market-ready; fits the market in terms of scope and range; meets the needs of your user/customer base; and is reliable and flexible enough to scale up.
                                                <br />
                                                At Neurastats, we also go beyond this to look at the wider business picture, ensuring that projects meet your business goals, generate value, and support a roadmap for future improvements.

                                            </p>
                                        </div>
                                        <div className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Benefits of UX Review</h1>
                                            <p className="px-4 para">With a UX review, you can quickly spot all issues that could have a negative impact on your product, service, or project and take steps to increase its business value.
                                            </p>
                                            <ul>
                                                <li>Compare your solution to those of your competitors and find out whether the interests of stakeholders align.</li>
                                                <li>Compare your solution to those of your competitors and find out whether the interests of stakeholders align.</li>
                                                <li>Compare your solution to those of your competitors and find out whether the interests of stakeholders align.</li>
                                                <li>End up with a clear and simple roadmap of your next steps and reduce the costs of software development.</li>
                                            </ul>
                                        </div>
                                        <div className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Reviewing user interface</h1>
                                            <p className="px-4 para">A UI review follows a similar approach to reviewing design elements in terms of classifying issues. Depending on the reviewer and the needs of the project, the analysis may place emphasis on various factors such as user flows, UI design, interactions, and accessibility, to name just a few.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#ss" className="content-a">Scoping sessions: the beginning</a></p>
                                            <p className="px-4"><a href="#mvp" className="content-a">Estimations and MVP</a></p>
                                            <p className="px-4"><a href="#pc" className="content-a">Scoping session with materials prepared by the prospective customer</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Digital product design"} paraone={"Solve real human problems and provide the ultimate UX for your web and mobile applications."} subTitletwo={"Web development"} paratwo={"Cutting-edge technology and reliable solutions tested in battle."} fcode1={<i class="fas fa-pencil-ruler"></i>} fcode2={<i class="fas fa-globe-americas"></i>} linkone={"/service/pd/pdsn"} linktwo={"/service/wd/wd"} />
        </>
    )
}

export default UX
