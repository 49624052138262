import React from 'react'

function DP6(props) {
    return (
        <>
            <div className="container dp6-container py-5">
                <h1 className="dp6-number">{props.number}</h1>
                <h1 className="dp6-title">{props.title}</h1>
                <p className="dp6-para w-75">{props.para}</p>
            </div>

        </>
    )
}

export default DP6
