import React from 'react'

function CoreVCARD(props) {
    return (
        <>
            <div className={`container corValCard p-4 ${props.margin}`}>
                <div className="img-box">
                    <img src={props.img} alt="" />
                </div>
                <div className=" context-box ml-4">
                    <h1 className="mb-5">{props.title}</h1>
                    <p className="mb-2">{props.para}.</p>
                </div>
            </div>
        </>
    )
}

export default CoreVCARD
