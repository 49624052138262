import React from 'react'
import DP6 from './DP6'
function Process() {
    return (
        <>
            <section className="about-6dprocess ">
                <div className="container-fluid px-4">
                    <div className="container-fluid px-5 dp6-heading">
                        <h1 className="heading2">Our <span className="light-heading">6D</span>  Process</h1>
                    </div>
                    <div className="container-fluid px-5 dp6-box">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 my-5">
                                <DP6 title="Discover" number="01." para="We sit down with you and discover what you are currently doing, what you want to do, and who your target market in order to deliver the best solution." />
                            </div>
                            <div className="col-lg-4 col-md-4 my-5">
                                <DP6 title="Define" number="02." para="Depending on the problem, we will define a solution and map it out for you, step by step so that we are all on the same page." />
                            </div>
                            <div className="col-lg-4 col-md-4 my-5">
                                <DP6 title="Design" number="03." para="Depending on your requirements, we will design a custom solution for you." />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 my-5">
                                <DP6 title="Develop" number="04." para="We sit down with you and discover what you are currently doing, what you want to do, and who your target market in order to deliver the best solution." />
                            </div>
                            <div className="col-lg-4 col-md-4 my-5">
                                <DP6 title="Deploy" number="05." para="We will deploy a live version of your solution, so that you can see first-hand how the solution will work, honk the horn and ring the bells.
At this stage the solution will be in greyscale, so as not to confuse you with the complex details, but to let you see how it works in real time." />
                            </div>
                            <div className="col-lg-4 col-md-4 my-5">
                                <DP6 title="Deliver" number="06." para="We don’t just deliver, handover the keys and let you drive off into the sunset. We will be your constant support till the product’s life which is actually never ending." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Process
