import React from 'react'
import CaseStudy from './CaseStudy'
import Customer from './Customer'
import Header from './header'
import Header2 from './Header2'
import Service from './Service'
import Navbar from "../Navbar/Navbar"
import Crousel from './Crousel'
import Estimate from '../EstimateProject/Estimate'
function Home() {


    return (
        <>
            <Navbar />
            <Estimate />
            <Crousel />
            <Header2 />
            <Service />
            <Customer />
            <CaseStudy />

        </>
    )
}

export default Home
