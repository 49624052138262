import React, { useRef, useState } from 'react'
import "./Estimate.css"
import emailjs from 'emailjs-com';
import validator from 'validator'
function OpenPos() {
    const modal = useRef(null)
    const [formMSG2, setFormMSG2] = useState(<p></p>)
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [msg, setmsg] = useState(<p></p>)
    const [toastMsg, setToastMsg] = useState(<p></p>)

    const sendEmail = async (e) => {
        e.preventDefault();
        if (name === "") {
            setFormMSG2(<p className="ml-2 error">
                Please enter valid Name
            </p>)
        }
        else {
            setFormMSG2(<p className="ml-2 error">

            </p>)
        }
        if (email === "") {
            setmsg(<p className="ml-2 error">
                Please enter valid email
            </p>)
        } else {
            if (validator.isEmail(email)) {
                setmsg(<p></p>)
                try {
                    const result = await emailjs.sendForm('service_lsmfvtk', 'template_e15qjxg', e.target, 'user_3oL0OoZvgtHcOdpxlBKNC')
                    const final = await result;
                    console.log(final);
                    setToastMsg(<p className="ml-2 success">
                        Thanks for enquiring with Neurastats, our team will contact you promptly to discuss next steps {name}
                    </p>)
                    e.target.reset()
                    setname("")
                    setemail("")
                    setmsg("")
                } catch (error) {
                    console.log(error)
                    setToastMsg(<p className="ml-2 error">
                        "Message not send ! Please check your internet connection"
                    </p>)
                }

            } else {
                setmsg(<p className="ml-2 error">
                    Please enter valid Email
                </p>)
            }
        }

    }

    return (
        <>

            <div id='modal' className="modal" id="exampleModalopen" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Apply for <span className="light-heading">Job</span></h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                        </div>
                        <div className="modal-body">
                            <form onSubmit={sendEmail} noValidate={true}>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Name</label>
                                    <input type="text" className="form-control" id="recipient-name" name="name" value={name} onChange={e => setname(e.target.value)} />
                                    {formMSG2}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-email" className="col-form-label">Email</label>
                                    <input type="email" className="form-control" id="recipient-email" name="email" value={email} onChange={e => setemail(e.target.value)} />
                                    {msg}
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Job Position</label>
                                    <input type="text" className="form-control" id="recipient-name" name="position" />
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Phone</label>
                                    <input type="text" className="form-control" id="recipient-name" name="phone" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Linkedin</label>
                                    <input type="text" className="form-control" id="recipient-name" name="linkedin" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Github</label>
                                    <input type="text" className="form-control" id="recipient-name" name="github" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Portfolio</label>
                                    <input type="text" className="form-control" id="recipient-name" name="portfolio" />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Resume</label>
                                    <input type="file" className="form-control" id="recipient-name" name="resume" />
                                </div> */}

                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Additional Info</label>
                                    <textarea className="form-control" id="message-text" name="message"></textarea>
                                </div>

                                <div className="modal-footer">
                                    {toastMsg}
                                    <button type="submit" className="btn ml-5 py-2 px-5 modal-btn-send">Apply</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default OpenPos
