import React, { useEffect } from "react";
import Logo from "../logowhite.svg";
import "./Navbar.css";
function Navbar2(props) {
  var lastScrollTop = 0;
  window.addEventListener("scroll", function () {
    var navbar = document.querySelector("#navbar2");
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    navbar.style.boxShadow = "0px 0px 8px #00000033";
    if (scrollTop < 80) {
      navbar.style.boxShadow = "none";
    }
    if (scrollTop > lastScrollTop) {
      navbar.style.top = "-100px";
    } else {
      navbar.style.top = "0px";
    }
    lastScrollTop = scrollTop;
  });

  const hideELE = (e) => {
    document.querySelector(".navbar-brand").classList.toggle("hideit");
  };

  useEffect(() => {
    const navLink = document.querySelectorAll(".nav-link");
    const path = window.location.pathname;
    navLink.forEach((link) => {
      if (link.href.includes(`${path}`)) {
        console.log("hello");
        console.log(link.href);
        link.classList.add("activeNav");
      } else {
        link.classList.remove("activeNav");
      }
    });

    console.log(path);
  });

  return (
    <>
      <header id="navbar2" className={`${props.navClass}`}>
        <nav className="navbar navbar-expand-lg  navbar-light bg-none">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="" className="img-fluid" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={hideELE}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse navbar-anime"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto">
              <li className="nav-item px-3 activeNav">
                <a className="nav-link" href="/home">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="/service">
                  Services
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="/about">
                  About Us
                </a>
              </li>

              {/* <li className="nav-item px-3">
                <a className="nav-link" href="/CaseStudy">Case Studies</a>
              </li> */}
              <li className="nav-item px-3">
                <a className="nav-link" href="/Career">
                  Careers
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="/contact">
                  Contact Us
                </a>
              </li>
            </ul>
            <form className="form-inline my-2 my-lg-0 mx-3">
              <a
                href="https://calendly.com/shreeyash_haritashya/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn my-2 my-sm-0 px-3 Req-btn"
                  type="button"
                  data-toggle="modal"
                >
                  Schedule a call
                </button>
              </a>
            </form>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar2;
