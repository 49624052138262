
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function IOS() {

    const [cardData, setcardData] = useState([
        {
            "header": "Established user base",
            "para": "Apple’s performance and quality has built a loyal, established user base to deliver apps to."
        },
        {
            "header": "Data security",
            "para": "Protect against hacking and malware with robust security tools and data encryption."
        },
        {
            "header": "Tech-savvy audience",
            "para": "iOS cross-device integration attracts tech-savvy users, facilitating market penetration in key areas."
        },
        {
            "header": "Shorter development time",
            "para": "iOS apps are 28% faster to develop than Android apps, with fewer variables to test against."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Delight your audience with superb iOS mobile applications</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Use cutting-edge technology to build native mobile applications that’ll wow your target market.</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Enhance your brand with iOS application development
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">There are 2.2M apps in Apple’s App Store, with quarterly revenues of around $25BN. By combining business objectives and smart technology solutions, you can tap into this market to boost your revenue and establish your brand’s image with a professional user base</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Accelerate business results with iOS app development</h1>
                                    <p className="para text-center">Tap into an established, tech-savvy audience to achieve higher revenue from app development
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Build a competitive advantage with smart iOS development solutions
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">With the best iOS app development services, you can crack the mobile market and grow your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="one" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">iOS app development - everything you need to know</h1>
                                            <p className="px-4 para">We build iOS apps using a range of technology stacks. Which one we use will depend on your project specifications.
                                                <br />
                                                Objective-C is a long-standing, highly trusted programming language that uses dynamic typing and message passing. It also requires dividing classes into two code blocks: interface and implementation.
                                                <br />
                                                Swift is a relatively new programming language, which Apple released to the public in 2014. In 2015, Swift became open-source. It abandons the Objective-C heritage and has all the modern programming features: generics, optionals, type inference, and higher-order functions.
                                                <br />
                                                There are other technology stacks, such as React Native or Flutter, which suit cross-platform development and can offer a quicker time to market
                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">What is an iPhone app?</h1>
                                            <p className="px-4 para">An iPhone app is a software application designed to run on Apple’s iOS-powered iPhone devices.
                                                <br />
                                                iOS stands for iPhone Operating System. iPhone applications are available via Apple’s App Store. Apps developed for iOS are also available on Apple’s other devices, such as iPad and iPod touch.
                                                <br />
                                                iPhone app developers can programme their own apps for download using sample code projects from Apple’s SDK.
                                                <br />
                                                You can put a price on your app if you wish – the revenue is split between the developer (70%) and Apple (30%). You can also make your app free to download. Apple also supports in-app purchases, which offer an alternative revenue stream for app developers.
                                            </p>
                                        </div>
                                        <div id="three" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Benefits of native iOS app development</h1>
                                            <p className="px-4 para">iOS apps bring in much more revenue than Android apps despite having a smaller market share. In 2019 (Q2), Apple users spent approximately $25bn on apps and in-app purchases.
                                                <br />
                                                As a result, successful iOS mobile app development can open up new revenue streams for your business and deliver a high ROI on the cost of building your app.
                                                <br />
                                                Apple releases new iOS-powered products each year to a stable, growing, and tech-savvy audience, enabling iPhone app developers to tap into this high-potential market.
                                                <br />
                                                Native apps also offer optimum performance because they are designed for specific operating systems, which increases processing speed and delivers better data security.
                                                <br />
                                                iOS apps also benefit from the iPhone or iPad interface, which is highly intuitive and interactive, delivering excellent usability and user experience.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#one" className="content-a">iOS app development - everything you need to know</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">What is an iPhone app?</a></p>
                                            <p className="px-4"><a href="#three" className="content-a">Benefits of native iOS app development</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Python Development"} paraone={"Python is currently one of the fastest growing languages to get your job done on time."} subTitletwo={"iOS development"} paratwo={"Develop top-quality iOS apps with cutting edge technology."} fcode1={<i class="fab fa-python"></i>} fcode2={<i class="fab fa-apple"></i>} linkone={"/service/wd/py"} linktwo={"/service/md/IOS"} />
        </>
    )
}

export default IOS
