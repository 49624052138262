
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function Ruby() {

    const [cardData, setcardData] = useState([
        {
            "header": "Build innovative prototypes fast",
            "para": "Enterprises can build MVPs and prototype extremely quickly to develop brand new ideas for the market or create powerful internal tools to help with digital transformation."
        },
        {
            "header": "Be secure and compliant",
            "para": "Ruby on Rails can help you become PSD2 compliant and is equipped with all the functions necessary to enable complete security for your application and clients."
        },
        {
            "header": "Easy and intuitive maintenance",
            "para": "Using the DRY principle allows your RoR developers to separate concerns making maintenance far easier – developers adore it for its beautiful syntax, and clients love it for the speed of development if offers."
        },
        {
            "header": "Launch new features easily",
            "para": "Rails makes use of the Model-View-Controller philosophy that promotes the modularity and extensibility of your web app meaning that no matter the complexity, it can quickly be extended with new features"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">RRuby on Rails consulting can help you develop high-performing applications fast</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Powerful and battle-tested technology for your web application</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Ruby on Rails gives you powerful pre-built features to launch your app fast
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Choosing the right technologies for web development is a challenge that every business has to face – and the right choice will give you a solid base for business growth.
                                            <br />
                                            Ruby on Rails (ROR) comes with an Object Relational Mapping System for data and logic in addition to an 'Out of the box’ routing and application management to help you get moving fast.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Build applications in record time to fuel ideas and innovation</h1>
                                    <p className="para text-center">Ruby on Rails lets you create complex and innovative ideas quickly
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Launch your app with a battle-tested technology that has powered the web since 2004
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Choosing the right technology can make the difference between success or failure once your product is live – Ruby on Rails has been trusted by big names, including Twitter.
                                            <br />
                                            Discover the top 34 companies using Ruby on Rails in 2019.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="ror" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Ruby on Rails development company</h1>
                                            <p className="px-4 para">Our Ruby on Rails web developers deliver source code that is efficient, concise, readable, maintainable, and clean. Neurastats can help you with solutions for web services and web-based apps.
                                                <br />
                                                We build scalable solutions and implement new functionalities within your existing web app or help with optimization.
                                                <br />
                                                Our Ruby on Rails consulting team, together with our Rails app developers, build web applications in a short time to help you launch your MVP as soon as possible

                                            </p>
                                        </div>
                                        <div id="ome" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Our methodology</h1>
                                            <p className="px-4 para">A professional team of external developers should be Agile. At Neurastats, we strive to be completely transparent with clients – we share our style guide, our internal documentation, and code review practices because we believe in sharing our knowledge and building trust.

                                            </p>
                                        </div>
                                        <div id="lev" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Leverage highly experienced Ruby on Rails developers</h1>
                                            <p className="px-4 para">It often doesn't make sense to invest in your own team of developers before you even have a proof of concept for your product – working with an external team is the cheaper option.
                                                <br />
                                                You don’t have to provide office space, equipment, and testing equipment – none of the material elements necessary for building an app. You can also forget about recruitment costs. You don’t need to worry about software and a test infrastructure. You can skip trying to gather the exact set of skills necessary for your project – a good agency will also offer DevOps, backend and frontend development, UI and UX design, as well as product and graphic design.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#ror" className="content-a">Ruby on Rails development companyy</a></p>
                                            <p className="px-4"><a href="#ome" className="content-a">Our methodology</a></p>
                                            <p className="px-4"><a href="#lev" className="content-a">Leverage highly experienced Ruby on Rails developers</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"UI design"} paraone={"Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product."} subTitletwo={"Branding"} paratwo={"Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers."} fcode1={<i class="fab fa-quinscape"></i>} fcode2={<i class="fas fa-copyright"></i>} linkone={"/service/pd/ui"} linktwo={"/service/pd/br"} />
        </>
    )
}

export default Ruby
