
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function RD() {

    const [cardData, setcardData] = useState([
        {
            "header": "Validate product idea",
            "para": "Reduce the risk of launching the wrong idea by testing if the idea works"
        },
        {
            "header": "Demonstrate business value",
            "para": "Prototype has enough value that people can already use or buy it"
        },
        {
            "header": "Evaluate the technology",
            "para": "A proof of concept can act as a feasibility study of the technology"
        },
        {
            "header": "Increase speed-to-market",
            "para": "Fast prototyping provides a feedback loop to guide future development of your product"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Get ahead with Research & Development Solutions</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Validate your idea in just 6 weeks. Experiment with ideas and technologies to secure cutting-edge products and sustained development</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Accelerate product growth with end-to-end innovation services
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Companies are starting to recognize that research and development are crucial for their survival. As a business, it seems exciting to be in the heart of the action, leading your innovation efforts, but without the right resources you might be doomed to fail. Investing in research and development projects helps to sustain a competitive market position and leads to the creation of new products and procedures.
                                            <br />
                                            In total, the top 1,000 companies spent around $858 billion on R&D in 2018. R&D spending worldwide equals $2 trillion annually. While those numbers are high mainly because of top R&D leaders like Google or Apple, more and more companies are realizing that investing in research and development pays off.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Validate your idea quickly with fast prototyping</h1>
                                    <p className="para text-center">R&D as a Service (RnDaaS) offers a shorter time-to-market of your innovation thanks to access to an experienced team of developers, ML engineers, QA specialists, and project managers. All that will give you the opportunity to implement emerging technologies from industry leaders.Research and Development as a Service can support your business in many ways:
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            The right process is the key advantage
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Research & Development is a process of a constant, iterative effort to make your product better and better. Make sure you have battle-tested methodologies to face your challenges.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="rd" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What is Research and Development?</h1>
                                            <p className="px-4 para">The goal of R&D is solving complex problems using cutting-edge knowledge that applies to business needs which may result in the development of new products and solutions that will make the organization more competitive on the market. R&D isn’t just about creating new products, it also involves researching new ways of doing things and adding new features

                                            </p>
                                        </div>
                                        <div id="si" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">What is sustained innovation?</h1>
                                            <p className="px-4 para">Having in mind that innovation involves the exploration of unknown technologies and business problems that have never been solved before, it is not surprising that tolerance for failure is an important characteristic of innovative companies. We have seen the biggest innovators fail: recall Apple’s MobileMe, Google Glass, and the Amazon Fire Phone. But tolerance for failure does not mean they do tolerate incompetence.
                                                <br />
                                                Moreover, a willingness to experiment does not mean working without any rules. Without discipline almost any initiative can be justified as an experiment. Discipline-oriented teams select experiments carefully looking at criteria such as their potential learning value and the potential of the technology to become the next big thing.
                                                <br />
                                                True innovation can be considered a sweet spot where it is psychologically safe, but requires putting in 120% of effort to ensure the success of the experiment.
                                                <br />
                                                Looking from the people perspective, R&D initiatives are a team effort, but there needs to be individual accountability to ensure the goals of the experiment are met.

                                            </p>
                                        </div>
                                        <div id="rnd" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Why do you need research and development as a service (RnDaaS)?</h1>
                                            <p className="px-4 para">By outsourcing research and development services, you can reduce your costs in a number of ways. You do not need to engage in recruitment and there is no staff to maintain and retrain, hence no ongoing costs. Instead, you are engaging a team of experts in the field.
                                                <br />
                                                When you come up with an idea, there is no new-recruit (or training) delay. The time-to-market is also reduced due to significant experience, which means a lower risk of failure for your initiative.
                                                Our existing team and infrastructure will allow you to focus on business value and delivering working products. While engaging in development innovation sprints, there should always be a deliverable at the end of each sprint which mitigates the risk of failure.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#rd" className="content-a">What is Research and Development?</a></p>
                                            <p className="px-4"><a href="#si" className="content-a">What is sustained innovation?</a></p>
                                            <p className="px-4"><a href="#rnd" className="content-a">What does a UI designer do?</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Digital product design"} paraone={"Solve real human problems and provide the ultimate UX for your web and mobile applications."} subTitletwo={"Web development"} paratwo={"Cutting-edge technology and reliable solutions tested in battle."} fcode1={<i class="fas fa-pencil-ruler"></i>} fcode2={<i class="fas fa-globe-americas"></i>} linkone={"/service/pd/pdsn"} linktwo={"/service/wd/wd"} />
        </>
    )
}

export default RD
