import React from 'react'
import "./Card.css"
function AboutCard(props) {
    return (
        <>
            <div className="about-card mx-auto py-5">
                <div className="container about-card-text pt-2 px-4">
                    <h1 className="my-3">{props.title}</h1>
                    <p>{props.para}</p>
                </div>
            </div>
        </>
    )
}

export default AboutCard
