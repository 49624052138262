import React from 'react'
import Header from './Header'
import Benefit from './Benefit'
import OveriewCard from './OveriewCard'
function Overview(props) {
    return (
        <>
            <section className="Overview-section">
                <div className="container-fluid overview-header">

                    <div className="row over-header-row mx-5">
                        <div className="col-lg-6 col-md-6">
                            <h1 className="over-heading">
                                {props.otitle}
                            </h1>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <p className="para">{props.opara}</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid benefit-section my-5">
                    <div className="container benefit-header my-5 py-4">
                        <h1 >{props.oheader}</h1>
                        <p className="para text-center">{props.oheadpara}</p>
                    </div>
                    <div className="container-fluid my-5 py-4">
                        <div className="row">
                            {
                                props.card.map((val, ind) => {
                                    return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} /></div>
                                })
                            }
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Overview
