
import React, { useEffect, useRef } from 'react'
import ServiceCompThree from './ServiceCompThree';
import ServiceCompTwo from './ServiceCompTwo';
function ServicetabTwo() {
    var value = useRef(null)
    console.log(value);



    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar, activetag;
    function myFunction() {
        const acDesi = document.getElementById("acDesi")
        const acApp = document.getElementById("acApp")
        const acWeb = document.getElementById("acWeb")
        const acTes = document.getElementById("acTes")
        const acBloc = document.getElementById("acBloc")
        const acAI = document.getElementById("acAI")
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
            navbar.style.margin = "0"
        } else {
            navbar.style.top = "55px"
            navbar.style.margin = "0.1rem 0"
        }
        lastScrollTop = scrollTop;

        activetag = document.querySelector(" a.achorActive")

        // if (activetag && (window.outerWidth < 500)) {
        //     activetag.scrollIntoView({ behavior: "smooth", inline: "center" });
        // }


        if (window.pageYOffset > (document.getElementById("Ideation").offsetTop - 100)) {
            acDesi.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acWeb.classList.remove("achorActive")
            acTes.classList.add("achorActive")
            acAI.classList.remove("achorActive")
            acBloc.classList.remove("achorActive")

        }

        if (window.pageYOffset > (document.getElementById("design").offsetTop - 100)) {

            acDesi.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acWeb.classList.remove("achorActive")
            acTes.classList.remove("achorActive")
            acAI.classList.remove("achorActive")
            acBloc.classList.remove("achorActive")

        }
        if (window.pageYOffset > (document.getElementById("Wdevelopment").offsetTop - 100)) {
            acDesi.classList.remove("achorActive")
            acWeb.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acTes.classList.remove("achorActive")
            acAI.classList.remove("achorActive")
            acBloc.classList.remove("achorActive")

        }
        if (window.pageYOffset > (document.getElementById("Adevelopment").offsetTop - 100)) {
            acDesi.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acWeb.classList.remove("achorActive")
            acTes.classList.remove("achorActive")
            acAI.classList.remove("achorActive")
            acBloc.classList.remove("achorActive")

        }

        if (window.pageYOffset > (document.getElementById("Blockchain").offsetTop - 100)) {
            acDesi.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acWeb.classList.remove("achorActive")
            acTes.classList.remove("achorActive")
            acBloc.classList.add("achorActive")
            acAI.classList.remove("achorActive")
        }
        if (window.pageYOffset > (document.getElementById("AI").offsetTop - 100)) {
            acDesi.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acWeb.classList.remove("achorActive")
            acTes.classList.remove("achorActive")
            acBloc.classList.remove("achorActive")
            acAI.classList.add("achorActive")
        }
        if (window.pageYOffset > (document.getElementById("AI").offsetTop + 800)) {
            acDesi.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acWeb.classList.remove("achorActive")
            acTes.classList.remove("achorActive")
            acAI.classList.remove("achorActive")
            acBloc.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <div className="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar px-0 py-2 " id="serStiky" ref={value}>
                    <div className="navbox-cont">
                        <a href="#Ideation" id="acTes" >Ideation</a>
                        <a href="#design" id="acDesi" >Product design</a>
                        <a href="#Wdevelopment" id="acWeb" >Web Development</a>
                        <a href="#Adevelopment" id="acApp" >App Development</a>
                        <a href="#Blockchain" id="acBloc" >Blockchain</a>
                        <a href="#AI" id="acAI" >AI/ML</a>
                    </div>
                </div>
                <div className="container-fluid tabs-contents">

                    <section id="Ideation" className="serviceTabSection py-2 odd">
                        <ServiceCompTwo title={'Ideation and evaluation'}
                            para={"When you have a brilliant idea, it's very tempting to immediately jump into the development of your product. However, it's essential to define what exactly you're trying to achieve to accomplish your business goals."}

                            subTitleOne={"Product design sprint"} paraOne={"A five-day workshop to answer crucial business questions, plan the essential features of your product and reduce the risk of bringing a product to the market."} linkOne="/service/idl/pds"
                            fcode1={<i className="fab fa-creative-commons-sampling"></i>}

                            subTitleTwo={"Scoping session"} paraTwo={"A workshop aimed at shaping your business idea, answering questions regarding project planning, time estimation and budgeting"} linkTwo="/service/idl/ss"
                            fcode2={<i className="fas fa-star-half-alt"></i>}
                            subTitlethree={"UX review"} parathree={"An evaluation which will help you radically improve your product by eliminating all UX issues to get a truly appealing and streamlined experience that users will love."} linkthree="/service/idl/ux"
                            fcode3={<i className="fas fa-search-location"></i>}
                            subTitlefour={"Research & development"} parafour={"A validation of your idea in just 6 weeks. Experiment with ideas and technologies to secure cutting-edge products and sustained development.."} linkfour="/service/idl/rd"
                            fcode4={<i className="fab fa-stumbleupon"></i>}
                        />
                    </section>

                    <section id="design" className="serviceTabSection py-2">
                        <ServiceCompTwo title={"Product design"} para={"Product design is at the center of every company's success today. Design has become the key differentiator in most markets, and software products are no exception."} subTitleOne={"Product design"} paraOne={"Craft beautiful and engaging digital products that impress your customers from the first moment"}
                            fcode1={<i className="fab fa-stumbleupon"></i>}
                            subTitleTwo={"User experience design"} paraTwo={"Humanize your software user experience and provide additional value to your customers and your business."}
                            fcode2={<i className="fas fa-praying-hands"></i>}
                            subTitlethree={"UI design"} parathree={"Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product."}
                            fcode3={<i className="fab fa-uikit"></i>}
                            subTitlefour={"Branding"} parafour={"Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers."}
                            fcode4={<i className="fas fa-copyright"></i>}

                            linkOne={"/service/pd/pdsn"} linkTwo={"/service/pd/ue"} linkthree={"/service/pd/ui"} linkfour={"/service/pd/br"}

                        />
                    </section>


                    <section id="Wdevelopment" className="serviceTabSection py-2 odd">
                        <ServiceCompTwo title={"Web development"} para={"Software development is an ever-evolving process. Instead of a public repository, we start your project with private repository so that your software will be kept secure."}

                            subTitleOne={"Web development"} paraOne={"Implement beautiful and responsive user interfaces that are highly-interactive and give users a native-like experience."}
                            fcode1={<i className="fab fa-css3"></i>}
                            subTitleTwo={"Ruby on Rails"} paraTwo={"Make use of the perfect solution when the speed of development is crucial to your success."}
                            fcode2={<i className="fas fa-gem"></i>}
                            subTitlethree={"Node.js"} parathree={"Build performant web applications based on Javascript – one language for full-stack development."}

                            fcode3={<i className="fab fa-node-js"></i>}
                            subTitlefour={"Python"} parafour={"Python is currently one of the fastest growing languages to get your job done on time."}
                            linkOne={"/service/wd/wd"} linkTwo={"/service/wd/ruby"} linkthree={"/service/wd/nd"} linkfour={"/service/wd/py"}
                            fcode4={<i className="fab fa-python"></i>}
                        />
                    </section>

                    <section id="Adevelopment" className="serviceTabSection py-2">
                        <ServiceCompTwo title={"Mobile development"}
                            para={"Mobile applications are now more popular than ever and have increasingly become an essential part of any modern business. Neurastats aims of creating stunning mobile applications and powerful web apps along with with blazing-fast performance."}
                            fcode1={<i className="fas fa-mobile-alt"></i>}
                            subTitleOne={"Mobile development"} paraOne={"Get professional support from the mobile team at every stage of development."}
                            subTitleTwo={"iOS"} paraTwo={"Develop top-quality iOS apps with cutting edge technology."}
                            fcode2={<i className="fab fa-apple"></i>}
                            subTitlethree={"Android"} parathree={"Building beautiful and scalable mobile applications with top performance using the Java and Kotlin programming languages."}
                            fcode3={<i className="fab fa-android"></i>}
                            subTitlefour={"Flutter"} parafour={"Take your idea to the next stage and accelerate your product's go-to-market with Flutter development."}
                            fcode4={<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                width="30" height="30"
                                viewBox="0 0 172 172"
                                style={{ fill: "#000000" }}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0b2d3b"><path d="M91.87208,11.32792l-72.24,72.24c-1.34504,1.34504 -1.34504,3.52256 0,4.86416l19.21584,19.21584c1.34504,1.34504 3.52256,1.34504 4.86416,0l91.45584,-91.45584c2.1672,-2.1672 0.63296,-5.87208 -2.43208,-5.87208h-38.43168c-0.9116,0 -1.78536,0.3612 -2.43208,1.00792zM135.16792,84.99208l-59.48792,59.48792l-21.64792,-21.64792c-1.34504,-1.34504 -1.34504,-3.52256 0,-4.86416l37.84,-37.84c0.64672,-0.64672 1.52048,-1.00792 2.43208,-1.00792h38.43168c3.06504,0 4.59928,3.70488 2.43208,5.87208zM135.16792,155.80792l-25.08792,-25.08792l-34.4,13.76l16.19208,16.19208c0.64672,0.64672 1.52048,1.00792 2.43208,1.00792h38.43168c3.06504,0 4.59928,-3.70488 2.43208,-5.87208z"></path></g></g></svg>}
                            linkOne={"/service/md/MD"} linkTwo={"/service/md/IOS"} linkthree={"/service/md/AN"} linkfour={"/service/md/FLT"}

                        />
                    </section>

                    <section id="Blockchain" className="serviceTabSection py-2 odd">
                        <ServiceCompTwo title={"Blockchain"} para={"Uncensor your data without fear. Your Wealth. Your Security. Your Choice"} subTitleOne={"A first-className way to store data"} paraOne={"Get the benefit of storing data into public & private blockchain nodes. It's the fastest, cheapest, unique and efficient solution that fits the needs of everyone."} subTitleTwo={"Securing your data ledgers from attacks"} paraTwo={"Neurastats leverages this technology by creating an unique and efficient solution by which one can easily exchange smart property coins online."}
                            subTitlethree={"Selective Ops Team"} parathree={"Control access to your critical systems and engage a team of experts to support the operation, troubleshooting, and maintenance of the Blockchain environment services and applications."}
                            subTitlefour={"Disrupt industry and create new business models"}
                            parafour={"Neurastats can help you create solutions by using revolutionary blockchain technologies to solve a variety of business problems and deliver real business value."}

                            fcode1={<i className="fas fa-shield-alt"></i>} fcode2={<i className="fas fa-bug"></i>} fcode3={<i className="fas fa-mask"></i>} fcode4={<i className="fas fa-passport"></i>}
                        />


                    </section>
                    <section id="AI" className="serviceTabSection py-2 ">
                        <ServiceCompTwo
                            title={"Machine learning/AI"} para={"AI is taking the world by storm. Working in digital transformation, data science, machine learning, or AI are the future of the business and Neurastats takes proud on early adoption of it."}

                            subTitleOne={"Machine learning, data science, business intelligence and digital transformation"} paraOne={"Data science solutions turn your data into a competitive advantage."}
                            fcode1={<i className="fas fa-brain"></i>}
                            subTitleTwo={"Digital transformation"} paraTwo={"Digitize your processes and increase revenues, cut costs, improve security, and build new ways of organizing processes."}
                            fcode2={<i className="fab fa-digital-ocean"></i>}
                            subTitlethree={"Business intelligence"} parathree={"Transform data into actionable intelligence that informs an organization’s strategic and tactical business decisions."}

                            fcode3={<i className="fas fa-trademark"></i>}
                            subTitlefour={"Data annotation"} parafour={"Annotate data quickly and effectively through the community of professionals on our platform."}
                            fcode4={<i className="fas fa-database"></i>}
                            linkOne={"/service/ml/ML"} linkTwo={"/service/ml/DT"} linkthree={"/service/ml/BI"} linkfour={"/service/ml/DA"}
                        />
                    </section>


                </div>

            </div>
        </>
    )
}

export default ServicetabTwo
