
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function ND() {

    const [cardData, setcardData] = useState([
        {
            "header": "Develop apps in record time",
            "para": "Node.js’s event-driven I/O model supports the handling of concurrent requests. As a result, your app is fast even when performing multiple operations at the same time."
        },
        {
            "header": "Scale easily, enhance performance",
            "para": "The event-based model also enhances the scalability and performance of Node.js applications. Node.js usually scales better than most other common frameworks."
        },
        {
            "header": "Become lightweight and efficient",
            "para": "The non-blocking and event-driven I/O makes web apps lightweight and efficient in the face of data-intensive real-time tasks running across distributed devices."
        },
        {
            "header": "Free yourself from restrictions",
            "para": "Node.js gives you more freedom to build an app your way. Developers are not restrained by excessive rules and guidelines, so your app will be exactly as you want."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Build high-performance web apps with Node.js</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Use Node.js and JavaScript for powerful full-stack developmen</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Fast and scalable apps in a powerful runtime environment
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Node.js allows the execution of JavaScript on the server-side and is perfect for building fast and scalable web applications. It has gained substantial popularity thanks to a vibrant community that makes sure the technology is up-to-date with the latest development trends and standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Get applications into production fast with rapid MVPs</h1>
                                    <p className="para text-center">Node.js is a great option for businesses that demand speed in production
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Take advantage of proven processes and make your project a success
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">A good Node js development company will be able to take care of even the most complex of processes and will guide you smoothly towards your goal. At Neurastats, our Node.js development processes are based on industry best practices and years of experience, so you can be sure that your company is in good hands.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="why" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Find out what you can do with Node.js</h1>
                                            <p className="px-4 para">Node.js is suitable for many different projects – here are some areas where it excels in performance

                                            </p>
                                        </div>
                                        <div id="iot" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Internet of Things</h1>
                                            <p className="px-4 para">The most obvious advantage of Node.js as a backend for such networks is its ability to process multiple concurrent requests and events emitted by thousands or even millions of devices on the network. The avalanche of requests and data coming from IoT devices does not block Node.js servers thanks to their event-driven architecture and asynchronous processing suitable for I/O-heavy operations on the IoT network.
                                                <br />
                                                This makes Node.js fast as an application layer between these devices and databases used to store the data originating from them.
                                                <br />
                                                IoT developers working in data-intensive scenarios can leverage the low resource requirements of Node.js. Low memory requirements allow for the easy integration of Node.js as software into single-board controllers such as Arduino, widely used for building digital devices that form IoT systems.
                                                <br />
                                                Finally, the Node community has been an early adopter of the IoT technology, creating over 80 packages for Arduino controllers and multiple packages for the Pebble and Fitbit wearable devices widely used in IoT systems.

                                            </p>
                                        </div>
                                        <div id="rtc" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Real-time chat</h1>
                                            <p className="px-4 para">Real-time chats can take the form of one-to-one or one-to-many group chats built on instant messaging (IM) or Internet Relay Chat (IRC) technologies.
                                                <br />
                                                Node.js provides all basic functionalities for building real-time chats of any complexity. In particular, Node has a powerful Event API that facilitates creating certain kinds of objects (“emitters”) that periodically emit named events “listened” for by event handlers. Thanks to this functionality, Node.js makes it easy to implement server-side events and push notifications widely used in instant messaging and other real-time applications.
                                                <br />
                                                Node’s event-based architecture also works well with the WebSockets protocol that facilitates a fast two-way exchange of messages between the client and the server via one open connection. By installing WebSockets libraries on the server and the client-side, you can implement real-time messaging that has lower overheads and latency and faster data transfer than most other, more conventional, solutions.
                                                <br />
                                                In Node, you have excellent support for WebSockets via such libraries as socket.io, ws, or websocket-node, thanks to which you can easily deploy efficient real-time chats and applications. With socket.io, for example, all you have to do to create a basic live chat is install the socket.io library on the server and the client and create event emitters and broadcasters that will push messages across the WebSockets open connection – this basic functionality can be achieved with just a few lines of code.

                                            </p>
                                        </div>
                                        <div id="sp" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Complex single-page applications</h1>
                                            <p className="px-4 para">Node.js is a great fit for SPAs thanks to its efficient handling of asynchronous calls and heavy I/O workloads characteristic of these applications. Node.js’s event loop allows to “delay” multiple concurrent requests from the client, which ensures smooth transitions between views and seamless data updates. Also, Node.js works well with data-driven SPAs, where the server acts as a backend that provides data to the client whereas the client does all the HTML rendering.
                                                <br />
                                                With such framework as Express available via the NPM repository, you can turn Node.js into a REST API streaming data and services from specific routes. Such approach helps reduce the server’s load while ensuring API sharing across different applications and environments. In this scenario, Node.js would only return the index page (index.html) while data would be sent via REST interfaces and controllers implemented server-side.
                                                <br />
                                                From the design point of view, such approach will ensure the clear separation of concerns (SoC) between models, controllers, and views with all data-related services implemented server-side.
                                                <br />
                                                Finally, Node.js is good for SPAs because it is written in the same language (JavaScript) as many popular JavaScript frameworks (Ember, Meteor, React, Angular) used in building SPAs. Since both Node.js and browsers use JavaScript, there is less context switching between them, and developers can use the same data and language structures and modular approaches both on the server and the client side. This results in faster development and better maintainability of your SPAs.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#why" className="content-a">Find out what you can do with Node.js</a></p>
                                            <p className="px-4"><a href="#iot" className="content-a">Internet of Things</a></p>
                                            <p className="px-4"><a href="#rtc" className="content-a">Real-time chat</a></p>
                                            <p className="px-4"><a href="#sp" className="content-a">Complex single-page applications</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"UI design"} paraone={"Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product."} subTitletwo={"Branding"} paratwo={"Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers."} fcode1={<i class="fab fa-quinscape"></i>} fcode2={<i class="fas fa-copyright"></i>} linkone={"/service/pd/ui"} linktwo={"/service/pd/br"} />
        </>
    )
}

export default ND
