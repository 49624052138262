
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function WD() {

    const [cardData, setcardData] = useState([
        {
            "header": "Transform your idea into a product",
            "para": "Choose the right technology solutions and launch your web app successfully"
        },
        {
            "header": "Innovate",
            "para": "Respond to dynamic market needs and stay ahead of the competition"
        },
        {
            "header": "Craft excellent user experiences",
            "para": "Create an app your users will love - beautiful, functional and helpful"
        },
        {
            "header": "Build trust among investors",
            "para": "Quickly create a successful MVP, future proof your product and acquire financial support"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Crafting digital future with bespoke web development solutions</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Build your own digital products with cutting-edge technologies and battle-tested tools.</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Grow your business with custom web development services
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Choosing the best web development partner is crucial to building a successful digital product. The right partner can apply the best tech solutions to build a secure, stable app that delivers exceptional user experience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Achieve your goals with the best web development services</h1>
                                    <p className="para text-center">How to leverage the power of tailor-made web development solutions?
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Take advantage of proven processes and make your project a success
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">A good technology partner is able to take care of even the most complex of processes and will guide you flawlessly towards your goal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="ks" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Kickstart your business with a web development company</h1>
                                            <p className="px-4 para">Whether you’re an entrepreneur or the leader of a large company, there will be important strategic goals tied to the success of your future digital product. The right web development partner can act as a guide and counsellor throughout this crucial process

                                            </p>
                                        </div>
                                        <div id="wda" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">What to expect from your web development agency</h1>
                                            <p className="px-4 para">A great web development agency will guide you through the technology world. You want to work with a team capable of asking the right questions, analyzing the market and defining obstacles - as well as the solutions to overcome them.
                                                <br />
                                                Your team at Neurastats will choose the right technology to build your app, but they’ll also adjust to your business requirements, market needs and future goals.

                                            </p>
                                        </div>
                                        <div id="ino" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Outstanding knowledge and expertise in innovations</h1>
                                            <p className="px-4 para">The software sector is always changing, and so are the demands of its users. New technologies and features emerge all the time, so the best web development team will continually improve its knowledge, staying up-to-date with new features, innovations, and security issues.</p>
                                        </div>
                                        <div id="uned" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">A team suited to your needs</h1>
                                            <p className="px-4 para">Expertise is just the first step in building the perfect team; the team composition needs to adjust to each stage of the development process, to keep focus sharp and costs down. The role of a good web development company is to plan the execution of a digital project, from start to finish.</p>
                                        </div>
                                        <div id="ass" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Perfect project management and Quality Assurance</h1>
                                            <p className="px-4 para">Building a successful web application requires a project manager who can take full ownership, and quality assurance best practices, so you can ensure your project is guided to completion without a hitch.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#ks" className="content-a">Kickstart your business with a web development company</a></p>
                                            <p className="px-4"><a href="#wda" className="content-a">What to expect from your web development agency</a></p>
                                            <p className="px-4"><a href="#ino" className="content-a">Outstanding knowledge and expertise in innovations</a></p>
                                            <p className="px-4"><a href="#uned" className="content-a">A team suited to your needs</a></p>
                                            <p className="px-4"><a href="#ass" className="content-a">Perfect project management and Quality Assurance</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"UI design"} paraone={"Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product."} subTitletwo={"Branding"} paratwo={"Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers."} fcode1={<i class="fab fa-quinscape"></i>} fcode2={<i class="fas fa-copyright"></i>} linkone={"/service/pd/ui"} linktwo={"/service/pd/br"} />
        </>
    )
}

export default WD
