import React, { useRef } from 'react'
import CoreVCARD from './CoreVCARD'
import pro1 from './img/pro1.svg'
import pro2 from './img/pro2.svg'
import pro3 from './img/pro3.svg'
import pro4 from './img/pro4.svg'
import pro5 from './img/pro5.svg'
import Carousel from 'react-elastic-carousel'
import left from "./img/left.svg"
import right from "./img/right.svg"
function CoreValue() {

    const breakPoints = [
        { width: 1, itemsToShow: 2 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 2 },
        { width: 1150, itemsToShow: 2, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 2 },
        { width: 1750, itemsToShow: 2 },
    ]

    var resetTimeout;
    var nop = 0, inc = 2;

    const carousel = useRef(null)
    const getIndex = (ind) => {
        clearTimeout(resetTimeout)

        if (ind.index + inc === 6) {
            resetTimeout = setTimeout(() => {
                carousel.current.goTo(0)
            }, 3000) // same time
        }
    }



    return (
        <>
            <section className="corevalue-section odd py-1">
                <div className="container-fluid career-header coreval-section">
                    <div className="container header-worklf">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 p-0">
                                <div className="container-fluid core-value-stikcy">
                                    <h1 className="heading p-0" >Neurastats’s <span className="light-heading">Core Values</span> </h1>
                                    <p className="pr-5" >These values reflect what’s important to the entire company. They guide our efforts, define our culture, shape the way we approach software development, and change alongside us as we aim for more and more ambitious goals. Initiatives such as Live Books, the Burning Minds conference, regularly scheduled feedback, and Neurastats Hero & Super Heroes, are examples of how our values affect daily life at Neurastats.</p>
                                    {/* <button className="customer-carouselbtn cbtn-lft" onClick={() => carousel.current.slidePrev()}> <img src={left} alt="" className="img-fluid" /> </button>
                                <button className="customer-carouselbtn cbtn-right" onClick={() => carousel.current.slideNext()}> <img src={right} alt="" className="img-fluid" /> </button> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Carousel verticalMode enableAutoPlay
                                    autoPlaySpeed={3000} breakPoints={breakPoints} showArrows={false} pagination={false}
                                    onNextEnd={i => getIndex(i)} ref={carousel}
                                >
                                    <CoreVCARD margin="" img={pro1} title={"Exceed clients’ and colleagues’ expectations"} para={'Never settle for "good enough" when you know you can and should do better.'} />
                                    <CoreVCARD margin="" img={pro2} title={"Take ownership and question the status quo in a constructive manner"} para={"Trust your gut and your knowledge, even if you need to question your team leader or CEO."} />
                                    <CoreVCARD margin="" img={pro3} title={"Be brave, curious and experiment – learn from all successes and failures"} para={"Take risks and make mistakes - that’s how we learn, and how we innovate."} />
                                    <CoreVCARD margin="" img={pro4} title={"Act in a way that makes all of us proud"} para={"We are all in this together and everything you say and do, whether internally or publicly, reflects on us."} />
                                    <CoreVCARD margin="" img={pro5} title={"Build an inclusive, transparent and socially responsible culture"} para={"Culture doesn’t happen by chance – we need to be proactive and work on it every day."} />
                                    <CoreVCARD img={pro3} title={"Be ambitious and grow yourself and the people around you"} para={"As you work towards career goals, be mindful of the people around you and of how you can help each other."} />
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CoreValue
