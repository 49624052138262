import React from 'react'
import Header from "../Overview/Header"
import Volks from '../Overview/Volks'
import Volk from '../Img/Volk.svg'
import Testimonial from "../../Testimonial/Testimonial"
import Counting from "../../Counting/Counting"
function Expertise() {
    return (
        <div>
            {/* <Header /> */}
            {/* <Volks /> */}
            {/* <div className="container-fluid my-5 volks-section">
                <div className="row my-5">
                    <div className="col-lg-6 col-md-6 Volks-img2">
                        <img src={Volk} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 col-md-6 px-5 Volks-text my-5">
                        <h1>Volkswagen: the average sale price increased by €1,000 thanks to insights from discovery workshops</h1>
                        <p className="my-5 para">Volkswagen interviewed about one million customers who bought a car in the past ten years. Based on the insights from the report and Product Design Sprint workshop, the idea for the first-in-the-world car boutique – Volkswagen Home – was born. The team created a completely new buying experience that translated into:</p>
                        <p className="my-5 para">€1K higher average sale price at VW Home than in traditional sales channels,</p>
                        <p className="my-5 para">Encouraging customers to choose a car with a better, more expensive direct-shift gearbox (DSG), increasing the market average to 25%</p>
                    </div>

                </div>
            </div> */}
            <div className="container my-5"></div>
            <Testimonial />
            <Counting />
        </div>
    )
}

export default Expertise
