
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function AN() {

    const [cardData, setcardData] = useState([
        {
            "header": "Low cost app development",
            "para": "Easily accessible Software Development Kit and low barrier to entry means low costs, high ROI"
        },
        {
            "header": "Ease of integration",
            "para": "Utilize Android’s background processes and customization for easy integration between platforms"
        },
        {
            "header": "Worldwide reach",
            "para": "Tap into a global market share of 74% and almost 2 billion active monthly Android devices"
        },
        {
            "header": "Easy approvals",
            "para": "Go from concept to product quickly: no stringent approval process means shorter time to market"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Android App Development - Quality mobile applications</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Break into the mobile market and improve business results with beautiful, scalable Android apps</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Tap into the world’s largest mobile market with custom Android app development
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Android makes up 74% of all mobile apps. Creating a successful Android app can help to tap into a huge market, take advantage of more customization opportunities and less restrictive Google Play App Store policies to deliver unique value and great usability.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Reach a global market with Android app development services</h1>
                                    <p className="para text-center">Combine custom tech solutions and business objectives to reach the world’s largest mobile app market
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Build intuitive, easy-to-use applications that attract and retain user attention
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Android mobileobile development is an ongoing process of a constant, iterative effort to make your product better and better. Devices and technologies through which users interact with your product will change so as your users, their needs and behaviour. Make sure you have the right tools, and battle-tested methodologies to face these challenges. And never stop the process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="one" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What is an Android app?</h1>
                                            <p className="px-4 para">An Android application is a piece of software that can be used on the Android operating system.
                                                <br />
                                                Android apps can help you reach the most possible users, as they make up the majority of the mobile market share.
                                                <br />
                                                When we hear about Android, we tend to think of smartphones, but there are also other devices compatible with Android, such as Smart TVs, laptops and more.
                                                <br />
                                                Android apps can be downloaded and installed on your device from a number of different app stores, such as the Google Play store and official third party distributors. They often serve a specific purpose, such as Google’s Gmail app for emails.
                                                <br />
                                                Apps can be built to serve both Android and iOS operating systems, but the processes are slightly different.
                                                <br />
                                                Android apps are built with a variety of open source coding technology, such as Java or Kotlin. This means that Android app development is quick, flexible and low cost.
                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">The process of Android app development</h1>
                                            <p className="px-4 para">First of all, you need to define and analyze your business idea to make sure your app will add value and stand out in a crowded market.
                                                <br />
                                                Next, devise a strategy for building and promoting the best possible app, taking into account usability, design, stability and functionality before building a wireframe.
                                                <br />
                                                A wireframe is a blueprint of the app’s overall structure and identifies all the tools you need to build your application before adding UI designs.
                                                <br />
                                                Next, the usability of the app is coded and navigational features, list views and data collation are included, with ongoing testing and validation to identify and remove bugs.
                                                <br />
                                                Now you’re ready to publish your app. It’s important to remember that mobile application development is an ongoing process, and requires performance maintenance.
                                            </p>
                                        </div>
                                        <div id="three" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">The business impact of Android apps</h1>
                                            <p className="px-4 para">With 74% of the mobile market share, Android apps can have a huge impact on KPIs, increasing your business exposure to the biggest possible audience.
                                                <br />
                                                You can also use your app to promote customer loyalty, with in-app notifications, promotions and personalization.
                                                <br />
                                                Mobile applications can also be used to reach out to new potential customers, with tools such as registration/booking forms, accounts, newsfeeds and other information that can be used to drive engagement and increase sales.
                                                <br />
                                                As Android apps are available on several platforms, they increase accessibility for users, as well as increasing your business’s accessibility to them, so you can reach them wherever they are.
                                                <br />
                                                Android mobile applications drive brand awareness amongst the largest mobile market, so they’re a vital part of a successful digital business strategy.
                                                <br />
                                                A professional Android development company will have years of expertise in how to navigate the mobile market, enabling you to take advantage of all the business benefits a successful Android app can bring.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>

                                            <p className="px-4"><a href="#one" className="content-a">What is an Android app?</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">The process of Android app development</a></p>
                                            <p className="px-4"><a href="#three" className="content-a">The business impact of Android apps</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Python Development"} paraone={"Python is currently one of the fastest growing languages to get your job done on time."} subTitletwo={"iOS development"} paratwo={"Develop top-quality iOS apps with cutting edge technology."} fcode1={<i class="fab fa-python"></i>} fcode2={<i class="fab fa-apple"></i>} linkone={"/service/wd/py"} linktwo={"/service/md/IOS"} />
        </>
    )
}

export default AN
