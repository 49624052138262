
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
// import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function SS() {

    const [cardData, setcardData] = useState([
        {
            "header": "Custom Roadmap",
            "para": "We help you craft a custom roadmap for developing your business vision and make sure your project is technically outstanding"
        },
        {
            "header": "Plan & Recommendations",
            "para": "You will finish the session with a solid development plan and expert recommendations."
        },
        {
            "header": "Project Management",
            "para": "Our world-class project management professionals will make sure your unique idea is translated into a technical action plan."
        },
        {
            "header": "Experienced Team",
            "para": "With over 20+ successful projects, we’re here to ensure your start is as frictionless as possible."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Transform your vision into a business reality</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Scoping sessions are the best way to shape your business ideas. Together we will turn your concept into a product that inspires masses.</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Scoping Sessions at Neurastats
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">A scoping session is a meeting between you (i.e. our client) and our team of experts, including developers and a project manager. We go through all aspects of project planning: from time and cost estimation to scope definition.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Crucial for the overall success of the product.</h1>
                                    <p className="para text-center">We recommend scoping session services to all our customers, as they allow us to understand and design your business idea better.
                                        Custom Roadmap
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            From time and cost estimation to scope definition
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Our experience confirms that scoping sessions have a major impact on the final outcome of the products we create.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="ss" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Scoping sessions: the beginning</h1>
                                            <p className="px-4 para">we sit down with our developers to create user stories. This may well generate more questions, but that’s just because we want to get a clear understanding of your vision and goals. <br />
                                                When all the materials are ready, we will organise a meeting between the customer, developers and project manager. This usually lasts between 2 and 3 hours. During the meeting, we will go through the User Stories we prepared earlier. They are all gathered in JIRA, the project management tool we use and share access with our clients. By discussing the user stories we ensure that everything is exactly where it should be.

                                            </p>
                                        </div>
                                        <div id="mvp" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Estimations and MVP</h1>
                                            <p className="px-4 para">Next, we sit down and estimate all the tasks we need to complete and then draft an MVP for our customers including goals and milestones. We believe that this is the safest way to check if a business idea will succeed. Of course, we will be more than happy to develop the application beyond the original project if need be! <br />Also, the scoping session gives our customers an insight into our working practices and culture. It’s a great moment to see if we are a good fit for you. After the scoping session, the client receives the relevant estimations including all the materials we prepared for the meeting. They are yours to use as you wish.

                                            </p>
                                        </div>
                                        <div id="pc" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Scoping session with materials prepared by the prospective customer</h1>
                                            <p className="px-4 para">Sometimes the client’s vision is only simple in the client’s head - introducing it to others comes as a challenge. <br />Why is that so important? We need to be sure we have left no stone unturned. Then, we move on to the meeting with the clients, estimations and preparation of the final materials.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#ss" className="content-a">Scoping sessions: the beginning</a></p>
                                            <p className="px-4"><a href="#mvp" className="content-a">Estimations and MVP</a></p>
                                            <p className="px-4"><a href="#pc" className="content-a">Scoping session with materials prepared by the prospective customer</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Digital product design"} paraone={"Solve real human problems and provide the ultimate UX for your web and mobile applications."} subTitletwo={"Web development"} paratwo={"Cutting-edge technology and reliable solutions tested in battle."} fcode1={<i class="fas fa-pencil-ruler"></i>} fcode2={<i class="fas fa-globe-americas"></i>} linkone={"/service/pd/pdsn"} linktwo={"/service/wd/wd"} />
        </>
    )
}

export default SS
