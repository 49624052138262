
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
function PDsn() {

    const [cardData, setcardData] = useState([
        {
            "header": "Design web and mobile apps",
            "para": "Create seamless UX and beautiful UI for different platforms and devices"
        },
        {
            "header": "Validate your ideas",
            "para": "Quickly create and validate prototypes to reduce the risk of failure"
        },
        {
            "header": "Improve conversions",
            "para": "Increase the number of leads and sales from your website"
        },
        {
            "header": "Streamline experience",
            "para": "Discover bottlenecks in the user journey and fix them"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Craft beautiful and usable digital products with expert product design services</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Leverage design thinking to deliver maximum value to your customers and your business</p>
            </div>
            <div class="container-fluid px-0  tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <secton id="Overview" className="serviceTabSection">
                        <Overview otitle={"Accelerate growth with exceptional software design"} opara={"Successful products are not only visually appealing to your audience, but are also highly tailored to users’ needs. With the right design tools, methodologies based on design thinking, and a human-centered approach, you can provide your users with the ultimate user experience."} oheader={"Design thinking empowers communication"} oheadpara={"Bring UX and UI designers on board to make your product shine"} card={cardData} />
                    </secton>
                    <secton id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            A design process based on best practices and methodologies that deliver
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Great design is an iterative process of resolving problems and continual improvement to make your product the best it can be. Devices and technologies will change, and so will your customers, their needs, and their behavior. With the right tools and battle-tested methodologies, you can face any challenge, and never stop the process of enhancing your product.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">

                                        <div id="pdser" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Product design services</h1>
                                            <p className="px-4 para">As a product design agency, Neurastats specializes in a wide range of distinct digital services. Our design consultancy can help you discover new opportunities for your business, validate ideas, optimize processes, and successfully bring new digital products to the market. Our design studio provides services for companies from various industries and at different stages of business development.
                                                <br />
                                                The Neurastats product design team consists of more than 60 experienced multidisciplinary designers. They can deliver a holistic design experience, whether you already have a digital product or want to shape your idea for a new business venture. As we work as full-stack design partners, our clients will always benefit from comprehensive expertise. This approach translates into engaging interfaces and a delightful UX that your users love.

                                            </p>
                                        </div>
                                        <div className="container-fluid approch-product-header my-5 px-5">
                                            <h1 id="ux" className="px-4 ">UX design</h1>
                                            <p className="px-4 para">At Neurastats, we always make sure that the experiences we craft are not only delightful but also provide maximum value to your users and your business. This is why UX design plays such an important role in our digital transformation process. UX designers at Neurastats provide you with a full range of services, from business idea to product delivery.
                                                <br />
                                                We offer various research methods to collect the necessary customer insights to ensure your product is highly tailored to your users’ needs. Our evaluation methods increase the functional and business value of your product. At the same time, our UX design team will ensure you strengthen your success metrics, such as conversion rates, time spent in the app, and customer feedback.

                                            </p>
                                        </div>
                                        <div id="pds" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Product Design Sprint</h1>
                                            <p className="px-4 para">Product Design Sprint is a workshop that we developed after years of experience and many scoping sessions and kick-off meetings with our clients.
                                                <br />
                                                The design sprint methodology was pioneered by Google Ventures (GV) as a tool to solve business problems through design, prototyping, and testing ideas with customers. The primary benefit is that months of work are condensed into just a few days that bring tangible results.

                                            </p>
                                        </div>
                                        <div id="ilu" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Illustrations</h1>
                                            <p className="px-4 para">Eighty percent of the world’s population are visualizers. This highlights the fact that our customers learn, remember, and, very often, make buying decisions simply by looking at something. Therefore, your product needs a strong visual presence to draw your customer’s attention, engage them, and explain complex information quickly.
                                                <br />
                                                If illustrations are drawn with purpose and consideration, they can lay the foundation of a long-term relationship between you and your customers, which will ultimately have a positive impact on your market position</p>
                                        </div>
                                        <div id="ds" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Design systems</h1>
                                            <p className="px-4 para">A design system consists of a library and documentation and includes your brand’s visual assets, logo, fonts, and colors. A design system also includes UI components, their documentation, and the code snippets to facilitate developers using them across existing products.
                                                <br />
                                                At Neurastats, we offer design system services that enable you to speed up development, reduce costs, and easily scale products while ensuring consistency.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#pdser" className="content-a">Product design services</a></p>
                                            <p className="px-4"><a href="#ux" className="content-a">UX design</a></p>
                                            <p className="px-4"><a href="#pds" className="content-a">Product Design Sprint</a></p>
                                            <p className="px-4"><a href="#ilu" className="content-a">Illustrations</a></p>
                                            <p className="px-4"><a href="#ds" className="content-a">Design systems</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </secton>
                    <secton id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </secton>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Business intelligence"} paraone={"Transform data into actionable intelligence that informs an organization’s strategic and tactical business decisions."} subTitletwo={"Data annotation"} paratwo={"Annotate data quickly and effectively through the community of professionals on our platform."} fcode1={<i class="fas fa-registered"></i>} fcode2={<i class="fas fa-print"></i>} linkone={"/service/ml/BI"} linktwo={"/service/ml/DA"} />
        </>
    )
}

export default PDsn
