import React, { useEffect, useRef } from "react";
import gig from "./img/gig.svg";
import udyog from "./img/udyog.svg";
import againeat from "./img/againeat.svg";
import fade from "./img/fade.svg";
import lorem from "./img/edukos.svg";
import chandrika from "./img/chandrika.svg";
import Carousel from "react-elastic-carousel";
import Testimonial from "../Testimonial/Testimonial";
import Fade from "react-reveal/Fade";
import left from "./BasicElements/left.svg";
import right from "./BasicElements/right.svg";
function Customer() {
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 2 },
    { width: 1750, itemsToShow: 2 },
  ];

  var resetTimeout;
  var nop = 0,
    inc = 2;

  const carousel = useRef(null);
  const getIndex = (ind) => {
    clearTimeout(resetTimeout);

    if (ind.index + inc === 6) {
      resetTimeout = setTimeout(() => {
        carousel.current.goTo(0);
      }, 3000); // same time
    }
  };

  const filler = () => {
    return (
      <div className="container-fluid customer-outer px-4">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="container-fluid px-5 mb-4 customer-heading">
              <Fade cascade>
                <p className="service-head">Our Customers</p>
                <h1 className="heading2">
                  {" "}
                  Who <span className="light-heading">believed </span> in us
                </h1>
                <div className="container-fluid customer-context">
                  <div className="customer-context-text">
                    <p className="para">
                      Idea and innovation are the foundation of everything we
                      do. As our customers continue to explore new markets and
                      expand their offerings, Neurastats is there to provide
                      support by analysing their audience and providing proven
                      solutions.
                    </p>
                    <button
                      className="customer-carouselbtn cbtn-lft"
                      onClick={() => carousel.current.slidePrev()}
                    >
                      {" "}
                      <img src={left} alt="" className="img-fluid" />{" "}
                    </button>
                    <button
                      className="customer-carouselbtn cbtn-right"
                      onClick={() => carousel.current.slideNext()}
                    >
                      {" "}
                      <img src={right} alt="" className="img-fluid" />{" "}
                    </button>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 home-custo-imgbx">
            <Fade cascade>
              <div className="container-fluid home-cus-md my-auto">
                <Carousel
                  verticalMode
                  enableAutoPlay
                  autoPlaySpeed={3000}
                  breakPoints={breakPoints}
                  showArrows={false}
                  pagination={false}
                  onNextEnd={(i) => getIndex(i)}
                  ref={carousel}
                >
                  <div className="card card-cus-hom my-4 py-3">
                    <div className="card-cus-row no-gutters">
                      <div className="img-box m-2">
                        <img src={fade} alt="..." className="img-logo" />
                      </div>
                      <div className="context-box my-auto">
                        <div className="card-body">
                          <h5 className="card-title card_cus_home_title">
                            Fade{" "}
                          </h5>
                          <p className="card-text para">
                            Enjoy Shop-the-video experience, web application
                            developed in react and Node.js.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-cus-hom my-4 py-3">
                    <div className="card-cus-row no-gutters">
                      <div className="img-box m-2">
                        <img src={lorem} alt="..." className="img-logo" />
                      </div>
                      <div className="context-box my-auto">
                        <div className="card-body">
                          <h5 className="card-title card_cus_home_title">
                            MyEdukos
                          </h5>
                          <p className="card-text para">
                            Learn from experts in entertainment industry, mobile
                            application developed in flutter for android and iOS
                            platform.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-cus-hom my-4 py-3">
                    <div className="card-cus-row no-gutters">
                      <div className="img-box m-2">
                        <img src={gig} alt="..." className="img-logo" />
                      </div>
                      <div className="context-box my-auto">
                        <div className="card-body">
                          <h5 className="card-title card_cus_home_title">
                            GIG
                          </h5>
                          <p className="card-text para">
                            Hire live entertainers on demand, mobile application
                            developed in flutter for android and iOS platform.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-cus-hom my-4 py-3">
                    <div className="card-cus-row no-gutters">
                      <div className="img-box m-2">
                        <img src={chandrika} alt="..." className="img-logo" />
                      </div>
                      <div className="context-box my-auto">
                        <div className="card-body">
                          <h5 className="card-title card_cus_home_title">
                            Chandrika{" "}
                          </h5>
                          <p className="card-text para">
                            Online E-commerce shopping website, web application
                            developed in Wordpress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-cus-hom my-3 py-3">
                    <div className="card-cus-row no-gutters">
                      <div className="img-box m-2">
                        <img src={againeat} alt="..." className="img-logo" />
                      </div>
                      <div className="context-box my-auto">
                        <div className="card-body">
                          <h5 className="card-title card_cus_home_title">
                            Again Eat
                          </h5>
                          <p className="card-text para">
                            Ordering food now gets easy, mobile application
                            developed in flutter for android and iOS platform.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-cus-hom my-3 py-3">
                    <div className="card-cus-row no-gutters">
                      <div className="img-box m-2">
                        <img src={udyog} alt="..." className="img-logo" />
                      </div>
                      <div className="context-box my-auto">
                        <div className="card-body">
                          <h5 className="card-title card_cus_home_title">
                            Vardhman Udyog
                          </h5>
                          <p className="card-text para">
                            Serving the demand of pulses for B2B business all
                            over India, web application developed in PHP.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="customer-section">
        <Testimonial />
      </section>
    </>
  );
}

export default Customer;
