import React from 'react'
import Navbar from '../Navbar/Navbar'
import Header from './Header'
import StartProject from "../StartProject/StartProject"
import ServiceTab from './ServiceTab'
import Testimonial from "../Testimonial/Testimonial"
import ServicetabTwo from './ServicetabTwo'
function Service() {
    return (
        <>
            <Navbar />
            <Header />

            <ServicetabTwo />
            <Testimonial />
            <div className="container-fluid">
                <StartProject />
            </div>


        </>
    )
}

export default Service
