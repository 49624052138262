import React from 'react'
import Form from './Form'
function Header() {
    return (
        <>
            <section className="contact-header mb-5">
                <div className="container-fluid contact-text-part px-4">
                    <div className="container-fluid contact-header-main px-5 py-5">
                        <h1 className="contact-heading mb-3">Contact Us</h1>
                        <p className="contact-para mb-3">Contact for Premium Business Services
                        </p>

                        <p className="contact-para mb-5">Fill in the form and tell us about your project. <br className="br-hide" /> Our team will contact you promptly to discuss next steps.
                        </p>
                    </div>
                </div>
                <Form />
            </section>
        </>
    )
}

export default Header
