
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
// import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
function ServicetabTwo() {

    const [cardData, setcardData] = useState([
        {
            "header": "Simple solutions",
            "para": "The collaborative approach and communication encourage getting answers to complicated problems"
        },
        {
            "header": "Users understanding",
            "para": "The sprint is based on is listening, trust and building meaningful relationships with users."
        },
        {
            "header": "Increased efficiency",
            "para": "The sprint itself is meant to be short and agile, as it is easily adapted to the complexity of a project"
        },
        {
            "header": "Lower risk",
            "para": "The sprint shrinks months of development, prototyping and feature testing into just a few days."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Product Design Sprint Launch a better product</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Get <span>answers to critical business questions</span> fast with the design sprint methodology</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <Overview otitle={"Accelerate growth after Product Design Sprint workshops"} opara={"Reduce months’ worth of work to days – solve complex business problems and develop successful products that are highly tailored to users’ needs. With design tools and methodologies based on design thinking and human-centered design, you can provide your customers with the ultimate user experience and reduce the risk of failure."} oheader={"Design thinking in Product Design Sprint"} oheadpara={"Build a meaningful product that meets the needs of real users"} card={cardData} />
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Design process based on best practices and methodologies that deliver
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Design is an ongoing process of a constant, iterative effort to make your product better and better. Devices and technologies through which users interact with your product will change so as your users, their needs and behaviour. Make sure you have the right tools, and battle-tested methodologies to face these challenges. And never stop the process</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="rd" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Product Design Sprint process</h1>
                                            <p className="px-4 para">A Product Design Sprint is a 5-day workshop which uses design thinking to reduce the risks inherent in successfully bringing products to market. This planning methodology can be effective in moving your company forward, regardless the stage of your business. Each day of the sprint corresponds to another stage of solving the problem. The five phases will help you understand your business ideas and needs. Our flow has proved to be useful in finding the right solutions, building new features, and achieving goals. Explore different perspectives and identify viable solutions.

                                            </p>
                                        </div>
                                        <div id="si" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Design sprint phases</h1>
                                            <p className="px-4 para">
                                                <p>1. Understand</p>
                                                <p>The first day is all about getting insights about the user needs, business needs, and technology capacities. Discussion during this stage is essential to create a roadmap for the sprint week. At the very beginning, we set up long-term goals, obstacles, and challenges. We run some exercises to paint a bigger picture of the problem. At the end of the day, you pick the main goal you want to achieve during the sprint.</p>
                                                <p>2. Diverge</p>
                                                <p>On the second day, we focus on generating and exploring as many ideas as possible. We’ll first review existing solutions, including their strong and weak points. The participants will also perform exercises to build new insights and solutions. Through brainstorming sessions and individual analyses, each team member will propose their ideas that will be later studied and tested</p>
                                                <p>3. Converge</p>
                                                <p>At this stage, we’ll have plenty of ideas which the team came up with during the previous two steps. The team’s goal now is to identify the best ideas and decide which of them should be prototyped in the next step. We’ll critique each solution in relation to the main goal. Based on the selected ideas, we’ll sketch a step-by-step roadmap for building a prototype.</p>
                                                <p>4. Prototype</p>
                                                <p>On the fourth day, it’s time to put all that the team developed into practice. You will turn your storyboard into a prototype – a realistic artifact you will then test with a customer. The main assumption here is that you will only build the customer-facing surface of your product or service. In this way, the prototype will be up and running in just one day – ready for review in the final stage.</p>
                                                <p>5. Test</p>
                                                <p>GV calls this phase the “moment of truth”. This is when you test the ideas with users, business stakeholders, and technical experts and hear direct feedback from them. This stage will validate your solutions before you start spending your money on designing and developing an actual product. At the end of the fifth day, you’ll have a clear idea of what you should do next.</p>
                                            </p>
                                        </div>
                                        <div id="rnd" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Benefits of design sprint</h1>
                                            <p className="px-4 para">Depending on the purpose of the Product Design Sprint and the initial problem there is to be solved, you will get tailored solutions and recommendations that will help you generate a unique Value Proposition for the existing or potential customers. Product Design Sprints include in-depth interviews with founders about their product idea, our design thinking, and the agile approach. They have been successfully tested in numerous projects. Clients confirm that they provide an actionable product roadmap for product development or prevalidated prototype.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#rd" className="content-a">Product Design Sprint process</a></p>
                                            <p className="px-4"><a href="#si" className="content-a">Design sprint phases</a></p>
                                            <p className="px-4"><a href="#rnd" className="content-a">Benefits of design sprint</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Digital product design"} paraone={"Solve real human problems and provide the ultimate UX for your web and mobile applications."} subTitletwo={"Web development"} paratwo={"Cutting-edge technology and reliable solutions tested in battle."} fcode1={<i class="fas fa-pencil-ruler"></i>} fcode2={<i class="fas fa-globe-americas"></i>} linkone={"/service/pd/pdsn"} linktwo={"/service/wd/wd"} />
        </>
    )
}

export default ServicetabTwo
