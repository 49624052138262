
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function UI() {

    const [cardData, setcardData] = useState([
        {
            "header": "Create usable products",
            "para": "Make digital interactions fluid and ensure simple, intuitive access to the content your users wan"
        },
        {
            "header": "Leverage predictable elements",
            "para": "Functional consistency, delivered with modern UI components, ensures predictability and trust"
        },
        {
            "header": "Give users personalized and immersive services",
            "para": "Drive customer engagement by offering services and products unique to them"
        },
        {
            "header": "Communicate brand values and establish trust",
            "para": "Define your brand differentiators and build trust among your target market with reliable, consistent designs."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Build beautiful, engaging user interfaces with UI design services</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">VSatisfy your users and hit business targets by creating seamless, intuitive products</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Leverage UI design to deliver accessible, efficient digital products
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product. Excellent UI encourages users to return by facilitating productive interactions and presenting beautiful aesthetics.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Give your products a competitive edge</h1>
                                    <p className="para text-center">Stand out from the crowd by enhancing your digital products with to-quality UI Design services.
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Use bullet-proof processes that deliver results
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">At Neurastats, we have spent years honing our processes and incorporating industry best practices to provide the highest standards of UI design.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="ui" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What is user interface design?</h1>
                                            <p className="px-4 para">User interface (UI) design is the process of building interfaces in software or computer devices. In simple terms, it’s about creating the look of something, factoring in usability and aesthetics.
                                                <br />
                                                UI design refers to any form of interface with which a user interacts, such as graphical user interfaces or voice-controlled interfaces.
                                                <br />
                                                The aim of UI design is to create intuitive, easy-to-use, and satisfying platforms for users, making it as simple as possible for them to navigate around or between pages to access the things they need, such as product information or contact boxes. At Neurastats, we strive to develop professional-looking interfaces to engage and gain the trust of users.

                                            </p>
                                        </div>
                                        <div id="gb" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Why is UI design good for business?</h1>
                                            <p className="px-4 para">Great UI design can deliver a number of tangible business benefits.
                                                <br />
                                                Firstly, it makes it easier for your customers to identify and access your products. It draws attention and keeps it, enabling businesses to attract and retain new customers.
                                                <br />
                                                UI Design can also be used to communicate brand values and establish trust within the relevant business sector.
                                                <br />
                                                As a result, UI design is good for business because it can increase conversion rates and improve the overall customer experience, strengthening business reputation.

                                            </p>
                                        </div>
                                        <div id="ud" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What does a UI designer do?</h1>
                                            <p className="px-4 para">A user interface designer creates all of the pages or screens through which a user will move as they interact with your product or application.
                                                <br />
                                                They work closely with user experience (UX) designers and create the visual and audio elements that facilitate user interactions.
                                                <br />
                                                Everything will be centred around making the interface as usable and satisfying as possible.
                                                <br />
                                                A UI designer might also establish a style guide to be used throughout an application or website, ensuring consistency and familiarity for the user.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#ui" className="content-a">What is user interface design?</a></p>
                                            <p className="px-4"><a href="#gb" className="content-a">Why is UI design good for business?</a></p>
                                            <p className="px-4"><a href="#ud" className="content-a">What does a UI designer do?</a></p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Business intelligence"} paraone={"Transform data into actionable intelligence that informs an organization’s strategic and tactical business decisions."} subTitletwo={"Data annotation"} paratwo={"Annotate data quickly and effectively through the community of professionals on our platform."} fcode1={<i class="fas fa-registered"></i>} fcode2={<i class="fas fa-print"></i>} linkone={"/service/ml/BI"} linktwo={"/service/ml/DA"} />
        </>
    )
}

export default UI
