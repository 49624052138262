import React, { useRef, useState } from 'react'
import "./Estimate.css"
import emailjs from 'emailjs-com';
import validator from 'validator'
function Estimate() {
    const modal = useRef(null)
    const [formMSG2, setFormMSG2] = useState(<p></p>)
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [msg, setmsg] = useState(<p></p>)
    const [toastMsg, setToastMsg] = useState(<p></p>)
    const sendEmail = async (e) => {
        e.preventDefault();
        if (name === "") {
            setFormMSG2(<p className="ml-2 pt-2 error">
                Please enter valid Name
            </p>)
        }
        else {
            setFormMSG2(<p className="ml-2 pt-2 error">
            </p>)
        }
        if (email === "") {
            setmsg(<p className="ml-2 pt-2 error">
                Please enter valid email
            </p>)
        } else {
            if (validator.isEmail(email)) {
                setmsg(<p></p>)
                try {
                    const result = await emailjs.sendForm('service_lsmfvtk', 'template_e15qjxg', e.target, 'user_3oL0OoZvgtHcOdpxlBKNC')
                    const final = await result;
                    console.log(final);
                    setToastMsg(<p className="ml-2 success">
                        Thanks for enquiring with Neurastats, our team will contact you promptly to discuss next steps {name}
                    </p>)
                    e.target.reset()
                    setname("")
                    setemail("")
                    setmsg("")
                } catch (error) {
                    setToastMsg(<p className="ml-2 error">
                        "Message not send ! Please check your internet connection"
                    </p>)
                }


            } else {
                setmsg(<p className="ml-5 py-2 pt-2 error">
                    Please enter valid Email
                </p>)
            }
        }
    }

    const showIT = () => {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }
    return (
        <>

            <div className="modal" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Estimate <span className="light-heading">Project</span></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={sendEmail} noValidate={true}>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Name</label>
                                    <input type="text" className="form-control" id="recipient-name" name="to_name" value={name} onChange={e => setname(e.target.value)} />
                                    {formMSG2}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-email" className="col-form-label">Email</label>
                                    <input type="email" className="form-control" id="recipient-email" name="from_name" value={email} onChange={e => setemail(e.target.value)} />
                                    {msg}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Message</label>
                                    <textarea className="form-control" id="message-text" name="message"></textarea>
                                </div>

                                <div className="modal-footer">
                                    {toastMsg}
                                    <button type="submit" className="btn modal-btn-send ml-5 py-2 px-5">Send message</button>

                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Estimate
