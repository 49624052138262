import React from 'react'
import Header from '../Overview/Header'
import Context from './Context'
function Approch(props) {
    return (
        <>
            <div className="container-fluid overview-header">
                <div className="row over-header-row mx-5">
                    <div className="col-lg-6 col-md-6">
                        <h1 className="over-heading">
                            {props.header}
                        </h1>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <p className="para">{props.hpara}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <div className="container-fluid approch-product-header my-5 px-5">
                        <h1 className="px-4 ">{props.subhead1}</h1>
                        <p className="px-4 para">{props.subpara1}</p>
                    </div>
                    <div className="container-fluid approch-subpart px-5">
                        <h1 className="px-4 ">{props.subhead2}</h1>
                        <p className="px-4 para">{props.subpara2}</p>
                    </div>
                    <div className="container-fluid approch-product-header my-5 px-5">
                        <h1 className="px-4 ">{props.subhead3}</h1>
                        <p className="px-4 para">{props.subpara3}</p>
                    </div>

                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="container-fluid content-box">
                        <h1 className="px-4 mb-3">Content</h1>
                        <p className="px-4"><a href="#rd" className="content-a">What is Research and Development?</a></p>
                        <p className="px-4"><a href="#si" className="content-a">What is sustained innovation?</a></p>
                        <p className="px-4"><a href="#rnd" className="content-a">What does a UI designer do?</a></p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Approch
