
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function BI() {

    const [cardData, setcardData] = useState([
        {
            "header": "Data Labeling & Structuring",
            "para": "Structure massive data sets for better organization, easier access & deeper insights."
        },
        {
            "header": "Improve Customer Experience",
            "para": "Increase the efficiency of machine learning & AI services for enhanced end user experiences."
        },
        {
            "header": "Improve Output Accuracy",
            "para": "Intuitive labelling of different data sets ensures higher accuracy and actionable data insights."
        },
        {
            "header": "Accelerate internal processes",
            "para": "Develop smart tools to reduce time spent on admin and logistics tasks and increase productivity."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">BData annotation services - quicker, easier, smarter</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Move faster and earn more with cutting-edge data labelling and annotation services</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Discover the value of quality data annotation labelling
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Quality data has been described as the new oil. There are rich insights and real value hidden in images, sound, video, and text. By taking advantage of AI data annotation services, you can tap into that value to achieve supercharged results.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Expert labeling & classification services for meaningful data</h1>
                                    <p className="para text-center">The annotation process requires precision and focus, but can deliver wide-ranging benefits

                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Data Annotation at Neurastats
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Everything you need to know about data annotation</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="one" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What is data annotation?</h1>
                                            <p className="px-4 para">Data annotation is the name given to the process of labeling different types of data, like text, images, and sound.
                                                <br />
                                                Data labeling and annotation services are important in the development of AI and machine learning (ML) technologies because they enable ‘supervised learning’.
                                                <br />
                                                In supervised learning, data is preprocessed and labeled, which helps the machine to understand and recognize recurring patterns. This is useful for future cases where the algorithm is presented with un-annotated data.
                                                <br />
                                                In basic terms, data annotation and labeling helps improve the efficiency and accuracy of machine learning tools. It can be applied to multiple data formats and requires precision and expertise.
                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">What are the advantages of data annotation?</h1>
                                            <p className="px-4 para">Data annotation in machine learning is becoming more common because it offers benefits of efficiency, accuracy, and output.
                                                <br />
                                                With annotated data, AI and machine learning applications can recognize and understand previously obscure data, enabling for continual improvement and more accurate output for the end user.
                                                <br />
                                                An example is in search results, where relevant data annotation can enable search engines to produce the desired search for users with only a few characters. Data annotation for eCommerce can also produce more relevant product recommendations.
                                                <br />
                                                Better accuracy means better end user experience, which translates to the ability to attract and retain customers. Data annotation software in AI and machine learning helps to build seamless processes in communications, retail, research and manufacturing, to name a few.
                                                <br />
                                                This involves real-time issue tracking and feedback, as well as workflow processes like labeling consensus.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>

                                            <p className="px-4"><a href="#one" className="content-a">What is data annotation?</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">What are the advantages of data annotation?</a></p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Mobile App Development"} paraone={"Grow your business with the right mobile solutions."} subTitletwo={"Node js development"} paratwo={"Build performant web applications based on Javascript – one language for full-stack development."} fcode1={<i class="fas fa-mobile"></i>} fcode2={<i class="fab fa-node"></i>} linkone={"/service/md/MD"} linktwo={"/service/wd/nd"} />
        </>
    )
}

export default BI
