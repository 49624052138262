import React, { useEffect, useRef } from "react";
import "./StartProject.css";
import Estimate from "../EstimateProject/Estimate";
import lotti from "lottie-web";

function StartProject() {
  const zoneel = useRef(null);
  const bodymoveel = useRef(null);

  useEffect(() => {
    let animationRunning = false;
    const bodymovinDiv = bodymoveel.current;
    const zone = zoneel.current;
    console.log(zone + "+" + bodymovinDiv);
    console.log(zone);
    console.log(bodymovinDiv);
    console.log(animationRunning);

    const anim = lotti.loadAnimation({
      container: bodymovinDiv,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "https://assets8.lottiefiles.com/packages/lf20_hvq7nlgk.json",
    });

    anim.setSpeed(2.6);

    zone.addEventListener("mouseenter", mouseEnter);
    zone.addEventListener("mouseleave", mouseLeave);

    function mouseEnter() {
      if (!animationRunning) {
        animationRunning = true;
        bodymovinDiv.classList.remove("bodymovin--hidden");
        anim.loop = false;
        anim.setDirection(1);
        anim.playSegments([0, 8], true);
        anim.addEventListener("complete", startLoop);
      }
    }

    function mouseLeave() {
      // anim.stop();
      bodymovinDiv.classList.add("bodymovin--hidden");
      animationRunning = false;
      anim.removeEventListener("complete", startLoop); // If we leave before the loop starts we want to make sure to clear it
    }

    function startLoop() {
      anim.loop = true;
      anim.removeEventListener("complete", startLoop);
      anim.playSegments([9, 13], true);
    }
  }, []);

  return (
    <>
      <div className="container mt-5 str-project" ref={zoneel}>
        <div className="row ">
          <div className="col-lg-9 col-md-9 ">
            <h1 className="str-title">
              Would you like to start a project with us?
            </h1>
            <p className="str-para mr-2">
              We have helped 10+ companies transform their business with
              top-notch tech solutions. Now it’s your turn, get a free quote
              now.
            </p>
          </div>
          <div className="col-lg-3 col-md-3 str-button-container">
            <a
              href="https://calendly.com/shreeyash_haritashya/30min"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="btn my-auto my-sm-5 px-4 Req-btn str-btn"
                type="button"
                data-toggle="modal"
              >
                <span className="bodymovin " ref={bodymoveel}></span>
                <span className="text text-center">Schedule a call</span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <Estimate />
    </>
  );
}

export default StartProject;
