import React from 'react'
import "./Ocard.css"
import IMG from "../Img/bulb.svg"
function OveriewCard(props) {
    return (
        <>
            <div className="overview-card  py-4 px-3" id={`id${props.key + 1}`}>
                <div className="over-card-img">
                    <img src={IMG} alt="" />
                </div>
                <div className="over-card-content pt-4">
                    <h1>{props.title}</h1>
                    <p>{props.para}</p>
                </div>
            </div>
        </>
    )
}

export default OveriewCard
