import React from 'react'
import Card from "../Card/Card"
import Carousel from 'react-elastic-carousel'
import styled from "styled-components";
function CaseStudy() {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 }
    ];
    return (
        <>
            <div className="container-fluid px-4 service-casestudy-section" >
                <div className="container text-center my-5 service-casestudy">
                    <h1 className="service-casestudy-heading mb-2">Read our <span className="light-heading">Case Studies</span> </h1>
                    <p className=" para">Check out the knowledge base collected and distilled by experienced professionals</p>
                </div>

                <div className="row my-5">
                    <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={3000}>
                        <Card auth="By John Smith" time="1h ago" title="Design, Development,
Testing, Blockchain" disc="Craft delightful user experiences for your digital products. Solve real problems and improve your vital business metrics through beautiful interfaces." />
                        <Card auth="By John Smith" time="1h ago" title="Design, Development,
Testing, Blockchain" disc="Craft delightful user experiences for your digital products. Solve real problems and improve your vital business metrics through beautiful interfaces." />
                        <Card auth="By John Smith" time="1h ago" title="Design, Development,
Testing, Blockchain" disc="Craft delightful user experiences for your digital products. Solve real problems and improve your vital business metrics through beautiful interfaces." />

                    </Carousel>
                </div>

            </div>
        </>
    )
}

export default CaseStudy
