
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function PY() {

    const [cardData, setcardData] = useState([
        {
            "header": "Transform your idea into a product",
            "para": "Let the right technology solutions help you launch your web or mobile app successfully"
        },
        {
            "header": "Innovate and stand out in a digital world",
            "para": "Respond to rapidly changing market needs and stay two steps ahead of your competitors"
        },
        {
            "header": "Turn interest into engagement and loyalty",
            "para": "Develop an innovative, functional, fast, and reliable web or mobile app that builds loyalty and trust with your users"
        },
        {
            "header": "Create a secure and stable product",
            "para": "Make sure you develop your app in line with the latest security standards. Avoid data leaks and reputational risks."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Create complex and innovative applications with Python development services</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Leverage the power of one of the fastest-growing technologies and build cutting-edge apps fast</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Grow your business with a leading Python development company
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Developing a successful digital product is a complex process that involves addressing the needs of your business and users, applying the right tech solutions, and creating a secure and stable app that provides exceptional user experience.
                                            <br />
                                            It requires choosing the right development company, using innovative solutions, and following a proven development process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Achieve your goals by applying the right solutions</h1>
                                    <p className="para text-center">Using a technology that fits your challenges and business goals will have a significant impact on the success of your project.
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Take advantage of proven processes and make your project a success
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">A good Python development company will be able to take care of even the most complex of processes and will guide you smoothly towards your goal.
                                            <br />
                                            At Neurastats, our software development processes are based on industry best practices and years of experience, so you can be sure that your project is in good hands.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="why" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Why should you build a product in Python? A short guide to using Python for web development</h1>
                                            <p className="px-4 para">Python is a free, open source programming language that has been used in development services for nearly 30 years, and it is still considered one of the top programming languages – particularly for web development and data science projects.
                                                <br />
                                                Why is it so popular? Well, in short, because Python is a high-level, dynamic programming language that focuses on rapid and robust development and can be used for projects of practically any size

                                            </p>
                                        </div>
                                        <div id="which" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Which companies use Python?</h1>
                                            <p className="px-4 para">It’s a great choice for startups that are working under time pressure but still want to deliver a high-quality solution to the market on time.
                                                <br />
                                                It’s also a powerful tool used by multi-billion-dollar corporations and extremely talented developers around the world. Companies like Google, Facebook, and Microsoft use Python for various applications like web applications, data science, AI, machine learning, deep learning, and task automation.
                                                <br />
                                                Python has been used to design some of the world’s biggest websites – social media giant Instagram, digital music platform Spotify, and ridesharing company Uber all power their websites with Python.

                                            </p>
                                        </div>
                                        <div id="qck" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Quick solutions for programming challenges</h1>
                                            <p className="px-4 para">Python has been used in development services for almost 30 years, and it is one of the most dynamically growing languages. It’s the first-choice language for a vast majority of students and skilled programmers, particularly for custom web development and app development.
                                                <br />
                                                Python developers and data scientists choose Python because it’s versatile, easy to read and understand, and allows for rapid development. Python provides developer-friendly solutions for common programming problems and is compatible with many major platforms and systems.
                                                <br />
                                                Moreover, Python is open source. Because of its simplicity, openness, and rich history, Python is extremely popular among developers, and a huge ecosystem has been created around it.
                                                <br />
                                                Python has a rich portfolio of frameworks (such as Flask and Django) and libraries (like NumPy and Pandas) that help speed up development. If there’s a problem – the solution probably already exists in Python.
                                                <br />
                                                Most of the time, if you need to quickly test your idea, you don’t need to waste too much time on development – just pick up some publicly available modules and build your software like LEGO blocks. It’s all thanks to a large, vibrant Python community that contributes to the language’s growth.
                                                <br />
                                                Python has an established position in web development. Universal frameworks like Django and Flask help developers to quickly create secure, fast web, desktop, and mobile applications.

                                            </p>
                                        </div>
                                        <div id="pyt" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Python is on the rise</h1>
                                            <p className="px-4 para">In recent times, the programming language owes its even greater popularity to the development of the field of data science, e.g., in the area of artificial intelligence or machine learning. Python is the first choice for AI-first companies. Python performs better in data manipulation and repetitive tasks than other solutions, e.g., R – and it's definitely the right choice if you're planning to build a digital product based on machine learning.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#why" className="content-a">Why should you build a product in Python? A short guide to using Python for web development</a></p>
                                            <p className="px-4"><a href="#which" className="content-a">Which companies use Python?</a></p>
                                            <p className="px-4"><a href="#qck" className="content-a">Quick solutions for programming challenges</a></p>
                                            <p className="px-4"><a href="#pyt" className="content-a">Python is on the rise</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"UI design"} paraone={"Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product."} subTitletwo={"Branding"} paratwo={"Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers."} fcode1={<i class="fab fa-quinscape"></i>} fcode2={<i class="fas fa-copyright"></i>} linkone={"/service/pd/ui"} linktwo={"/service/pd/br"} />
        </>
    )
}

export default PY
