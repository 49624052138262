import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
function Post() {
    const { id } = useParams()
    console.log(id);
    const [state, setstate] = useState({});
    const [counter, setcounter] = useState(0)
    var getData = async () => {
        var response = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Neurastats")
        var result = await response.json()
        setstate(result.items[id])
        console.log(state);
    }
    window.addEventListener("load", () => {
        setcounter(counter + 1)
    })

    useEffect(() => {
        getData()
    }, [counter])
    return (
        <>
            <Navbar />
            <div className="container-fluid main-post">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <p className="para">{state.author}</p>
                        </div>
                        <div className="col-lg-6 col-md-6" style={{ textAlign: "end" }}>
                            <p className="para">{state.pubDate}</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h1 className="heading">{state.title}</h1>
                </div>
                <div className="container main-post-context" dangerouslySetInnerHTML={{ __html: state.description }}>
                </div>
            </div>
        </>
    )
}

export default Post
