import React from 'react'
import Navbar from '../Navbar/Navbar'
import "./Casestudy.css"
import Main from './Main'
import StartProject from "../StartProject/StartProject"
function CaseStudy() {
    return (
        <>
            <Navbar />
            <Main />
            <div className="container-fluid">
                <StartProject />
            </div>


        </>
    )
}

export default CaseStudy
