import React, { useEffect, useState } from 'react'
import IMG from "../Details/Img/Volk.svg"
import CaseCard from './CaseCard'
function Main() {
    const [state, setstate] = useState([]);
    const [loading, setloading] = useState(true)

    var getData = async () => {
        var response = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Neurastats")
        var result = await response.json()
        console.log(result.items[0]);
        setstate(result.items)
        setloading(false)
        console.log(state[0]);
    }



    useEffect(() => {
        getData()
    }, [])

    if (loading) {
        return <h1>Loading</h1>
    }

    return (
        <>
            <div className="container-fluid casestudy-main">
                <div className="container-fluid main-header pl-5">
                    <h1 className="heading">Case Studies</h1>
                </div>
                <div className="container-fluid my-5">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            {/* <div className="container-fluid">
                                <a href={state[0].link} className="main-header-card-link" >
                                    <div className="main-header-card">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 background" style={{ backgroundImage: `url(${state[0].thumbnail})` }} >

                                            </div>
                                            <div className="col-lg-6 col-md-6 main-header-cardTXT">
                                                <div className="container-fluid py-3 ">
                                                    <div className="container-fluid d-flex justify-content-between">
                                                        <p className="upper-text-card">{state[0].author}</p>
                                                        <p className="upper-text-card">{state[0].pubDate}</p>
                                                    </div>
                                                    <div className="container">
                                                        <h1 className="title-casestudy">{state[0].title}</h1>
                                                        <p className="dics casestudy-dics " dangerouslySetInnerHTML={{ __html: state[0].description.substring(0, 150) }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div> */}
                            <div className="container-fluid my-5">
                                <div className="row">
                                    {
                                        state.map((value, ind) => {
                                            return <CaseCard title={value.title} disc={value.content} id={ind} author={value.author} date={value.pubDate} img={value.thumbnail} />
                                        })
                                    }


                                </div>
                            </div>


                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="container blog-model">
                                <h1 className="heading-popular">Popular Post</h1>
                                <div className="main-header-card">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6" >
                                            <img src={state[0].thumbnail} alt="" className="main-header-img" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 main-header-cardTXT">
                                            <div className="container-fluid py-3 ">
                                                <div className="container-fluid d-flex justify-content-between">
                                                    <p className="upper-text-card">{state[0].author}</p>
                                                    <p className="upper-text-card">{state[0].pubDate}</p>
                                                </div>
                                                <div className="container">
                                                    <h1 className="title-sidebar">{state[0].title}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Main
