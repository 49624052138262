import React from "react";
import Logo from "../logowhite.svg";
import "./Footer.css";
import facebook from "./img/facebook.svg";
import instagram from "./img/instagram.svg";
import linkedin from "./img/linkedin.svg";
import twitter from "./img/twitter.svg";
import call from "./img/call.svg";
import mail from "./img/mail.svg";
import place from "./img/place.svg";
function Footer() {
  return (
    <>
      <section className="footer py-2">
        <div className="container-fluid pt-5">
          <div className="row my-5 px-5 footer-row-main">
            <div className="col-lg-4 col-md-12">
              <div className="container">
                <div className="logo">
                  <img src={Logo} alt="" className="img-fluid" />
                </div>
                <div className="content-footer my-5">
                  <h3>World class agile product team on demand.</h3>
                </div>
                <div className="container footer-nav-link">
                  <div className="footer-address my-5">
                    <div className="footer-row-two">
                      <div className="col-lg-2 col-md-2">
                        <img src={place} alt="" className="social-img" />
                      </div>
                      <div className="col-lg-10 col-md-10 footer-adrs">
                        {" "}
                        <span>
                          G1 Elegant Apartments, Santhosh Nagar, Shiv Shakti
                          Nagar, Jaipur, Rajasthan 302019, India
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 my-3">
              <div className="container footer-nav-link footer-cont">
                <h1>Contact Us</h1>
                <div class="footer-email my-4">
                  <div className="footer-row-two">
                    <div className="col-lg-2 col-md-2">
                      <img src={mail} alt="" className="social-img" />
                    </div>
                    <div className="col-lg-10 col-md-10">
                      {" "}
                      <a href="mailto:hi@neurastats.com">hi@neurastats.com</a>
                    </div>
                  </div>
                </div>
                {/* <div className="footer-call my-2">
                                    <div className="footer-row-two">
                                        <div className="col-lg-2 col-md-2"><img src={call} alt="" className="social-img" /></div>
                                        <div className="col-lg-10 col-md-10"> <a href="tel:+918005577501">+91 80055 77501</a></div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-12 my-3">
              <div className="container  footer-nav-link footersec2">
                <h1>Follow Us </h1>
                <div className="content-footer my-5">
                  <div className="social mt-5">
                    <a
                      href="https://in.linkedin.com/company/neurastats"
                      className="mx-2 p-2 social-link"
                    >
                      <img src={linkedin} alt="" className="social-img" />
                    </a>
                    <a
                      href="https://twitter.com/neurastats1"
                      className="mx-2 p-2 social-link"
                    >
                      <img src={twitter} alt="" className="social-img" />
                    </a>
                    <a
                      href="https://www.instagram.com/neurastats/?hl=en"
                      className="mx-2 p-2 social-link"
                    >
                      <img src={instagram} alt="" className="social-img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-line" />
          <p className="footer-copyright">
            Copyright © 2023 Neurastats | Powered by Neurastats
          </p>
        </div>
      </section>
    </>
  );
}

export default Footer;
