import React from 'react'
import frnt from "../images/frnt.svg"
import bcknd from "../images/bcnd.svg"
import mob from "../images/mob.svg"
import mkrt from "../images/mrkt.svg"
function CareerPath() {
    return (
        <>
            <section id="careerpath" className="py-3 odd">
                <div className="container-fluid my-5 careerPath">
                    <div className="container careerPath-header">
                        <h1 className="text-center mx-auto w-50 mb-4">You decide what comes next. Choose <span className="light-heading">your career path</span> </h1>
                        <p className="text-center mx-auto w-50 mb-5">We want to offer our team members personalized opportunities for growth, based on their level of experience. There is always room for development.</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="careerpath-card">
                                    <div className="img-box" style={{ backgroundImage: `url(${frnt})` }}>

                                    </div>
                                    <div className="container context-box text-center py-4">
                                        <h1 className="mb-5"><a href="" className="coreVlink">Front End</a></h1>
                                        <p className="px-4"> Create beautiful, engaging interfaces and shape user experience.</p>
                                        <a href="" className="opos" data-toggle="modal" data-target="#exampleModalopen" >Apply</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6"><div className="careerpath-card">
                                <div className="img-box" style={{ backgroundImage: `url(${bcknd})` }}>

                                </div>
                                <div className="container context-box text-center py-4">
                                    <h1 className="mb-5"><a href="" className="coreVlink">Back End</a></h1>
                                    <p className="px-4">Build dynamic web content, delighting users and meeting business needs</p>
                                    <a href="" className="opos" data-toggle="modal" data-target="#exampleModalopen" >Apply</a>
                                </div>
                            </div></div>
                            <div className="col-lg-3 col-md-6"><div className="careerpath-card">
                                <div className="img-box" style={{ backgroundImage: `url(${mob})` }}>

                                </div>
                                <div className="container context-box text-center py-4">
                                    <h1 className="mb-5"><a href="" className="coreVlink">Mobile</a></h1>
                                    <p className="px-4">Develop efficient, satisfying experience using Android, React Native, and iOS technologies.</p>
                                    <a href="" className="opos" data-toggle="modal" data-target="#exampleModalopen" >Apply</a>
                                </div>
                            </div></div>
                            <div className="col-lg-3 col-md-6"><div className="careerpath-card">
                                <div className="img-box" style={{ backgroundImage: `url(${mkrt})` }}>

                                </div>
                                <div className="container context-box text-center py-4">
                                    <h1 className="mb-5"><a href="" className="coreVlink">Marketing</a></h1>
                                    <p className="px-4">Curate our message and deliver valuable content to a wide audience..</p>
                                    <a href="" className="opos" data-toggle="modal" data-target="#exampleModalopen" >Apply</a>
                                </div>
                            </div></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CareerPath
