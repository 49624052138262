import React from 'react'
import IMG from "../Home/img/Test.svg"
function ServiceCompTwo(props) {
    return (
        <>
            <div className="container-fluid my-5 py-5">
                <div className="container service-comp-hd-container my-5">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 my-auto">
                            <h1 className="heading ">{props.title}</h1>
                        </div>
                        <div className="col-lg-7 col-md-7 my-auto">
                            <p className="para  my-auto">{props.para}</p>
                        </div>
                    </div>
                </div>
                <div className="container my-5 py-5">
                    <h1 className="service-heading">{props.title} <span className="light-heading">Service</span></h1>
                </div>
                <div className="container-fluid my-5 px-3">
                    <div className="row text-center">
                        <div className="col-lg-3 col-md-6 service-details">
                            <div className="container-fluid service-wrapper text-center">
                                {props.fcode1}
                                <h1 className="my-4 service-sub-heading">{props.subTitleOne}</h1>
                                <p className="my-2 mx-auto  service-para">{props.paraOne}</p>
                                <button className="btn btn-serv  serv-btx"><a href={props.linkOne}>Learn More</a> </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 service-details">
                            <div className="container-fluid service-wrapper text-center">
                                {props.fcode2}
                                <h1 className="my-4 service-sub-heading">{props.subTitleTwo}</h1>
                                <p className="my-2 mx-auto  service-para">{props.paraTwo}</p>
                                <button className="btn btn-serv  serv-btx"><a href={props.linkTwo}>Learn More</a> </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 service-details">
                            <div className="container-fluid service-wrapper text-center">
                                {props.fcode3}
                                <h1 className="my-4 service-sub-heading">{props.subTitlethree}</h1>
                                <p className="my-2 mx-auto  service-para">{props.parathree}</p>
                                <button className="btn btn-serv serv-btx"><a href={props.linkthree}>Learn More</a> </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 service-details">
                            <div className="container-fluid service-wrapper text-center">
                                {props.fcode4}
                                <h1 className="my-4 service-sub-heading">{props.subTitlefour}</h1>
                                <p className="my-2 mx-auto  service-para">{props.parafour}</p>
                                <button className="btn btn-serv  serv-btx"><a href={props.linkfour}>Learn More</a> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceCompTwo
