import React from 'react'
import hear from "../images/hearthem.svg"
function HearThe() {
    return (
        <>
            <div className="container-fluid career-header">
                <div className="container header-heaerthem">
                    <h1 className="heading w-50">It's all about people. Let’s <span className="light-heading">hear them out</span> .</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 hearthem-context-box my-auto">
                            <img src="" alt="" />
                            <div className="container">
                                <h1 className="heading mb-5">
                                    The designing challenges and the leadership aspect are my favourite parts of the job. In Neurastats, I discovered that I’ve been given the opportunity to support colleagues in overcoming challenges.
                                </h1>
                                <p className="bold">Sakshi Mathur</p>
                                <p>Senior Graphics Designer</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center hearthem-img-box">
                            <img src={hear} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HearThe
