import React from 'react'

function Faq() {

    var i, icon, elements;
    elements = document.getElementsByClassName("whyPara");
    icon = document.getElementsByClassName("arrowIcon");
    function textChange(ele, id) {
        console.log(id);
        if (document.getElementById(id).className.includes(" rotate")) {
            console.log("yes");
            document.getElementById(id).className = document.getElementById(id).className.replace(" rotate", "")
            ele.className = ele.className.replace(" aboutActive", "")

        }
        else {
            for (i = 0; i < icon.length; i++) {
                icon[i].className = icon[i].className.replace(" rotate", "");
            }
            for (i = 0; i < elements.length; i++) {
                elements[i].className = elements[i].className.replace(" aboutActive", "");
            }
            document.getElementById(id).classList.add("rotate")
            console.log(ele);
            ele.className += " aboutActive";
        }




    }

    return (
        <div>
            <div className="container-fluid faq">
                <div className="container ">
                    <h1 className="text-center mx-auto w-50 mb-4">What you wanted to know about <span className="light-heading">working with us – FAQ</span> </h1>
                    <p className="text-center mx-auto w-50 mb-5">All your frequently asked questions about working at Neurastats answered.</p>
                </div>
                <div className="container my-5">
                    <div id="accordion">
                        <div class="card-collapse whyPara">
                            <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn collapse-btn whyPara aboutActive" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={e => textChange(e.target, "PlanIcon")}>
                                        How did the company react to the pandemic, how did it affect employment? <span className="arrowIcon rotate" id="PlanIcon"><i class="fas fa-chevron-right "></i></span>
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    In response to the pandemic, in March 2020 we have introduced a set of precautionary measures intended to keep our team members, clients, and partners safe. These included closing offices, suspending business travels, moving onboardings online (learn more in Neurastats COVID-19 Policy Statement). This 24-hour shift to remote working wouldn't be possible without our 8+ months of being a remote-friendly company.
                                    Neurastats remains a stable company: we are constantly developing and taking on more and more new projects. We’re also constantly looking for new people to join us.
                                </div>
                            </div>
                        </div>
                        <div class="card-collapse">
                            <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={e => textChange(e.target, "ExpertIcon")}>
                                        What does the recruitment process look like? <span className="arrowIcon" id="ExpertIcon"><i class="fas fa-chevron-right "></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div class="card-body">
                                    Below, you will find the basic stages of the recruitment process. Please note that depending on the team, there may be slight differences, but generally speaking, here's what's going to happen:
                                    <br /><br />
                                    <p className='mb-2 bold'>  Send your application</p>

                                    Once you click the “apply” button on our careers page and fill the questionnaire. We recommend you upload your CV in English as the majority of the recruitment process is conducted in this language.
                                    If you’re a developer, paste a link to your Github or Bitbucket profile - we will be able to check your code before moving forward with the next steps. If your application meets our requirements, you’ll be invited to the next stage.
                                    <br /><br />
                                    <p className='mb-2 bold'>Recruitment tasks</p>

                                    During the recruitment process, we would like to get to know your skills and thinking in practice as much as possible - that's why we encourage you to do the assignment that is strongly connected with the tasks performed on a daily basis.
                                    You will have a chance to show us your best self. As much as your resume and experience matter, we care about your attitude and skillset a lot! The deadline for sending us the tasks is usually up to 5 days (but you can get more time if needed), depending on complexity. The task should take you an average 3-5 working days to complete. We'll be in touch for the whole time.
                                    Please, keep in mind that you are doing work solely for the purposes of the recruitment process. We guarantee that the content of the task will not be used commercially.
                                    <br /><br />
                                    For non-tech positions, where we require an advanced level of English speaking skills (C1/C1+), we often conduct a short online language audit at this stage. The conversation led by our auditor aims at checking your level of proficiency in English, after which you will receive full feedback from us in regards to your vocabulary, grammar, flow, and pronunciation.
                                    <br /><br />
                                    <p className='mb-2 bold'> The interview</p>

                                    After we have checked all your tasks, we send interview invitations to candidates who rocked with their answers. The interview is usually conducted online via Google Hangouts by one person from the Recruitment Team and one person from the team you’re applying to join.
                                    <br /><br />
                                    This part of the recruitment process will focus more on your suitability for the role - your experience, skills, and attitude, as well as your motivation and aspirations.
                                    What we care about most is simply to get to know you better, and thus we would appreciate your honesty & openness.
                                    <br /><br />
                                    <p className='mb-2 bold'>The final meeting</p>

                                    During the final meeting, you will have a chance to meet your potential leader. We will delve deeper into the answers from the previous stages and focus on your career goals, experience, and motivation. We will provide you the space to clarify any doubts or questions that you may have.
                                    <br /><br />
                                    <p className='mb-2 bold'>Pair programming (for developers only)</p>

                                    Pair programming sessions are constructed in a way that allows you to show your best self while working on everyday tasks. We will expect you to code and share your screen so that we can understand your way of thinking. You are of course allowed to search for any information needed to complete the task – check the documentation, Google anything you need.
                                    We conduct this whole meeting remotely, via Google Hangouts, so you can relax and sit comfortably on your own couch.
                                    <br /><br />
                                    <p className='mb-2 bold'>Job offer</p>

                                    If everything goes well at each of these 4 steps - you’ll find a job offer in your mailbox very soon.
                                </div>
                            </div>
                        </div>
                        <div class="card-collapse">
                            <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                    <button class="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={e => textChange(e.target, "PriceIcon")}>
                                        I didn't get through the recruitment process the first time. Can I try again? <span className="arrowIcon" id="PriceIcon"><i class="fas fa-chevron-right "></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div class="card-body">
                                    Sure! We encourage you to boost your skills and try again. If you didn’t get the job you applied for - feel free to send your application for any other position that suits your requirements. In case you’d like to give the same position another try, we ask you to wait 6 months before your next attempt. Also remember that you can always ask for feedback.
                                </div>
                            </div>
                        </div>
                        <div class="card-collapse">
                            <div class="card-header" id="headingFour">
                                <h5 class="mb-0">
                                    <button class="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={e => textChange(e.target, "MeetingIcon")} >
                                        Why is the recruitment process conducted in English and what does the language audit look like?<span className="arrowIcon" id="MeetingIcon"><i class="fas fa-chevron-right "></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                <div class="card-body">
                                    Over 90 percent of our clients are foreign companies, including most from such markets as the US, UK, Germany, and Switzerland. This is why, to ensure transparent communication and foster an inclusive culture, the basic language of Neurastats is English. It’s also why it’s natural for us to check your language skills during the recruitment process.
                                    During the language audit, we use the help of an external language auditor in processes where we require level C1 or higher. These are, for example, Business Development positions or Project Managers, where contact with the client is required on a daily basis. We care about a reliable assessment of a specialist who, after the audit, provides us, and then the candidate, with a detailed description of the audit results.
                                </div>
                            </div>
                        </div>
                        <div class="card-collapse">
                            <div class="card-header" id="headingfive">
                                <h5 class="mb-0">
                                    <button class="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive" onClick={e => textChange(e.target, "icon6")} >
                                        Will I be recruited for a specific project?<span className="arrowIcon" id="icon6"><i class="fas fa-chevron-right "></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                                <div class="card-body">
                                    No, we’re looking for long-term cooperation, not gigs. Projects are assigned by our PMO team when you’re onboarded. You usually work on one project at once, and it’s possible to change it after some time if you’ve been assigned to a very long one
                                </div>
                            </div>
                        </div>
                        <div class="card-collapse">
                            <div class="card-header" id="headingsix">
                                <h5 class="mb-0">
                                    <button class="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix" onClick={e => textChange(e.target, "icon7")} >
                                        Can I work 100% remotely?<span className="arrowIcon" id="icon7"><i class="fas fa-chevron-right "></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapsesix" class="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                                <div class="card-body">
                                    Yes, you can work 100% remotely and within flexible working hours. You can work however you feel is best for you, as long as you are able to join your meetings (online). It is also important to mention that we all live in a similar time zone, which is one of the main reasons why we hire candidates from selected parts of the world.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
