
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function UE() {

    const [cardData, setcardData] = useState([
        {
            "header": "Design web and mobile apps",
            "para": "Create seamless UX and beautiful UI for different platforms and devices"
        },
        {
            "header": "Validate your ideas",
            "para": "Quickly create and validate prototypes to reduce the risk of failure"
        },
        {
            "header": "Improve conversions",
            "para": "Increase the number of leads and sales from your website"
        },
        {
            "header": "Streamline experience",
            "para": "Discover bottlenecks in the user journey and fix them"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Build human-centered products that deliver results with UX design services</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Solve users’ problems and create a competitive advantage by providing the ultimate user experience</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Exceptional UX design engages users and helps you achieve your business goals
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">UX services help you create products that are not only highly tailored to users’ needs and easy to use, but also deliver tangible results to your business.
                                            <br />
                                            Building a successful product goes far beyond just making it beautiful – great UX leads users through a seamless journey. Achieving this requires a deep understanding of your users and their needs, intelligent UX design, and rigorous testing..</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Create intuitive experiences that users love</h1>
                                    <p className="para text-center">Bring UX designers on board to make your product shine
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Design process based on best practices and methodologies that deliver
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">At Neurastats, we have spent years honing our processes and incorporating industry best practices to provide the highest standards of UX design. While your users’ needs and behaviors may change over time, we have the proven tools and battle-tested methodologies to consistently deliver exceptional products and services, ensuring superior user experience every time.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div id="ui" className="col-lg-8 col-md-8">
                                        <div className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">UX and UI: The perfect duo</h1>
                                            <p className="px-4 para">Each product’s design consists of two codependent parts – user experience (UX) and the user interface (UI). UX is the structural representation of the product’s content and its features. Good UX design should be based on thorough research, data analysis, and knowledge about the motor skills and psychological abilities of the end-user group. It should also take into account the company’s brand strategy, KPIs, and business goals and obstacles.
                                                <br />
                                                UX is about making digital products usable and practical, keeping the simplest possible visual styling. User interface design focuses on usability achieved through an aesthetically pleasing layout. The user interface is not only about an app’s screens. A designer needs to set the color palette, contrast, typography, and the final dimensions of components keeping in mind facets such as grid, baseline, responsiveness, and corporate brand identity.

                                            </p>
                                        </div>
                                        <div id="ux" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">UX design</h1>
                                            <p className="px-4 para">User experience design lies at the base of the product design process, which, in a classic scenario, consists of the following steps. Starting with the discovery stage, we move further through ideation and concept, then wireframing, user testing, implementing recommendations - development, and finally, design handover, which is the project’s termination.
                                                <br />
                                                The first four steps are a part of UX design, whereas the final three steps form UI design. UX designers should first get to know the product’s potential customers as deeply as possible. They may conduct interviews, lead workshops, or spend time reading about brand strategy or the end-user group. After the UX designer gathers enough information, they begin preparing user flow maps, sitemaps, and product prototypes, which are subsequently tested with a select group of users.

                                            </p>
                                        </div>
                                        <div id="ur" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">User research</h1>
                                            <p className="px-4 para">User research focuses on understanding users’ behavior, needs, and motivations through observation techniques, task analysis, and other feedback methodologies.
                                                <br />
                                                This field of research aims to improve the usability of products by incorporating experimental and observational research methods to guide the design, development, and refinement of a product. User researchers often work alongside designers, engineers, and programmers in all stages of product creation and idealization.</p>
                                        </div>
                                        <div id="wp" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Wireframes and prototypes</h1>
                                            <p className="px-4 para">A wireframe is a basic visual interface guide that outlines the structure of an interface and the relationships between its pages. A wireframe serves as a blueprint that defines a project’s structure, content, and functionality. Wireframes are created before any visual design work starts so that the focus is on the information architecture without the distraction of color or visual elements.
                                                <br />
                                                A prototype, on the other hand, is a simulation or early sample version of a final product. The main goal of prototyping is to test a product or idea before investing time and money into development. You can mock up every interaction, show all the features and views so that it can be experienced the same way as a fully developed product without engaging a developer. Prototypes validate usability issues, reveal areas for improvement, and verify overall UX strategy.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#ui" className="content-a">UX and UI: The perfect duo</a></p>
                                            <p className="px-4"><a href="#ux" className="content-a">UX design</a></p>
                                            <p className="px-4"><a href="#ur" className="content-a">User research</a></p>

                                            <p className="px-4"><a href="#wp" className="content-a">Wireframes and prototypes</a></p>
                                        </div>
                                    </div>
                                </div></div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Business intelligence"} paraone={"Transform data into actionable intelligence that informs an organization’s strategic and tactical business decisions."} subTitletwo={"Data annotation"} paratwo={"Annotate data quickly and effectively through the community of professionals on our platform."} fcode1={<i class="fas fa-registered"></i>} fcode2={<i class="fas fa-print"></i>} linkone={"/service/ml/BI"} linktwo={"/service/ml/DA"} />
        </>
    )
}

export default UE
