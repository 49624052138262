import React from 'react'
import ServiceComp from './ServiceComp'
import Design from "./ServiceIMG/Design.svg"
import AI from "./ServiceIMG/AI.svg"
import Block from "./ServiceIMG/Block.svg"
import Test from "./ServiceIMG/Test.svg"
import Webdev from "./ServiceIMG/Webdev.svg"
import Digital from "./ServiceIMG/Digital.svg"
import Fade from 'react-reveal/Fade';
function Service() {
    return (
        <>
            <section className="service-section odd py-3" id="serviceSection">
                <div className="container-fluid home-service-header">
                    <Fade cascade>
                        <div className="container-fluid ">
                            <p className="service-head">Our Services</p>
                            <h1 className="heading2"> <span className="light-heading"> Experts </span> in every aspects of  the product lifecycle</h1>
                        </div>
                    </Fade>
                </div>
                <div className="container-fluid service-box ">

                    <div className="row h-s-row">
                        <ServiceComp title={"Ideation"} img={Test} para={"When you have a brilliant idea, it's very tempting to immediately jump into the development of your product. However, it's essential to define what exactly you're trying to achieve to accomplish your business goals ."} link={"/service#Ideation"} />
                        <ServiceComp title={"Product design"} img={Design} para={"Product design is at the center of every company's success today. Design has become the key differentiator in most markets, and software products are no exception."} link={"/service#design"} />
                        <ServiceComp title={"Web Development"} img={Webdev} para={"Software development is an ever-evolving process. Instead of a public repository, we start your project with private repository so that your software will be kept secure."} link={"/service#WDevelopment"} />

                    </div>

                    <div className="row h-s-row home-service-row">
                        <ServiceComp title={"Mobile development"} img={Digital} para={"Mobile applications are now more popular than ever and have increasingly become an essential part of any modern business. Neurastats aims of creating stunning mobile applications and powerful web apps along with with blazing-fast performance."} link={'/service#ADevelopment'} />
                        <ServiceComp title={"Blockchain"} img={Block} para={"Uncensor your data without fear. Your Wealth. Your Security. Your Choice."} link={"/service#Blockchain"} />

                        <ServiceComp title={"AI.ML"} img={AI} para={"AI is taking the world by storm. Working in digital transformation, data science, machine learning, or AI are the future of the business and Neurastats takes proud on early adoption of it."} link={"/service#AI"} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service
