import React from 'react'
import Footer from "./Footer/Footer"
import Home from "./Home/Home"
import About from "./About/About"
import Contact from "./Contact/Contact"
import Service from './Service/Service'
import Detail from "./Details/Detail"
import Idl from "./Details/Idl"
import CaseStudy from './CaseStudy/CaseStudy'
import Career from './Careers/Career'
import Post from './CaseStudy/Post'
import Estimate from './EstimateProject/Estimate'
import PDS from './Ideation/PDS'
import SS from './Ideation/SS'
import UX from './Ideation/UX'
import RD from './Ideation/RD'
import BI from "./ML/BI"
import DA from "./ML/DA"
import DT from "./ML/DT"
import ML from "./ML/ML"
import AN from "./MobDev/AN"
import FLT from "./MobDev/FLT"
import IOS from "./MobDev/IOS"
import MD from "./MobDev/MD"
import BR from "./ProductDesign/BR"
import ILU from "./ProductDesign/ILU"
import PDsn from "./ProductDesign/PDsn"
import UE from "./ProductDesign/UE"
import UI from "./ProductDesign/UI"
import ND from "./WebDev/ND"
import PY from "./WebDev/PY"
import WD from "./WebDev/WD"
import Ruby from "./WebDev/Ruby"
import EstimateProject from './EstimateProject/EstimateProject'
import { Switch, Route, Redirect } from "react-router-dom"
import "./Details/ServiceTwo.css"
function App() {

    return (
        <>
            <section className="rootmain">
                <Estimate />
                <Switch>
                    <Route exact path="/home"><Home /></Route>
                    <Route exact path="/about"> <About /></Route>
                    <Route exact path="/contact"><Contact /></Route>
                    <Route exact path="/service">  <Service /></Route>
                    <Route exact path="/detail">  <Detail /></Route>
                    <Route exact path="/service/idl/pds"> <PDS /></Route>
                    <Route exact path="/service/idl/ss"> <SS /></Route>
                    <Route exact path="/service/idl/ux"> <UX /></Route>
                    <Route exact path="/service/idl/rd"> <RD /></Route>
                    <Route exact path="/service/ml/BI"> <BI /></Route>
                    <Route exact path="/service/ml/DA"> <DA /></Route>
                    <Route exact path="/service/ml/DT"> <DT /></Route>
                    <Route exact path="/service/ml/ML"> <ML /></Route>
                    <Route exact path="/service/md/AN"> <AN /></Route>
                    <Route exact path="/service/md/FLT"> <FLT /></Route>
                    <Route exact path="/service/md/IOS"> <IOS /></Route>
                    <Route exact path="/service/md/MD"> <MD /></Route>
                    <Route exact path="/service/pd/br"> <BR /></Route>
                    <Route exact path="/service/pd/ilu"> <ILU /></Route>
                    <Route exact path="/service/pd/pdsn"> <PDsn /></Route>
                    <Route exact path="/service/pd/ue"> <UE /></Route>
                    <Route exact path="/service/pd/ui"> <UI /></Route>
                    <Route exact path="/service/wd/nd"> <ND /></Route>
                    <Route exact path="/service/wd/py"> <PY /></Route>
                    <Route exact path="/service/wd/ruby"> <Ruby /></Route>
                    <Route exact path="/service/wd/wd"> <WD /></Route>
                    <Route exact path="/CaseStudy">  <CaseStudy /></Route>
                    <Route exact path="/CaseStudy/post/:id">  <Post /></Route>
                    <Route exact path="/Career">  <Career /></Route>
                    <Route exact path="/testing">  <EstimateProject /></Route>
                    <Redirect to="/home" />
                </Switch>
            </section>
            <Footer />
        </>
    )
}

export default App
