import React, { useState } from 'react'
import map from "./img/map.svg"
import emailjs from 'emailjs-com';
import validator from 'validator'


function Form() {
  const [formMSG, setFormMSG] = useState(<p></p>)
  const [formMSG2, setFormMSG2] = useState(<p></p>)
  const [email, setemail] = useState("")
  const [message, setmessage] = useState("")
  const [name, setname] = useState("")
  const [toastMsg, setToastMsg] = useState(<p></p>)
  function sendEmail(e) {
    e.preventDefault();
    if (name === "") {
      setFormMSG2(<p className="ml-5 py-3 px-4 w-75 error">
        Please enter valid Name
      </p>)
    }
    else {
      setFormMSG2(<p className="ml-5 py-3 px-4 w-75 error">

      </p>)
    }

    if (email === "") {
      setFormMSG(<p className="ml-5 py-3 px-4 w-75 error">
        Please enter valid email
      </p>)
    } else {
      if (validator.isEmail(email)) {
        setFormMSG(<p></p>)
        emailjs.sendForm('service_lsmfvtk', 'template_wqnqwfy', e.target, 'user_3oL0OoZvgtHcOdpxlBKNC')
          .then((result) => {
            console.log(result.text);
            setToastMsg(<p className="ml-5 py-3 px-4 w-75  success">
              Thanks for enquiring with Neurastats, our team will contact you promptly to discuss next steps {name}
            </p>)
          }, (error) => {
            console.log(error.text);
            setToastMsg(<p className="ml-5 py-3 px-4 w-75 error">
              "Message not send ! Please check your internet connection"
            </p>)
          });

        e.target.reset();
        setemail("")
        setFormMSG("")
        setFormMSG2("")
        setmessage("")
        setname("")
      }
      else {
        setFormMSG(<p className="ml-5 py-3 px-4 w-75 error">
          Please enter valid email
        </p>)
      }
    }

  }


  return (
    <>

      <div className="container-fluid contact-text-part2 px-4">

        <div className="container-fluid my-5 form-box ">
          <div className="container mb-4 contact-req-box">
            <h1 className="contact-heading">Request Free Consultation</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 pt-5 px-3 form-ele-container">
              <form onSubmit={sendEmail}>
                <div class="form-group mb-5">
                  <input type="text" class="form-control ml-5 py-3 px-4 w-75" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" name="to_name" value={name} onChange={e => setname(e.target.value)} />
                  {formMSG2}
                </div>
                <div class="form-group mb-5">
                  <input type="email" class="form-control ml-5 py-3 px-4 w-75" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" name="from_name" value={email} onChange={e => setemail(e.target.value)} />
                  {formMSG}
                </div>

                <div class="form-group mb-4">
                  <textarea class="form-control ml-5 py-3 px-4 w-75" id="exampleFormControlTextarea1" rows="5" placeholder="Message" name="message" value={message} onChange={e => setmessage(e.target.value)} ></textarea>
                  {toastMsg}
                </div>

                <button type="submit" class="btn Req-btn-form ml-5 mb-5 py-2 px-5" id="liveToastBtn">Submit</button>
              </form>

            </div>
            <div className="col-lg-6 col-md-12 map-img-box " >

              <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.669488957154!2d75.75508941504427!3d26.882239983140707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5790802e8e5%3A0x6949cdf3037f2d49!2sNeurastats!5e0!3m2!1sen!2sin!4v1628926247312!5m2!1sen!2sin" allowfullscreen={true} loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form
