import React from 'react'

function CaseCard(props) {
    return (

        <div className="col-lg-6 col-md-6 mb-4">
            <a href={`/CaseStudy/post/${props.id}`} className="dics-link ">
                <div className="container-fluid cardcard ">
                    <div className="container-fluid py-3 ">
                        <div className="container-fluid d-flex justify-content-between">
                            <p className="upper-text-card">By {props.author}</p>
                            <p className="upper-text-card">{props.date}</p>
                        </div>
                        <div className="container">
                            <div className="row-5">
                                <img src={props.img} alt="" className="img-fluid" />
                            </div>
                            <div className="row-7"> <h1 className="title">{props.title}</h1>
                                <p className="disc" dangerouslySetInnerHTML={{ __html: props.disc.substring(0, 95) }}></p>
                                <a href={`/CaseStudy/post/${props.id}`} className="dics-link ">Go To Post</a></div>

                        </div>
                    </div>
                </div>
            </a>
        </div >

    )
}

export default CaseCard
