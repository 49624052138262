import React from 'react'
import Design from "./img/Design.svg"
import Fade from 'react-reveal/Fade';
function ServiceComp(props) {
    return (
        <>
            <div className="col-lg-4 col-md-12 col-box">
                <Fade cascade>
                    <div className="serv-img-box">
                        <img src={props.img} alt="" className="serv-img" />
                    </div>
                    <div className="serv-content">
                        <h1 className="serv-title">{props.title}</h1>
                        <p className="mb-5 w-75">{props.para}.</p>
                        <button className="btn btn-serv"> <a href={props.link}>Learn More</a> </button>
                    </div>
                </Fade>
            </div>
        </>
    )
}

export default ServiceComp
