import React, { useState } from 'react'
import "./Estimate.css"
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
import Navbar2 from '../Navbar/Navbar2';
import image from "./img/estimate.svg";

function EstimateProject() {
    const [formMSG2, setFormMSG2] = useState(<p></p>)
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [msg, setmsg] = useState(<p></p>)
    function sendEmail(e) {
        e.preventDefault();
        if (name === "") {
            setFormMSG2(<p className="ml-5 py-3 px-4 w-75 error">
                Please enter valid Name
            </p>)
        }
        else {
            setFormMSG2(<p className="error">

            </p>)
        }
        if (email === "") {
            setmsg(<p className="ml-5 py-3 px-4 w-75 error">
                Please enter valid email
            </p>)
        } else {
            if (validator.isEmail(email)) {
                setmsg(<p></p>)
                emailjs.sendForm('service_lsmfvtk', 'template_e15qjxg', e.target, 'user_3oL0OoZvgtHcOdpxlBKNC')
                    .then((result) => {
                        console.log(result.text);
                        toast(`Thanks for enquiring with Neurastats, our team will contact you promptly to discuss next steps ${name}`, {
                            position: "top-center",
                            progressClassName: 'progress-bar',
                            bodyClassName: "grow-font-size",
                        });
                    }, (error) => {
                        console.log(error.text);
                        toast("Message Not Sendded ! check you network connection", {
                            position: "top-center",
                            progressClassName: 'progress-bar',
                            bodyClassName: "grow-font-size",
                        });
                    });
                e.target.reset()
                setname("")
                setemail("")
                setmsg("")
            } else {
                setmsg(<p className="ml-5 py-3 px-4 w-75 error">
                    Please enter valid Email
                </p>)
            }
        }
    }



    return (
        <>
            <Navbar2 navClass="white-nav" />
            <section className="estimate-header">
                <div className="container-fluid estimate-text-part px-4">
                    <div className="container-fluid about-header-main px-5 py-5">
                        <h1 className="estimate-heading">Estimate Project</h1>
                        <p className="para my-4">Need an estimate? You're in the right place!
                        </p>

                        <p className="about-para w-75">Fill in the form and tell us about your project. Our team will contact you promptly to discuss next steps.
                        </p>
                    </div>
                </div>

            </section>

            <div className="container estimate-form-box p-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <form onSubmit={sendEmail} noValidate={true} className="esti-form">
                                <div className="form-group mb-2">
                                    <label htmlFor="recipient-name" className="col-form-label">Name</label>
                                    <input type="text" className="form-control p-3" id="recipient-name" name="to_name" value={name} onChange={e => setname(e.target.value)} />
                                    {formMSG2}
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor="recipient-email" className="col-form-label">Email</label>
                                    <input type="email" className="form-control p-3" id="recipient-email" name="from_name" value={email} onChange={e => setemail(e.target.value)} />
                                    {msg}
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="message-text" className="col-form-label">Message</label>
                                    <textarea className="form-control p-3" id="message-text" name="message"></textarea>
                                </div>

                                <div className="estimate-form-btnbox">
                                    <button type="submit" className="btn modal-btn-send my-3 py-3 px-5">Send message</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 col-md-6 my-auto estimate-img">
                            <img src={image} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default EstimateProject

