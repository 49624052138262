import React from "react";
import "./Counting.css";
import Fade from "react-reveal/Fade";
function Counting() {
  return (
    <>
      <div className="container prl-5 counting-box ">
        <div className="row counting-row">
          <div className="col-lg-3 col-md-3 text-center my-auto">
            <Fade cascade>
              <div className="container ">
                <h1 className="h2-sm-txt">Some Numbers and Counting...</h1>
              </div>
            </Fade>
          </div>
          <div className="col-lg-9 col-md-9">
            <div className="row text-center pt-5 h2-sm-txt2">
              <Fade cascade>
                <div className="col-lg-3 col-md-3 col-sm-6  h2-sm-bl">
                  <h1>20+</h1>
                  <p>
                    Satisfied <br /> clients
                  </p>
                </div>
              </Fade>
              <Fade cascade>
                <div className="col-lg-3 col-md-3 col-sm-6 h2-sm-bl">
                  <h1>42</h1>
                  <p>
                    Projects <br /> Completed
                  </p>
                </div>
              </Fade>
              <Fade cascade>
                <div className="col-lg-3 col-md-3 col-sm-6 h2-sm-bl ">
                  <h1>10</h1>
                  <p>
                    Accolades <br /> Earned
                  </p>
                </div>
              </Fade>
              <Fade cascade>
                <div className="col-lg-3 col-md-3 col-sm-6 ">
                  <h1>200k+</h1>
                  <p>
                    Lines of <br /> Code
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Counting;
