import React from 'react'
import Navbar2 from '../Navbar/Navbar2'
import "./Contact.css"
import Header from './Header'
import Getintouch from './Getintouch'
import StartProject from "../StartProject/StartProject"
function Contact() {
    return (
        <>
            <Navbar2 navClass="white-nav" />
            <Header />
            <Getintouch />
            <div className="container-fluid">
                <StartProject />
            </div>


        </>
    )
}

export default Contact
