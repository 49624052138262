
import React, { useEffect, useRef } from 'react'
import Approch from "./Approch/Approch"
import Expertise from "./Expertise/Expertise"
import Overview from "./Overview/Overview"
function ServicetabTwo() {
    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approch</a>
                    <a href="#Expertise" id="acExp">Expertise</a>

                </div>
                <div className="container-fluid tabs-contents">
                    <secton id="Overview" className="serviceTabSection">
                        <Overview />
                    </secton>
                    <secton id="Approch" className="serviceTabSection">
                        <Approch />
                    </secton>
                    <secton id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </secton>
                </div>
            </div>
        </>
    )
}

export default ServicetabTwo
