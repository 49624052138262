
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function FLT() {

    const [cardData, setcardData] = useState([
        {
            "header": "Expand your reach",
            "para": "1.8 billion people worldwide purchase goods online. Sell more with convenience, ease, and speed"
        },
        {
            "header": "Increase brand exposure",
            "para": "Apps account for over 90% of the time spent online time using smartphones. Be where your customers are"
        },
        {
            "header": "Build engagement and loyalty",
            "para": "Send out relevant marketing messages at the right time and use push notifications to increase retention"
        },
        {
            "header": "Optimize your tactics",
            "para": "Collect and use data to create an exceptional user experience and increase the effectiveness of your efforts"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Build and deploy apps for iOS and Android with Flutter app development</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Get your app onto the market fast using a single codebase</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Cross-platform mobile app development made easy
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Flutter is Google's mobile app software development kit (SDK) created to build cross-platform mobile, web, and desktop applications. It's a complete environment with a framework, widgets, and tools, which lets you develop mobile apps efficiently.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Achieve your business goals with Flutter mobile app development</h1>
                                    <p className="para text-center">Combine your business objectives with technological solutions in a smart way to achieve better results
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Get native performance with a single cross-platform code base
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Flutter is open source software – the SDK uses a C++ rendering engine and is based on the Dart programming language. It also provides widgets out of the box, and its architecture is based on reactive programming, which means that web and mobile apps built with Flutter are extremely responsive and provide the user with high performance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div id="one" className="col-lg-8 col-md-8">
                                        <div className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Use Flutter to reach users of both iOS and Android devices</h1>
                                            <p className="px-4 para">Building an app with native performance is simple using a Flutter development process.
                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Customizable UI widgets</h1>
                                            <p className="px-4 para">The development model is based on objects. In Flutter, each object – button or font – is a widget. Widgets are at the core of the Flutter concept – using them is a lot like building with blocks, and they allow you to design beautiful apps easily.
                                                <br />
                                                At the same time, your Flutter app development team gets access to the lowest level of the code, where they can create new widgets – customized to your needs. They do so using the same tools the Flutter team used to build the default widgets.
                                            </p>
                                        </div>
                                        <div id="three" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Hot reload for faster design</h1>
                                            <p className="px-4 para">Flutter features hot reload, a valuable function that boosts the productivity of developers. Hot reload lets developers see their changes in real time, allowing them to code faster and with greater accuracy.
                                            </p>
                                        </div>
                                        <div id="four" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Easy to learn</h1>
                                            <p className="px-4 para">When using a new SDK in a project, you should always take a closer look at how many people are using it and how steep the learning curve is. In the case of Flutter, both are very promising. While the main challenge is knowledge of Dart (frameworks like React Native use the more popular JavaScript), even beginner developers can build quick prototypes and apps with Flutter.
                                                <br />
                                                Thanks to Google's support and developers contributing to the open source project, Flutter is growing rapidly. This means that there is an increasing number of Flutter developers, making it more accessible to businesses.
                                            </p>
                                        </div>
                                        <div id="five" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Dart is modern and effective</h1>
                                            <p className="px-4 para">The language behind Flutter is similar to C++ and Java, particularly in the way it compiles the source code. Dart is accessible even for beginners thanks to its unsurprising object orientation and syntax.
                                                <br />
                                                Additionally, the Dart language combines both ahead-of-time (AOT) and just-in-time (JIT) compilation, which gives Flutter apps their high performance. Unlike the languages used in native Android development, Dart does not need separate XML files from the layout, which means faster and easier development.
                                            </p>
                                        </div>
                                        <div id="six" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Cross-cultural accessibility</h1>
                                            <p className="px-4 para">With Flutter, you don't have to worry about internationalization: Flutter supports 24 languages. Apart from this, it has built-in currencies, dates, units of measure, as well as layout options adapted to the languages around the world that are not written from left to right.
                                                <br />
                                                Moreover, Flutter is fully accessible by providing large fonts, enhanced contrast, and screen readers for those who need it.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>

                                            <p className="px-4"><a href="#one" className="content-a">Use Flutter to reach users of both iOS and Android devices</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">Customizable UI widgets</a></p>
                                            <p className="px-4"><a href="#three" className="content-a">Hot reload for faster design</a></p>
                                            <p className="px-4"><a href="#four" className="content-a">Easy to learn</a></p>
                                            <p className="px-4"><a href="#five" className="content-a">Dart is modern and effective</a></p>
                                            <p className="px-4"><a href="#six" className="content-a">Cross-cultural accessibility</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Python Development"} paraone={"Python is currently one of the fastest growing languages to get your job done on time."} subTitletwo={"iOS development"} paratwo={"Develop top-quality iOS apps with cutting edge technology."} fcode1={<i class="fab fa-python"></i>} fcode2={<i class="fab fa-apple"></i>} linkone={"/service/wd/py"} linktwo={"/service/md/IOS"} />
        </>
    )
}

export default FLT
