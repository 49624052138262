import React from 'react'
import Arrow from "./BasicElements/ArrowDown.svg"
import Counting from '../Counting/Counting'
import Fade from 'react-reveal/Fade';
function Header2() {
    return (
        <>
            <section className="header2">
                <div className="container-fluid">
                    <div className="container text-center  h2-mt-ct">
                        <Fade bottom >
                            <h1 >We deploy world-class agile product teams on demand. Teams that can design, build, ship and Scale your vision in most efficient way.</h1>
                        </Fade>
                        <Fade bottom cascade >
                            <a href="#serviceSection" className="my-auto"> <button className="btn px-4 py-3"> <span className="btn-icon pr-2"> <img src={Arrow} alt="" /></span> Have a Look at our Services</button> </a>
                        </Fade>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Header2
