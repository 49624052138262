import React, { useEffect, useState } from "react";
import "./Testimonial.scss";
import { clients, clientsType } from "./Clients";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as EyeLogo } from "./img/eye.svg";
import { ReactComponent as LeftNavigation } from "./img/left_navigation.svg";
import { ReactComponent as RightNavigation } from "./img/right_navigation.svg";

function Testimonial() {
  const [activeMenu, setActiveMenu] = useState(0);
  const [isNext, setIsNext] = useState(false);

  const nextButton = (clients: Array<clientsType>) => {
    setActiveMenu((activeMenu + 1) % clients.length);
    setIsNext(true);
  };

  const prevButton = (clients: Array<clientsType>) => {
    setActiveMenu((activeMenu - 1 + clients.length) % clients.length);
    setIsNext(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextButton(clients);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  });

  const ViewCard = ({
    link,
    logo,
    viewCategory,
  }: {
    link: string;
    logo: React.ReactNode;
    viewCategory: string;
  }) => {
    return (
      <div className="testi-view-card">
        <a href={link}>
          <div className="testi-view-card-categ">
            {logo}
            {viewCategory}
          </div>
        </a>
      </div>
    );
  };

  return (
    <>
      <div style={{ paddingLeft: "1.5rem" }}>
        <p className="service-head">Our Customers</p>
        <h1 className="heading">
          Who <span className="light-heading">believed</span> in us
        </h1>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-main">
          {clients.map((client) => {
            return (
              <div>
                {client.key === activeMenu && (
                  <div className="testi-card-main">
                    <div className="testi-card">
                      <div
                        className={
                          activeMenu === client.key
                            ? "slide active testi-client-logo"
                            : "slide testi-client-logo"
                        }
                      >
                        <div className={isNext ? "nextSlide" : ""}>
                          {<client.logo style={client.style} />}
                        </div>
                      </div>
                      {
                        //small screen photo frame
                      }
                      <div className="photo-frame small-screen">
                        <div
                          className={
                            activeMenu === client.key ? "fade active" : "fade"
                          }
                        >
                          {client.key === activeMenu && (
                            <img
                              src={client.image}
                              alt="pfp"
                              className="client-image"
                            />
                          )}
                        </div>
                      </div>

                      {
                        //end of the small screen
                      }

                      {
                        //small screen client-det
                      }
                      <div
                        className={
                          activeMenu === client.key
                            ? "slide active testi-client-det small-screen"
                            : "slide testi-client-det small-screen"
                        }
                      >
                        <div className={isNext ? "nextSlide" : ""}>
                          <div>{client.name}</div>
                          <div>{client.posi}</div>
                        </div>
                      </div>
                      {
                        //end of the small screen
                      }

                      <div
                        className={
                          activeMenu === client.key
                            ? "slide active testi-client-title"
                            : "slide testi-client-title"
                        }
                      >
                        <div className={isNext ? "nextSlide" : ""}>
                          {client.title}
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu === client.key
                            ? "slide active testi-client-det"
                            : "slide testi-client-det"
                        }
                      >
                        <div className={isNext ? "nextSlide" : ""}>
                          <div>{client.name}</div>
                          <div>{client.posi}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          <div className="slant"></div>
          <div className="photo-frame">
            {clients.map((client) => {
              return (
                <div
                  className={activeMenu === client.key ? "fade active" : "fade"}
                >
                  {client.key === activeMenu && (
                    <img
                      src={client.image}
                      alt="pfp"
                      className="client-image"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="container2">
          {clients.map((client) => {
            return (
              <>
                {client.key === activeMenu && (
                  <div className="testi-view-card-section">
                    <ViewCard
                      viewCategory="View Live Project"
                      link={client.link}
                      logo={<EyeLogo className="testi-view-card-logo" />}
                    />
                  </div>
                )}
              </>
            );
          })}
          <div className="testi-navigation" style={{ gap: "2vw" }}>
            <LeftNavigation
              viewBox="0 0 70 70"
              onClick={() => prevButton(clients)}
            />
            <RightNavigation
              viewBox="0 0 70 70"
              onClick={() => nextButton(clients)}
            />
          </div>

          <div className="testi-navigation small-screen">
            <LeftNavigation
              viewBox="-28 -28 120 120"
              onClick={() => prevButton(clients)}
            />
            <RightNavigation
              viewBox="-28 -28 120 120"
              onClick={() => nextButton(clients)}
            />
          </div>

          <div className="testi-navigation-section">
            <div
              className={
                activeMenu === 0
                  ? "testi-navigation-circle active"
                  : "testi-navigation-circle"
              }
            ></div>
            <div
              className={
                activeMenu === 1
                  ? "testi-navigation-circle active"
                  : "testi-navigation-circle"
              }
            ></div>
            <div
              className={
                activeMenu === 2
                  ? "testi-navigation-circle active"
                  : "testi-navigation-circle"
              }
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
