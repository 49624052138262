
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function BR() {

    const [cardData, setcardData] = useState([
        {
            "header": "Brand recognition",
            "para": "In a digital market visibility is key and high brand awareness is the first step to user acquisition."
        },
        {
            "header": "Bolstered customer loyalty",
            "para": "Inspire customer engagement and retention with a powerful, authentic brand image."
        },
        {
            "header": "Brand credibility",
            "para": "Maintaining brand values creates authenticity - a key factor in user trust and retention."
        },
        {
            "header": "A strong foundation for marketing efforts",
            "para": "Well-defined brand guidelines help make marketing efforts consistent and more powerful."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")

            acExp.classList.remove("achorActive")
        }

        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")

            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")

            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Build a brand identity for the digital age and boost your bottom line with branding services</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Create a branding system that inspires trust and increases the company’s value</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>

                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Grow your business with digital brand creation
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">The success of your brand relies on the power of its digital strategy. Choosing the right digital branding partner will help you accelerate your business growth and attract new customers. Getting a visual identity, design, content and tone right for your target market.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Boost your digital product’s growth with powerful branding</h1>
                                    <p className="para text-center">Build a powerful, recognizable product with a strong brand that will help you achieve your business goals.
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>

                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Business intelligence"} paraone={"Transform data into actionable intelligence that informs an organization’s strategic and tactical business decisions."} subTitletwo={"Data annotation"} paratwo={"Annotate data quickly and effectively through the community of professionals on our platform."} fcode1={<i class="fas fa-registered"></i>} fcode2={<i class="fas fa-print"></i>} linkone={"/service/ml/BI"} linktwo={"/service/ml/DA"} />
        </>
    )
}

export default BR
