import React from 'react'
import fade from "../BrandLogo/fade.svg"
import edukos from "../BrandLogo/edukos.svg"
import gig from "../BrandLogo/gig.svg"
import chandrika from "../BrandLogo/chandrika.svg"
import agineat from "../BrandLogo/agineat.svg"
import udyog from '../BrandLogo/udyog.svg'
import valuelearn from "../images/valuelearn.svg"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
function Header() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div className="container-fluid career-header hw-main">
                <div className="container header-worklf hw-main">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <h1 className="heading">
                                Working at Neurastats means <span className="light-heading"> flexibility, trust and meaningful choices</span>
                            </h1>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <p className="para mb-4">
                                Working at Neurastats means being a part of a community that values growth, mutual trust and respect.
                            </p>
                            <p className="para mb-4">
                                We want to continuously learn and improve, so we maintain a feedback-oriented mindset. And most importantly, we care – about creating sustainable software, about how our actions affect the world, and about each individual employee’s experience.We offer a safe, inclusive and productive environment for all team members, and we’re always open to feedback.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid header-workbrd">
                    <div className="container-fluid text-center">
                        <h1 className="heading ">
                            Work with brands that <span className="light-heading">make a difference</span>
                        </h1>
                        <div className="my-5">

                            <Carousel responsive={responsive}
                                showDots={false} infinite={true} autoPlay={true}
                                autoPlaySpeed={2000}
                            >
                                <div className=" text-center brand-box">
                                    <img src={chandrika} alt="" className="brandlogo" /> <h1 className="brandName">Chandrika</h1>
                                </div>
                                <div className=" text-center brand-box">
                                    <img src={agineat} alt="" className="brandlogo" /> <h1 className="brandName">Again Eat</h1>
                                </div>
                                <div className=" text-center brand-box">
                                    <img src={fade} alt="" className="brandlogo" /> <h1 className="brandName">Fade</h1>
                                </div>
                                <div className=" text-center brand-box"> <img src={edukos} alt="" className="brandlogo" /> <h1 className="brandName">My Edukos</h1></div>
                                <div className=" text-center brand-box"> <img src={gig} alt="" className="brandlogo" /> <h1 className="brandName">GIG</h1></div>
                                <div className=" text-center brand-box"> <img src={udyog} alt="" className="brandlogo" /> <h1 className="brandName">Vardhman Udyog</h1></div>
                            </Carousel>


                        </div>
                    </div>
                </div>
                <div className="container header-worklf my-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <h1 className="heading">
                                Learn about <span className="light-heading"> our values
                                </span>
                            </h1>
                        </div>
                        <div className="col-lg-6 col-md-6">

                            <p className="para mb-4">
                                We’ve taken the time to condense our values into seven key points. They shape the way we work with clients and with each other, and are the foundation for Neurastats company culture. As we grow and improve, we expect these values to evolve alongside us. We use internal initiatives, such as Ask Me Anything sessions with the core team, to keep our values alive
                            </p>
                        </div>
                    </div>
                    <div className="container my-5 text-center">
                        <img src={valuelearn} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
