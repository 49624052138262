import React from "react";
import StartProject from "../StartProject/StartProject";
import Counting from "../Counting/Counting";
function CaseStudy() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 4 },
  ];
  return (
    <>
      <section className="casestudy-section odd py-3">
        <Counting />
        <div className="container-fluid">
          <StartProject />
        </div>
      </section>
    </>
  );
}

export default CaseStudy;
