import React from 'react'
import Header1 from "./Header1"
import Navbar2 from '../Navbar/Navbar2'
import Process from './Process'
import Whyus from './Whyus'
import "./About.css"
import Counting from '../Counting/Counting'
import StartProject from "../StartProject/StartProject"
function About() {
    return (
        <>
            <Navbar2 navClass="white-nav" />
            <Header1 />
            <Process />
            <Whyus />
            <Counting />
            <div className="container-fluid">
                <StartProject />
            </div>

        </>
    )
}

export default About
