
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function PY() {

    const [cardData, setcardData] = useState([
        {
            "header": "Increase user retention",
            "para": "Create a solid, consistent user base by delivering continuous value"
        },
        {
            "header": "Improve user experience",
            "para": "Deliver convenience to your customers with great UI & UX design"
        },
        {
            "header": "Turn interest into engagement and loyalty",
            "para": "Develop an innovative, functional, fast, and reliable web or mobile app that builds loyalty and trust with your users"
        },
        {
            "header": "Reduce business costs",
            "para": "Drive down costs now and later with expert development and maintenance services"
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Mobile App Development smart applications, maximum value</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Innovate faster to deliver peerless performance with cutting-edge mobile solutions</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Grow your business with risk-free mobile solutions
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Successful mobile app development combines three key aspects: market, user, & the product itself. Combining these factors in a smart way - using the best tools and expertise - delivers unique value, great usability, and solid performance to your users.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Enhance your business with mobile app development</h1>
                                    <p className="para text-center">Accelerate results by creating practical, seamless experiences with mobile app solutions
                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Build intuitive, easy-to-use applications that attract and retain user attention
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Mobile app development is an ongoing process of a constant, iterative effort to make your product better and better. Devices and technologies through which users interact with your product will change so as your users, their needs and behaviour. Make sure you have the right tools, and battle-tested methodologies to face these challenges. And never stop the process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="one" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Mobile app development - from idea to execution</h1>
                                            <p className="px-4 para">What goes into mobile development and how does it work?

                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Mobile development company</h1>
                                            <p className="px-4 para">There are over 2.7 million apps on the Google Play store and over 2.2 million on the App store, so it’s a tough market to stand out in and only the best apps thrive. That’s why working with a mobile application development company is the best way to accelerate returns on long-term projects and complex mobile app development.
                                                <br />
                                                An established partner will bring the expertise you need to manage the mobile app development process and thrive in a crowded industry.
                                                <br />
                                                By partnering with a professional mobile development company, you can use the skill and experience of an expert app development team, managed by a project manager. The PM will ensure seamless communication and report back to you about the development process.

                                            </p>
                                        </div>
                                        <div id="three" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">How to choose a mobile app development company?</h1>
                                            <p className="px-4 para">You’ve got a great idea for a new app. Now, you need to choose the right mobile application development company to help you build it.
                                                <br />
                                                Depending on what project stage you’re at, you might want some complementary services before you start to build your app.
                                                <br />
                                                From the Ideation & Strategy stage, we’ll sit down with you to study your idea, devise technical solutions, and plan the business side of the project, including its future growth.
                                                <br />
                                                With over 12 years of experience in software engineering and mobile app development, our expert development team can build the perfect app to suit your business needs.
                                                <br />
                                                Together, we can build an app with excellent front-end UX & UI capabilities and stable, scalable business solutions at the back-end.
                                                <br />
                                                Our top Dribbble team can create wireframes, mockups, and clickable prototypes. At every stage of the app development process, user-centric analysis is performed to optimize a clean, pixel-perfect UI that maximizes engagement across all platforms.
                                                <br />
                                                From day one, we’ll put our Quality Assurance & DevOps pros to work on your project.
                                            </p>
                                        </div>
                                        <div id="four" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">Custom mobile app design</h1>
                                            <p className="px-4 para">Modern mobile business requirements are dynamic, requiring custom app development solutions.
                                                <br />
                                                As a native app development company, Neurastats creates innovative, custom-built applications, with flawless designs and friendly user interfaces for a tailored, audience-specific solution.
                                                <br />
                                                Custom mobile apps improve efficiency, increase conversions, retain customers, and help you exceed your business requirements. They deliver high scalability and unique functions and features, which is why custom mobile app development is the best choice to help your business thrive in a tough market.
                                                <br />
                                                Our team of mobile app developers have been in the game for over a decade, so they know all about making a difference where it counts.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>

                                            <p className="px-4"><a href="#one" className="content-a">Mobile app development - from idea to execution</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">Mobile development company</a></p>
                                            <p className="px-4"><a href="#three" className="content-a">How to choose a mobile app development company?</a></p>
                                            <p className="px-4"><a href="#four" className="content-a">Custom mobile app design</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Python Development"} paraone={"Python is currently one of the fastest growing languages to get your job done on time."} subTitletwo={"iOS development"} paratwo={"Develop top-quality iOS apps with cutting edge technology."} fcode1={<i class="fab fa-python"></i>} fcode2={<i class="fab fa-apple"></i>} linkone={"/service/wd/py"} linktwo={"/service/md/IOS"} />
        </>
    )
}

export default PY
