import React from 'react'

function Whyus() {

    var i, icon, elements;
    elements = document.getElementsByClassName("whyPara");
    icon = document.getElementsByClassName("arrowIcon");
    function textChange(ele, id) {

        console.log(id);

        if (document.getElementById(id).className.includes(" rotate")) {
            console.log("yes");
            document.getElementById(id).className = document.getElementById(id).className.replace(" rotate", "")
            ele.className = ele.className.replace(" aboutActive", "")

        }
        else {
            for (i = 0; i < icon.length; i++) {
                icon[i].className = icon[i].className.replace(" rotate", "");
            }
            for (i = 0; i < elements.length; i++) {
                elements[i].className = elements[i].className.replace(" aboutActive", "");
            }
            document.getElementById(id).classList.add("rotate")
            console.log(ele);
            ele.className += " aboutActive";
        }




    }

    return (
        <>
            <section className="whyus-section odd py-5">
                <div className="container-fluid px-4">
                    <div className="container-fluid px-5">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">  <h1 className="heading2">Why Choose Us?</h1></div>
                            <div className="col-lg-6 col-md-6">  <p className="whyus-head-para">
                                It’s our attention to the small stuff, scheduling of timelines and keen project management that makes us stand out from the rest. We are creative, while keeping a close eye on the calendar and your budget.
                            </p></div>
                        </div>


                    </div>
                    <div className="container-fluid whyus-pic-part">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="container">
                                    <svg width="650" height="625" className="whyus-img " viewBox="0 0 650 625" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="about">
                                            <path id="Vector" d="M491.035 332.028C514.241 318.456 545.164 315.62 567.517 330.555C539.611 339.267 515.685 357.569 499.973 382.223C493.948 391.753 488.239 402.98 477.539 406.534C470.881 408.746 463.451 407.382 457.129 404.34C450.808 401.297 445.388 396.7 440.045 392.153L438.339 391.759C451.25 368.179 467.829 345.6 491.035 332.028Z" fill="#F0F0F0" />
                                            <path id="Vector_2" d="M567.27 331.062C545.082 329.378 522.927 334.729 503.953 346.354C499.824 348.784 496.104 351.85 492.93 355.439C489.95 359.034 487.988 363.362 487.247 367.972C486.47 372.257 486.171 376.73 484.365 380.752C483.411 382.796 482.035 384.616 480.327 386.092C478.62 387.567 476.62 388.665 474.459 389.314C469.115 391.069 463.459 390.8 457.937 390.345C451.807 389.84 445.447 389.217 439.576 391.499C438.865 391.776 438.478 390.656 439.188 390.38C449.401 386.41 460.356 390.893 470.845 389.004C475.739 388.123 480.397 385.761 482.811 381.232C484.922 377.271 485.234 372.659 485.984 368.314C486.641 363.806 488.392 359.527 491.083 355.851C494.065 352.113 497.661 348.909 501.717 346.377C510.759 340.53 520.663 336.142 531.068 333.372C542.925 330.146 555.247 328.971 567.5 329.899C568.258 329.957 568.024 331.119 567.27 331.062L567.27 331.062Z" fill="white" />
                                            <path id="Vector_3" d="M509.843 342.251C508.324 338.803 508.081 334.928 509.157 331.317C510.232 327.706 512.556 324.595 515.714 322.539C516.354 322.126 517.027 323.101 516.386 323.515C513.442 325.424 511.277 328.322 510.283 331.687C509.288 335.051 509.529 338.661 510.962 341.863C511.273 342.559 510.151 342.944 509.843 342.251H509.843Z" fill="white" />
                                            <path id="Vector_4" d="M487.355 365.748C494.509 368.602 502.475 368.654 509.666 365.896C510.378 365.622 510.766 366.741 510.054 367.015C502.566 369.872 494.276 369.8 486.838 366.814C486.129 366.529 486.65 365.464 487.355 365.748V365.748Z" fill="white" />
                                            <path id="Vector_5" d="M542.701 331.701C543.011 333.157 543.677 334.514 544.64 335.649C545.604 336.785 546.834 337.663 548.221 338.206C548.933 338.482 548.411 339.546 547.704 339.272C546.175 338.665 544.817 337.694 543.75 336.442C542.683 335.19 541.938 333.695 541.582 332.089C541.535 331.94 541.547 331.78 541.615 331.64C541.683 331.5 541.802 331.391 541.947 331.335C542.096 331.284 542.258 331.294 542.399 331.363C542.54 331.431 542.649 331.553 542.701 331.701V331.701Z" fill="white" />
                                            <path id="Vector_6" d="M576.784 421.104C576.334 420.945 575.885 420.785 575.427 420.631C569.373 418.515 563.157 416.895 556.841 415.786C556.354 415.693 555.859 415.605 555.37 415.526C540.124 413.017 524.545 413.36 509.424 416.537C503.408 417.808 497.497 419.533 491.743 421.7C483.797 424.693 475.42 428.571 467.191 428.758C466.333 428.787 465.473 428.763 464.618 428.686L437.99 393.872C437.97 393.762 437.941 393.658 437.92 393.549L436.793 392.207C437.082 392.075 437.384 391.944 437.673 391.811C437.841 391.733 438.014 391.664 438.181 391.586C438.294 391.537 438.408 391.488 438.508 391.437C438.545 391.421 438.583 391.404 438.613 391.394C438.713 391.343 438.811 391.306 438.902 391.262C440.591 390.524 442.288 389.796 443.994 389.079C444.002 389.073 444.002 389.073 444.016 389.075C457.023 383.646 470.598 379.256 484.408 377.065C484.824 377 485.247 376.927 485.674 376.878C491.905 375.925 498.203 375.483 504.505 375.556C507.956 375.607 511.402 375.833 514.83 376.233C523.685 377.282 532.32 379.718 540.419 383.452C556.562 390.896 570.144 403.42 576.324 419.845C576.482 420.265 576.634 420.678 576.784 421.104Z" fill="#F0F0F0" />
                                            <path id="Vector_7" d="M576.282 421.364C559.579 406.661 538.668 397.595 516.52 395.453C511.76 394.907 506.944 395.115 502.248 396.069C497.705 397.147 493.532 399.421 490.165 402.656C486.965 405.609 484.033 409 480.17 411.124C478.177 412.182 475.982 412.806 473.731 412.957C471.479 413.107 469.221 412.78 467.105 411.996C461.782 410.181 457.428 406.561 453.293 402.873C448.702 398.778 443.999 394.452 437.938 392.739C437.204 392.532 437.569 391.404 438.302 391.612C448.846 394.591 454.894 404.766 464.406 409.573C468.844 411.816 473.986 412.735 478.64 410.572C482.71 408.68 485.736 405.186 488.951 402.168C492.19 398.964 496.164 396.601 500.526 395.287C505.157 394.097 509.957 393.704 514.721 394.125C525.46 394.9 536.01 397.36 545.985 401.413C557.395 405.975 567.94 412.456 577.165 420.574C577.736 421.077 576.849 421.863 576.282 421.364H576.282Z" fill="white" />
                                            <path id="Vector_8" d="M523.692 395.723C524.556 392.056 526.695 388.815 529.728 386.579C532.761 384.344 536.489 383.259 540.248 383.519C540.401 383.531 540.544 383.602 540.646 383.717C540.748 383.832 540.801 383.982 540.794 384.135C540.788 384.289 540.722 384.434 540.61 384.54C540.499 384.646 540.351 384.704 540.197 384.703C536.697 384.454 533.224 385.465 530.404 387.553C527.585 389.64 525.604 392.667 524.82 396.087C524.649 396.83 523.522 396.462 523.692 395.723V395.723Z" fill="white" />
                                            <path id="Vector_9" d="M491.591 400.945C495.584 407.53 501.913 412.369 509.316 414.495C510.049 414.705 509.684 415.832 508.952 415.623C501.253 413.395 494.677 408.347 490.536 401.485C490.142 400.83 491.198 400.294 491.591 400.945V400.945Z" fill="white" />
                                            <path id="Vector_10" d="M556.28 407.082C555.65 408.431 555.365 409.916 555.451 411.402C555.537 412.889 555.99 414.331 556.771 415.599C557.173 416.248 556.116 416.784 555.716 416.139C554.86 414.734 554.361 413.141 554.263 411.499C554.165 409.856 554.47 408.215 555.152 406.718C555.205 406.572 555.311 406.451 555.45 406.38C555.588 406.309 555.749 406.293 555.898 406.336C556.047 406.385 556.171 406.491 556.243 406.63C556.314 406.77 556.328 406.932 556.28 407.082V407.082Z" fill="white" />
                                            <path id="Vector_11" d="M39.1875 544.47C41.9101 517.725 57.8541 491.078 83.0973 481.831C73.6775 509.507 74.3507 539.623 84.9978 566.85C89.142 577.336 94.8431 588.567 91.404 599.305C89.2642 605.986 83.7811 611.183 77.5964 614.494C71.4114 617.806 64.5026 619.472 57.6799 621.106L56.3556 622.252C44.9248 597.92 36.4649 571.216 39.1875 544.47Z" fill="#F0F0F0" />
                                            <path id="Vector_12" d="M83.3609 482.33C68.9177 499.258 60.1753 520.306 58.3757 542.485C57.9035 547.253 58.1857 552.065 59.2121 556.745C60.3592 561.271 62.6976 565.408 65.9841 568.725C68.9864 571.88 72.4227 574.759 74.6059 578.588C75.6943 580.565 76.3526 582.749 76.5375 584.998C76.7225 587.247 76.4299 589.51 75.6791 591.638C73.9461 596.989 70.3938 601.398 66.7701 605.589C62.7466 610.243 58.4938 615.013 56.875 621.099C56.6789 621.837 55.5461 621.489 55.7419 620.753C58.5583 610.163 68.6386 603.959 73.2982 594.374C75.4725 589.902 76.3121 584.747 74.0774 580.127C72.1232 576.086 68.5822 573.114 65.5148 569.947C62.2612 566.758 59.8379 562.821 58.4567 558.479C57.1954 553.867 56.7281 549.073 57.0751 544.304C57.6848 533.554 59.981 522.968 63.8794 512.931C68.2651 501.452 74.5822 490.808 82.5573 481.459C83.0511 480.881 83.8515 481.755 83.3609 482.33V482.33Z" fill="white" />
                                            <path id="Vector_13" d="M58.5348 535.309C54.8546 534.502 51.5812 532.413 49.299 529.415C47.0169 526.417 45.8747 522.706 46.0764 518.944C46.0864 518.791 46.1553 518.647 46.2687 518.544C46.3821 518.441 46.5312 518.385 46.6845 518.39C46.8379 518.394 46.9836 518.457 47.0912 518.567C47.1987 518.676 47.2596 518.823 47.2611 518.976C47.0668 522.479 48.1311 525.937 50.262 528.724C52.3928 531.511 55.4498 533.445 58.8811 534.176C59.6266 534.336 59.276 535.468 58.5348 535.309V535.309Z" fill="white" />
                                            <path id="Vector_14" d="M64.2512 567.326C70.7744 563.232 75.5145 556.829 77.5264 549.394C77.7252 548.657 78.858 549.005 78.6595 549.741C76.5514 557.473 71.605 564.126 64.8075 568.372C64.1593 568.777 63.6065 567.728 64.2512 567.326H64.2512Z" fill="white" />
                                            <path id="Vector_15" d="M69.3892 502.55C70.748 503.159 72.2369 503.421 73.722 503.312C75.2072 503.203 76.6421 502.728 77.8979 501.927C78.5406 501.515 79.0928 502.564 78.4542 502.974C77.0626 503.851 75.4773 504.375 73.8367 504.498C72.1961 504.622 70.5502 504.342 69.0428 503.683C68.8955 503.633 68.7729 503.528 68.6998 503.391C68.6267 503.253 68.6087 503.093 68.6494 502.943C68.696 502.793 68.7999 502.668 68.9384 502.594C69.0769 502.521 69.2389 502.505 69.3892 502.55V502.55Z" fill="white" />
                                            <path id="Vector_16" d="M161.693 527.742C161.299 528.011 160.905 528.28 160.511 528.559C155.232 532.201 150.258 536.265 145.638 540.713C145.275 541.051 144.913 541.399 144.561 541.747C133.544 552.581 124.634 565.366 118.284 579.452C115.763 585.06 113.671 590.851 112.028 596.776C109.76 604.958 107.951 614.011 103.251 620.767C102.768 621.478 102.242 622.157 101.676 622.803L57.8563 623.779C57.7556 623.731 57.6546 623.693 57.5534 623.646L55.8057 623.765C55.8691 623.453 55.9415 623.132 56.0049 622.82C56.0409 622.639 56.0867 622.458 56.1227 622.277C56.1498 622.157 56.1774 622.036 56.195 621.926C56.2038 621.885 56.2132 621.845 56.2223 621.815C56.2399 621.705 56.2679 621.604 56.2857 621.503C56.6856 619.704 57.0989 617.904 57.5256 616.104C57.5253 616.094 57.5254 616.095 57.5349 616.084C60.8207 602.378 65.2797 588.825 71.6539 576.38C71.8458 576.006 72.037 575.621 72.2489 575.246C75.1533 569.653 78.51 564.306 82.2855 559.259C84.3617 556.502 86.5761 553.852 88.9203 551.319C94.9899 544.786 102.049 539.248 109.84 534.909C125.371 526.261 143.495 522.676 160.405 527.371C160.837 527.491 161.26 527.612 161.693 527.742Z" fill="#F0F0F0" />
                                            <path id="Vector_17" d="M161.607 528.3C139.883 533.12 120.23 544.663 105.44 561.288C102.193 564.811 99.5205 568.823 97.5224 573.177C95.7131 577.482 95.0895 582.193 95.7165 586.82C96.2144 591.147 97.2247 595.514 96.6623 599.886C96.3412 602.12 95.5516 604.26 94.3452 606.167C93.1388 608.074 91.543 609.705 89.6622 610.952C85.0569 614.181 79.5659 615.563 74.1492 616.728C68.135 618.021 61.8677 619.269 56.9108 623.154C56.3102 623.624 55.6148 622.665 56.2145 622.195C64.8388 615.435 76.6225 616.551 86.1139 611.703C90.5427 609.441 94.3167 605.831 95.3141 600.796C96.1863 596.394 95.1483 591.889 94.6063 587.513C93.9285 583.008 94.3641 578.405 95.8751 574.107C97.645 569.665 100.158 565.557 103.306 561.957C110.265 553.742 118.473 546.671 127.628 541.005C138.041 534.48 149.493 529.784 161.49 527.121C162.232 526.957 162.345 528.137 161.607 528.3H161.607Z" fill="white" />
                                            <path id="Vector_18" d="M109.887 555.654C107.435 552.794 106.079 549.155 106.062 545.388C106.044 541.62 107.367 537.969 109.793 535.087C110.286 534.505 111.213 535.244 110.719 535.826C108.455 538.506 107.224 541.907 107.247 545.416C107.27 548.924 108.547 552.308 110.846 554.958C111.345 555.535 110.384 556.228 109.887 555.654Z" fill="white" />
                                            <path id="Vector_19" d="M95.1751 584.66C102.849 585.318 110.488 583.059 116.571 578.335C117.173 577.866 117.869 578.826 117.267 579.294C110.928 584.198 102.973 586.533 94.9895 585.83C94.2285 585.763 94.4183 584.593 95.1751 584.66V584.66Z" fill="white" />
                                            <path id="Vector_20" d="M138.277 536.033C138.996 537.337 140.027 538.443 141.278 539.25C142.529 540.058 143.961 540.542 145.446 540.659C146.207 540.717 146.017 541.887 145.26 541.829C143.621 541.692 142.04 541.156 140.655 540.267C139.271 539.378 138.125 538.163 137.319 536.729C137.231 536.6 137.196 536.443 137.221 536.29C137.245 536.136 137.327 535.997 137.45 535.902C137.577 535.81 137.736 535.773 137.891 535.797C138.046 535.822 138.185 535.907 138.277 536.033V536.033Z" fill="white" />
                                            <g id="Group 1">
                                                <path id="Path 1" d="M376.905 73.51L141.02 50.308C138.288 50.0243 135.647 49.1622 133.273 47.779C130.824 46.354 128.654 44.4974 126.866 42.299C123.024 37.5998 120.916 31.7212 120.896 25.651C120.894 24.1725 121.025 22.6968 121.289 21.242C121.542 19.8485 121.928 18.4825 122.442 17.163C122.94 15.8863 123.567 14.6639 124.314 13.515C125.049 12.3862 125.904 11.3402 126.863 10.395C127.797 9.47434 128.831 8.66217 129.947 7.97402C132.12 6.63468 134.576 5.82267 137.118 5.60302C138.418 5.49157 139.726 5.52512 141.018 5.70303L376.905 37.611C378.706 37.8784 380.411 38.5925 381.864 39.688C383.456 40.8827 384.818 42.3575 385.881 44.04C387.066 45.8933 387.973 47.9104 388.573 50.027C389.227 52.3066 389.558 54.6665 389.557 57.038C389.559 58.1936 389.478 59.3478 389.317 60.492C389.164 61.5824 388.928 62.6595 388.611 63.714C388.31 64.7179 387.925 65.6947 387.46 66.634C387.017 67.5311 386.488 68.3839 385.883 69.181C385.318 69.9281 384.672 70.6112 383.958 71.218C383.296 71.7802 382.566 72.2572 381.785 72.638C380.271 73.3769 378.581 73.6785 376.904 73.509L376.905 73.51Z" fill="#E4E4E4" />
                                                <path id="Path 2" d="M272.061 63.198L141.02 50.308C138.288 50.0243 135.647 49.1622 133.273 47.779C130.824 46.354 128.654 44.4974 126.866 42.299C123.024 37.5998 120.916 31.7212 120.896 25.651C120.894 24.1725 121.025 22.6968 121.289 21.242C121.542 19.8485 121.928 18.4825 122.442 17.163C122.94 15.8863 123.567 14.6639 124.314 13.515C125.049 12.3862 125.904 11.3401 126.863 10.395C127.797 9.47432 128.831 8.66215 129.947 7.97401C132.12 6.63466 134.576 5.82265 137.118 5.603C138.418 5.49155 139.726 5.5251 141.018 5.70301L272.058 23.432L272.061 63.198Z" fill="#263238" />
                                                <path id="Path 3" d="M271.726 66.127C271.12 66.0638 270.538 65.8582 270.026 65.527C269.487 65.1791 269.017 64.7338 268.64 64.214C268.231 63.6523 267.913 63.0288 267.7 62.367C267.471 61.6624 267.354 60.926 267.354 60.185V25.339C267.35 24.6245 267.466 23.9145 267.699 23.239C267.905 22.6415 268.224 22.0896 268.64 21.614C269.019 21.1764 269.495 20.8338 270.03 20.614C270.57 20.4 271.156 20.3311 271.73 20.414C272.337 20.5037 272.915 20.7316 273.42 21.08C273.956 21.4509 274.422 21.9149 274.794 22.45C275.198 23.0219 275.511 23.6526 275.722 24.32C275.947 25.0238 276.062 25.7582 276.062 26.497V61.062C276.066 61.776 275.951 62.4858 275.722 63.162C275.519 63.7658 275.204 64.3261 274.794 64.814C274.419 65.2616 273.948 65.6188 273.416 65.859C272.885 66.0925 272.303 66.1848 271.726 66.127Z" fill="#3F3D56" />
                                                <path id="Path 4" d="M62.1062 37.524C61.8728 37.5001 61.6413 37.4603 61.4132 37.405C61.1797 37.3482 60.9502 37.276 60.7262 37.189C60.4959 37.0997 60.2714 36.9958 60.0542 36.878C59.8295 36.7561 59.6124 36.6205 59.4042 36.472C58.1455 35.5751 57.2495 34.2573 56.8782 32.757C56.7551 32.258 56.693 31.746 56.6932 31.232V5.06602C56.6914 4.56714 56.7536 4.07008 56.8782 3.58702C56.9954 3.13097 57.1755 2.69344 57.4132 2.28702C57.8796 1.49302 58.5735 0.85726 59.4052 0.46202C59.8231 0.262104 60.2683 0.124962 60.7262 0.0550227C61.1834 -0.0140574 61.648 -0.0181061 62.1062 0.043014C62.5797 0.106549 63.0427 0.23231 63.4832 0.417022C63.9495 0.612592 64.3901 0.864462 64.7952 1.16702L85.6932 16.692C86.0667 16.9699 86.4088 17.2878 86.7132 17.64C87.3164 18.3373 87.7634 19.1557 88.0242 20.04C88.1549 20.4835 88.2371 20.9398 88.2692 21.401C88.2692 21.4378 88.2716 21.4745 88.2762 21.511C88.2809 21.5475 88.2809 21.5845 88.2762 21.621V21.842C88.2779 22.3291 88.2188 22.8145 88.1002 23.287C87.9882 23.7323 87.8166 24.1605 87.5902 24.56C87.3703 24.9473 87.0958 25.3009 86.7752 25.61C86.4546 25.9204 86.09 26.1819 85.6932 26.386L64.7932 37.027C64.5851 37.1329 64.3695 37.2234 64.1482 37.298C63.93 37.3717 63.7068 37.4296 63.4802 37.471C63.2543 37.5123 63.0257 37.5373 62.7962 37.546C62.566 37.5548 62.3354 37.5474 62.1062 37.524V37.524Z" fill="#CACACA" />
                                            </g>
                                            <g id="Group 2">
                                                <path id="Path 5" d="M376.951 187.445L141.091 191.893C138.42 191.942 135.772 191.388 133.345 190.272C130.912 189.145 128.732 187.537 126.938 185.543C125.054 183.454 123.575 181.032 122.577 178.402C120.433 172.727 120.433 166.464 122.577 160.789C123.575 158.158 125.054 155.736 126.938 153.646C128.732 151.651 130.911 150.04 133.345 148.911C135.772 147.793 138.42 147.237 141.091 147.285L376.951 151.548C378.709 151.593 380.422 152.111 381.91 153.048C383.499 154.052 384.866 155.37 385.926 156.921C387.121 158.655 388.029 160.57 388.617 162.592C389.929 167.096 389.929 171.88 388.617 176.384C388.029 178.407 387.121 180.323 385.926 182.058C384.866 183.613 383.5 184.935 381.91 185.943C380.422 186.881 378.709 187.4 376.951 187.445V187.445Z" fill="#E4E4E4" />
                                                <g id="Group 3">
                                                    <path id="Path 6" d="M239 190.244L142.142 191.997C139.335 192.047 136.553 191.488 134.003 190.362C131.446 189.225 129.156 187.603 127.272 185.592C125.292 183.485 123.738 181.042 122.69 178.389C121.566 175.553 120.993 172.542 121 169.507C120.999 168 121.137 166.497 121.413 165.014C121.682 163.565 122.088 162.143 122.624 160.764C123.152 159.408 123.811 158.102 124.591 156.864C125.366 155.634 126.263 154.479 127.268 153.415C128.247 152.376 129.333 151.434 130.509 150.604C131.639 149.807 132.854 149.128 134.133 148.577C136.649 147.492 139.384 146.954 142.142 147.003L239 148.683V190.244Z" fill="#263238" />
                                                    <path id="Path 7" d="M234.355 192.762C233.726 192.775 233.102 192.634 232.539 192.352C231.966 192.062 231.459 191.655 231.051 191.158C230.611 190.623 230.269 190.013 230.044 189.358C229.797 188.646 229.672 187.898 229.674 187.144V151.084C229.672 150.33 229.797 149.581 230.044 148.868C230.269 148.213 230.611 147.603 231.051 147.068C231.459 146.573 231.966 146.167 232.539 145.878C233.102 145.596 233.726 145.455 234.355 145.468C234.989 145.483 235.609 145.65 236.165 145.954C236.735 146.267 237.237 146.691 237.64 147.201C238.074 147.747 238.41 148.363 238.633 149.023C238.876 149.735 238.999 150.482 238.997 151.234V186.994C238.999 187.746 238.876 188.493 238.633 189.205C238.41 189.865 238.074 190.482 237.64 191.028C237.237 191.539 236.735 191.963 236.165 192.277C235.609 192.581 234.989 192.747 234.355 192.762V192.762Z" fill="#3F3D56" />
                                                </g>
                                                <path id="Path 8" d="M62.1862 188.359C61.7204 188.366 61.2559 188.308 60.8062 188.186C60.5771 188.124 60.3526 188.047 60.1342 187.954C59.9106 187.859 59.6938 187.749 59.4852 187.624C59.0674 187.376 58.6847 187.073 58.3472 186.724C58.0133 186.376 57.7265 185.986 57.4942 185.564C57.2586 185.135 57.0788 184.678 56.9592 184.204C56.8355 183.714 56.7733 183.21 56.7742 182.704V156.538C56.7734 156.032 56.8355 155.528 56.9592 155.038C57.079 154.564 57.2588 154.108 57.4942 153.68C57.9614 152.827 58.6495 152.116 59.4862 151.621C59.8985 151.372 60.343 151.182 60.8072 151.054C61.7115 150.809 62.6662 150.823 63.5632 151.093C64.025 151.232 64.4659 151.433 64.8742 151.69L85.7742 164.764L85.8492 164.812L85.9242 164.861L85.9982 164.911L86.0712 164.962C86.4067 165.205 86.7131 165.485 86.9842 165.798C87.255 166.11 87.4897 166.451 87.6842 166.815C87.8804 167.184 88.0356 167.573 88.1472 167.976C88.2621 168.39 88.3325 168.815 88.3572 169.244V169.609C88.358 170.103 88.2989 170.595 88.1812 171.074C88.0671 171.537 87.8958 171.985 87.6712 172.406C87.4499 172.821 87.1764 173.206 86.8572 173.551C86.5348 173.898 86.1679 174.2 85.7662 174.451L64.8742 187.548C64.6675 187.678 64.4524 187.794 64.2302 187.895C64.0135 187.994 63.7903 188.078 63.5622 188.146C63.3381 188.213 63.1095 188.265 62.8782 188.301C62.6492 188.336 62.418 188.356 62.1862 188.359V188.359Z" fill="#CACACA" />
                                            </g>
                                            <path id="Path 9" d="M376.905 300.999L141.02 333.006C138.458 333.376 135.845 333.143 133.388 332.328C130.931 331.512 128.698 330.135 126.865 328.306C124.969 326.42 123.484 324.163 122.503 321.675C121.423 318.932 120.878 316.008 120.896 313.06C120.916 306.989 123.024 301.11 126.866 296.41C128.654 294.211 130.824 292.353 133.273 290.927C135.647 289.543 138.287 288.68 141.02 288.395L376.905 265.101C378.612 264.93 380.332 265.247 381.864 266.017C383.447 266.831 384.822 267.999 385.881 269.429C387.086 271.043 387.997 272.856 388.573 274.786C389.238 276.985 389.57 279.27 389.557 281.567C389.558 283.939 389.227 286.298 388.573 288.578C387.973 290.695 387.066 292.712 385.881 294.566C384.818 296.249 383.456 297.725 381.864 298.92C380.411 300.016 378.706 300.731 376.905 300.999V300.999Z" fill="#E4E4E4" />
                                            <path id="Path 10" d="M339.815 306.031L141.02 333.005C138.458 333.375 135.845 333.142 133.388 332.327C130.931 331.511 128.698 330.134 126.865 328.305C124.969 326.419 123.484 324.162 122.503 321.674C121.424 318.932 120.878 316.01 120.896 313.063C120.897 311.554 121.028 310.049 121.289 308.563C122.07 304.099 123.99 299.911 126.863 296.406C127.795 295.268 128.827 294.216 129.947 293.263C131.018 292.35 132.172 291.54 133.396 290.846C134.578 290.177 135.825 289.628 137.117 289.21C138.385 288.8 139.692 288.527 141.017 288.395L339.815 268.763V306.031Z" fill="#263238" />
                                            <path id="Path 12" d="M62.1052 338.691C61.8755 338.721 61.6439 338.736 61.4122 338.734C61.1826 338.732 60.9533 338.714 60.7262 338.68C60.2682 338.61 59.8231 338.473 59.4052 338.273C58.5729 337.877 57.8789 337.24 57.4132 336.444C57.1754 336.038 56.9954 335.6 56.8782 335.144C56.754 334.663 56.6919 334.168 56.6932 333.671V307.501C56.6932 306.988 56.7553 306.477 56.8782 305.979C57.2501 304.478 58.1464 303.16 59.4052 302.263C59.8139 301.97 60.2577 301.729 60.7262 301.546C61.1692 301.373 61.6333 301.261 62.1062 301.211C62.5654 301.163 63.029 301.181 63.4832 301.263C63.9391 301.346 64.3804 301.496 64.7932 301.706L85.6932 312.339L85.7692 312.378L85.8442 312.419L85.9182 312.461L85.9912 312.504C86.3082 312.696 86.6004 312.927 86.8612 313.19C87.1211 313.453 87.3487 313.745 87.5392 314.062C87.7339 314.386 87.8912 314.731 88.0082 315.091C88.1303 315.467 88.2121 315.855 88.2522 316.248C88.2576 316.3 88.2622 316.352 88.2662 316.404C88.2702 316.456 88.2735 316.509 88.2762 316.562C88.2762 316.615 88.2782 316.668 88.2822 316.721C88.2862 316.775 88.2862 316.828 88.2822 316.882C88.2822 317.383 88.2231 317.881 88.1062 318.368C87.8768 319.335 87.4275 320.237 86.7932 321.002C86.4685 321.388 86.0993 321.734 85.6932 322.033L64.7932 337.566C64.5868 337.719 64.3715 337.86 64.1482 337.988C63.9326 338.111 63.7095 338.221 63.4802 338.317C63.2575 338.41 63.029 338.489 62.7962 338.552C62.5692 338.614 62.3384 338.66 62.1052 338.691V338.691Z" fill="#CACACA" />
                                            <path id="Vector_21" d="M338.381 279.26C339.836 279.051 341.32 279.162 342.727 279.588C344.134 280.014 345.431 280.743 346.526 281.724C347.62 282.705 348.487 283.914 349.064 285.266C349.641 286.618 349.915 288.08 349.866 289.549L383.215 302.386L368.106 313.181L338.753 299.336C336.29 299.09 334.003 297.949 332.325 296.129C330.647 294.31 329.696 291.938 329.65 289.463C329.604 286.989 330.468 284.583 332.077 282.703C333.686 280.822 335.929 279.597 338.381 279.26H338.381Z" fill="#FFB6B6" />
                                            <path id="Vector_22a" d="M465.01 272.959L470.652 283.216C469.288 289.722 465.765 295.576 460.653 299.826C452.015 307.111 406.849 334.244 406.849 334.244L349.829 305.618L359.804 292.363L404.57 307.366L429.824 283.519L465.01 272.959Z" fill="#3F3D56" />
                                            <path id="Vector_23" d="M470.191 433.808L464.295 434.919L499.847 623.599L505.743 622.488L470.191 433.808Z" fill="#CACACA" />
                                            <path id="Vector_24" d="M411.504 622.488L417.4 623.599L452.952 434.919L447.056 433.808L411.504 622.488Z" fill="#CACACA" />
                                            <path id="Vector_25" d="M454.519 442.697C479.095 442.697 499.019 436.206 499.019 428.197C499.019 420.189 479.095 413.697 454.519 413.697C429.942 413.697 410.019 420.189 410.019 428.197C410.019 436.206 429.942 442.697 454.519 442.697Z" fill="#CACACA" />
                                            <path id="Vector_26" d="M360.004 611.715L347.744 611.714L341.912 564.426L360.006 564.427L360.004 611.715Z" fill="#FFB6B6" />
                                            <path id="Vector_27" d="M363.13 623.599L323.599 623.597V623.097C323.6 619.017 325.221 615.103 328.106 612.218C330.992 609.332 334.905 607.711 338.986 607.711H338.987L363.131 607.712L363.13 623.599Z" fill="#2F2E41" />
                                            <path id="Vector_28" d="M419.518 547.373L408.809 553.342L380.689 514.878L396.494 506.068L419.518 547.373Z" fill="#FFB6B6" />
                                            <path id="Vector_29" d="M428.035 556.23L393.507 575.477L393.263 575.041C391.276 571.476 390.787 567.269 391.902 563.344C393.017 559.418 395.646 556.097 399.21 554.11L399.211 554.109L420.3 542.354L428.035 556.23Z" fill="#2F2E41" />
                                            <path id="Vector_30" d="M489.151 392.746C489.151 392.746 495.096 411.164 458.623 434.455L411.651 481.261L362.845 598.01L333.869 585.02L378.428 466.078L404.486 420.454L405.242 395.697L411.651 378.246L458.151 361.746L489.151 392.746Z" fill="#2F2E41" />
                                            <path id="Vector_31" d="M393.781 546.608L345.301 473.516C343.075 470.153 342.089 466.119 342.513 462.108C342.937 458.097 344.745 454.359 347.626 451.536L396.712 403.52L405.035 395.197H423.659L414.926 427.419L384.629 461.761L419.653 523.739L393.781 546.608Z" fill="#2F2E41" />
                                            <path id="Vector_32a" d="M429.411 292.603L444.019 271.697L477.398 269.012C477.398 269.012 506.093 296.048 500.872 331.647C495.651 367.246 490.651 402.246 490.651 402.246C490.651 402.246 438.651 365.246 411.651 378.246L429.411 292.603Z" fill="#3F3D56" />
                                            <g id="hand">
                                                <path id="Vector_33a" d="M419.493 401.671C419.752 400.223 420.325 398.851 421.172 397.649C422.019 396.447 423.119 395.446 424.395 394.715C425.67 393.985 427.091 393.543 428.556 393.421C430.021 393.298 431.495 393.499 432.874 394.008L455.553 366.392L461.045 384.131L438.667 407.636C437.658 409.896 435.855 411.708 433.6 412.728C431.345 413.747 428.794 413.905 426.43 413.169C424.067 412.434 422.056 410.857 420.777 408.738C419.499 406.619 419.042 404.104 419.493 401.671V401.671Z" fill="#FFB6B6" />
                                                <path id="Vector_34a" d="M448.045 399.876L438.056 385.448L466.421 347.629L451.809 316.318L452.873 279.074L464.947 276.843L465.097 276.919C470.967 280.298 475.505 285.583 477.957 291.897C480.969 299.427 487.693 327.696 491.433 343.876C492.168 347.011 492.088 350.282 491.2 353.378C490.312 356.474 488.646 359.29 486.362 361.56L448.045 399.876Z" fill="#3F3D56" />
                                            </g>
                                            <g id="head">
                                                <path id="Vector_35" d="M473.509 253.4C480.009 242.465 476.414 228.331 465.479 221.831C454.544 215.331 440.41 218.926 433.91 229.861C427.409 240.796 431.005 254.93 441.94 261.43C452.875 267.93 467.009 264.335 473.509 253.4Z" fill="#FFB6B6" />
                                                <path id="Vector_36" d="M468.436 233.36C468.192 236.705 471.329 249.038 471.329 249.038C473.628 245.17 470.017 258.023 470.017 258.023C507.747 231.723 477.934 205.3 466.273 207.649C466.516 204.304 457.005 205.629 457.005 205.629C453.381 199.985 445.438 207.477 445.438 207.477L446.971 204.898C440.804 206.467 434.71 225.525 434.71 225.525C436.801 233.747 468.679 230.015 468.436 233.36Z" fill="#2F2E41" />
                                            </g>
                                            <path id="Path 11" d="M338.931 308.533C338.425 308.606 337.909 308.536 337.44 308.333C336.965 308.12 336.546 307.797 336.219 307.391C335.849 306.936 335.568 306.416 335.389 305.858C335.185 305.22 335.084 304.554 335.089 303.885V271.222C335.088 270.53 335.189 269.841 335.389 269.178C335.575 268.563 335.855 267.981 336.219 267.452C336.546 266.97 336.961 266.554 337.441 266.225C337.885 265.921 338.397 265.733 338.931 265.676C339.441 265.626 339.955 265.717 340.417 265.939C340.891 266.172 341.306 266.51 341.629 266.927C341.992 267.392 342.269 267.919 342.445 268.482C342.647 269.119 342.748 269.783 342.745 270.451V302.867C342.745 303.558 342.644 304.245 342.445 304.906C342.262 305.525 341.987 306.114 341.629 306.652C341.305 307.145 340.894 307.575 340.417 307.922C339.978 308.243 339.469 308.452 338.931 308.533V308.533Z" fill="#3F3D56" />
                                            <path id="Vector_37" d="M648.485 624.542H1.19067C0.875482 624.541 0.573504 624.415 0.350952 624.192C0.1284 623.968 0.00344849 623.666 0.00344849 623.351C0.00344849 623.036 0.1284 622.733 0.350952 622.51C0.573504 622.287 0.875482 622.161 1.19067 622.16H648.485C648.8 622.16 649.103 622.286 649.327 622.509C649.55 622.732 649.675 623.035 649.675 623.351C649.675 623.667 649.55 623.97 649.327 624.193C649.103 624.416 648.8 624.542 648.485 624.542Z" fill="#CACACA" />
                                        </g>
                                    </svg>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 about-coll">
                                <div id="accordion">
                                    <div className="card-collapse whyPara">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn collapse-btn whyPara aboutActive" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={e => textChange(e.target, "PlanIcon")}>
                                                    A Plan for Success <span className="arrowIcon rotate" id="PlanIcon"><i className="fas fa-chevron-right "></i></span>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse py-2  show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body py-1">
                                                You want results. We have found that the best way to get them is with up front research – of your company, competitors, target market and customer psychographics. Only after we fully understand you and your customers, do we recommend a plan of attack.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-collapse">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={e => textChange(e.target, "ExpertIcon")}>
                                                    Experts Only <span className="arrowIcon" id="ExpertIcon"><i className="fas fa-chevron-right "></i></span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse " aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body py-1">
                                                First Impressions is comprised of specialists having great in-depth knowledge that hail from various backgrounds. As such, First Impressions will never assign second-tier (or gasp! third tier!)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-collapse">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={e => textChange(e.target, "PriceIcon")}>
                                                    Pricing <span className="arrowIcon" id="PriceIcon"><i className="fas fa-chevron-right "></i></span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse " aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body py-1">
                                                Our prices are competitive and fair. There are no surprise bills. Any unexpected or additional expenses must be pre-approved by you. That’s how we would like to be treated, and that is how our clients are treated.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-collapse">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={e => textChange(e.target, "MeetingIcon")} >
                                                    Meeting Deadlines<span className="arrowIcon" id="MeetingIcon"><i className="fas fa-chevron-right "></i></span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse " aria-labelledby="headingFour" data-parent="#accordion">
                                            <div className="card-body py-1">
                                                We have worked with different clients for many years. Their rule – if we miss a deadline, we’re out. Period. They have some of the tightest turnarounds in the business – and we’ve never missed a single one.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-collapse">
                                        <div className="card-header" id="headingFive">
                                            <h5 className="mb-0">
                                                <button className="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={e => textChange(e.target, "icon7")} >
                                                    Award-Winning<span className="arrowIcon" id="icon7"><i className="fas fa-chevron-right "></i></span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse " aria-labelledby="headingFive" data-parent="#accordion">
                                            <div className="card-body py-1">
                                                We’ve won the award on behalf of our clients having the best products.Work with us, and you’ll work with seasoned professionals – vigilant of deadlines, and committed to exceeding client expectations.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-collapse">
                                        <div className="card-header" id="headingSix">
                                            <h5 className="mb-0">
                                                <button className="btn  collapse-btn whyPara" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" onClick={e => textChange(e.target, "icon6")} >
                                                    24x7 Live Support<span className="arrowIcon" id="icon6"><i className="fas fa-chevron-right "></i></span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseSix" className="collapse " aria-labelledby="headingSix" data-parent="#accordion">
                                            <div className="card-body py-1">
                                                Tomorrow, we will be here for you. We are not a flash-in-the-pan company. We are here to help you resolve all your queries, and we make sure that all the services run without any disruption.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Whyus
