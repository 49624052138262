
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function BI() {

    const [cardData, setcardData] = useState([
        {
            "header": "Access data insights",
            "para": "Rapidly improve decision-making processes with actionable insights based on reliable data."
        },
        {
            "header": "Optimize processes",
            "para": "Increase efficiency and reduce costs with effective processes enabled by powerful digital technologies."
        },
        {
            "header": "Enhance customer experience",
            "para": "Deliver stunning experiences that meet your customers’ increasingly high expectations."
        },
        {
            "header": "Increase agility",
            "para": "Empower employees with tools that support collaboration, innovation, and organizational flexibility."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Business Intelligence: Data-driven Solutions for Your Business</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Transforming data into actionable intelligence that informs an Organization’s strategic and tactical business decisions</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Deliver powerful new experiences to customers and employees
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Digital transformation means more than just digitalizing individual processes. It is a chance to fundamentally change how you deliver value to customers, solve business problems, and support your employees.
                                            <br />
                                            Rethink and redesign existing processes or create new business models to sustainably increase revenue, reduce costs, and enhance efficiency..</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Reinvent what’s possible by doing things in a new (digital) way</h1>
                                    <p className="para text-center">Digital transformation lets you seize opportunities and respond to challenges with innovative tools and a fresh mindset.

                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Effectively reshape your business in a planned and structured manner
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">We will ensure that your digital transformation is successful by relentlessly focusing on measuring and delivering business value through five key stages:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div id="one" className="col-lg-8 col-md-8">
                                        <div className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What is digital transformation?</h1>
                                            <p className="px-4 para">Digital transformation is the integration of digital technologies across the entire organization to create a fundamental change in the way the business operates. It is a chance to define new ways of working, optimize processes, introduce new business models, and reimagine the customer experience. For digital transformations to be successful, they require a shift in organizational culture. Employees should be empowered to develop fresh ways of solving problems and given the freedom to allow innovation to flourish.
                                                <br />
                                                When successful, digital transformation has the power to significantly increase efficiency, reduce costs, enhance customer experience, and create a sustainable competitive advantage. Businesses can also take advantage of opportunities that exist in the market with the creation of new business models and through digital disruption.
                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Where should you start with digital transformation?</h1>
                                            <p className="px-4 para">Developing a digital transformation strategy is a vital and basic step to take before any decisions can be turned into reality. However, business leaders may find it difficult to know what to focus on.
                                                <br />
                                                To create an effective digital transformation strategy, a company needs to find answers to the essential questions of Why, What, and Who. That’s what enables creating a compelling vision and plan for change.
                                                <br />
                                                As McKinsey’s data indicates, a well-planned digital strategy and a good change story make transformation efforts more than three times as likely to be successful. Illustrating the vision helps present three most frequent reasons for a digital transformation failure: failing at employee engagement, having too many priorities, and losing clarity.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>
                                            <p className="px-4"><a href="#one" className="content-a">What is digital transformation?</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">Where should you start with digital transformation?</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Mobile App Development"} paraone={"Grow your business with the right mobile solutions."} subTitletwo={"Node js development"} paratwo={"Build performant web applications based on Javascript – one language for full-stack development."} fcode1={<i class="fas fa-mobile"></i>} fcode2={<i class="fab fa-node"></i>} linkone={"/service/md/MD"} linktwo={"/service/wd/nd"} />
        </>
    )
}

export default BI
