import React from 'react'
import AboutCard from '../Card/AboutCard'
function Header1() {
    return (
        <>
            <section className="about-header">
                <div className="container-fluid about-text-part px-4">
                    <div className="container-fluid about-header-main px-5 py-5">
                        <h1 className="about-heading">About Us</h1>
                        <p className="about-para my-4">Neurastats is one of the quickest and developing organization in India  <br className="br-hide" />  by giving best client service throughout the life of software.
                        </p>

                        <p className="about-para w-75">Share your challenge with our team, and we’ll work with you <br className="br-hide" /> to convey a progressive digital products. Our Customer’s products have been highlighted by top state media.
                        </p>
                    </div>
                </div>
                <div className="container-fluid card-box">
                    <div className="row about-card-row px-5">
                        <div className="col-lg-4 col-md-4 my-2">
                            <AboutCard title="Who Are We" para="We understand the needs and requirements of our customer and resolve the needs through leveraging new technologies in addition to helping our clients to bring great products to market in less time and at less cost." />
                        </div>
                        <div className="col-lg-4 col-md-4 my-2"> <AboutCard title="Our Mission" para="Globally recognized for providing quality Services to our customers worldwide while adhering to our core values by being an vibrant organization where openness, trust, teamwork, simplicity, and innovation are valued and promoted." /></div>
                        <div className="col-lg-4 col-md-4 my-2"> <AboutCard title="What We Do" para="Product Design
Graphics Design
Mobile Application Development
Web Application Development
Social Media Marketing
24*7 Help & Support" /></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header1
