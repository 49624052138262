import React from 'react'
import Navbar from '../Navbar/Navbar'
import Header from "./Header"
import CoreValue from './CoreValue'
import HearThe from './HearThe'
import CareerPath from './CareerPath'
import FAQ from './Faq'
import MainHeader from './MainHeader'
import "./Career.css"
import OpenPos from '../EstimateProject/OpenPos'
function Career() {
    return (
        <>
            <Navbar />
            <MainHeader />
            <Header />
            <CoreValue />
            <HearThe />
            <CareerPath />
            <FAQ />
            <OpenPos />
        </>
    )
}

export default Career
