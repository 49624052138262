import React from 'react'
import "./Card.css"
function Card(props) {
    return (
        <>
            <a href="/casestudy">
                <div className="container-fluid card-home m-2 py-4 ">
                    <div className="container-fluid d-flex justify-content-between">
                        <p className="upper-text-card">{props.auth}</p>
                        <p className="upper-text-card">{props.time}</p>
                    </div>
                    <div className="container">
                        <h1 className="title">{props.title}</h1>
                        <p className="dics ">{props.disc}</p>
                    </div>
                </div>
            </a>
        </>
    )
}

export default Card
