
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import CaseStudy from '../Details/CaseStudy'
import Approch from "../Details/Approch/Approch"
import Expertise from "../Details/Expertise/Expertise"
import Overview from "../Details/Overview/Overview"
import OtherService from '../Details/OtherService'
import OveriewCard from '../Details/Overview/OveriewCard'
function AN() {

    const [cardData, setcardData] = useState([
        {
            "header": "Increase sales",
            "para": "49% of customers are willing to purchase more frequently when machine learning is present."
        },
        {
            "header": "Improve productivity",
            "para": "Machine learning technologies are projected to increase labor productivity by up to 40% by 2035."
        },
        {
            "header": "Analyze large volumes of data",
            "para": "ML gives apps the ability to learn and improve over time. It is extremely adept at processing large volumes of data quickly and identifying patterns and trends."
        },
        {
            "header": "Improve customer satisfaction",
            "para": "75% of enterprises using AI and machine learning tools enhance customer satisfaction by more than 10%."
        }
    ])

    var value = useRef(null)
    console.log(value);
    window.onscroll = function () { myFunction() };
    var lastScrollTop = 0;
    var sticky, navbar;
    function myFunction() {
        const acOve = document.getElementById("acOve")
        const acApp = document.getElementById("acApp")
        const acExp = document.getElementById("acExp")

        console.log(sticky + " stkc");
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        navbar.style.boxShadow = "0px 0px 8px #00000033"
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("fixed")
        }
        else {
            navbar.classList.remove("fixed")
        }
        if (scrollTop < sticky) {
            navbar.style.boxShadow = "none"
        }
        if (scrollTop > lastScrollTop) {
            navbar.style.top = "0"
        } else {
            navbar.style.top = "55px"
        }
        lastScrollTop = scrollTop;

        if (window.pageYOffset > document.getElementById("Overview").offsetTop) {
            acOve.classList.add("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Approch").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.add("achorActive")
            acExp.classList.remove("achorActive")
        }
        if (window.pageYOffset > document.getElementById("Expertise").offsetTop) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.add("achorActive")
        }

        if (window.pageYOffset > (document.getElementById("Expertise").offsetTop + 1000)) {
            acOve.classList.remove("achorActive")
            acApp.classList.remove("achorActive")
            acExp.classList.remove("achorActive")
        }
    }

    useEffect(() => {
        navbar = document.getElementById("serStiky")
        sticky = navbar.offsetTop;
    })

    return (
        <>
            <Navbar />
            <div className="containerd text-center service-headerTwo">
                <h1 className="service-header-heading my-5 mx-auto w-75">Outrank the competition with machine learning solutions</h1>
                <p className="service-header-subHeading my-2 mx-auto w-75">Machine learning solutions are transforming business capabilities. Reimagine what's possible, and leave the competition behind.</p>
            </div>
            <div class="container-fluid px-0 tabs-container">
                <div className="service-fixed-navbar sfn2 px-0 py-2 " id="serStiky" ref={value}>
                    <a href="#Overview" id="acOve">Overview</a>
                    <a href="#Approch" id="acApp">Approach</a>
                    <a href="#Expertise" id="acExp">Expertise</a>
                </div>
                <div className="container-fluid tabs-contents">
                    <section id="Overview" className="serviceTabSection">
                        <section className="Overview-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            Gain a competitive advantage using machine learning applications
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">The use of machine learning in business is increasing rapidly. Smart companies are harnessing the value of their data in machine learning models to reduce costs, optimize processes, and increase customer satisfaction.
                                            <br />
                                            AI and machine learning have the potential to reshape industries – ML algorithms can be used in applications across practically all sectors, from eCommerce to finance, healthcare to education, and cybersecurity to charity.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid benefit-section my-5">
                                <div className="container benefit-header my-5 py-4">
                                    <h1 >Machine learning can be used across practically any sector or industry</h1>
                                    <p className="para text-center">Machine learning can support your business in many ways:

                                    </p>
                                </div>
                                <div className="container-fluid my-5 py-4">
                                    <div className="row">
                                        {
                                            cardData.map((val, ind) => {
                                                return <div className="col-lg-3 col-md-6 text-center"><OveriewCard title={val.header} para={val.para} key={ind} /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
                    <section id="Approch" className="serviceTabSection">
                        <section className="Approch-section">
                            <div className="container-fluid overview-header">

                                <div className="row over-header-row mx-5">
                                    <div className="col-lg-6 col-md-6">
                                        <h1 className="over-heading">
                                            A robust process is our key advantage
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="para">Over the years, our team has tested and implemented a transparent and efficient workflow for machine learning projects. Our processes ensure our customers receive more reproducible results faster and in a more flexible way.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div id="one" className="container-fluid approch-product-header my-5 px-5">
                                            <h1 className="px-4 ">What is machine learning?</h1>
                                            <p className="px-4 para">Machine learning is a set of artificial intelligence techniques that gives web and mobile applications the ability to learn, adapt, and improve over time. It does this by processing vast amounts of data, identifying trends and patterns within it – most of which would not be apparent to a human being – and then making decisions and taking actions to help meet specific objectives.
                                            </p>
                                        </div>
                                        <div id="two" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Why do you need machine learning?</h1>
                                            <p className="px-4 para">Machine learning solutions open your business up to a wide variety of new opportunities. You can use machine learning models to personalize your customer experience, automate processes, gain deeper insights with advanced analytics, and deploy digital solutions that will change the way customers interact with your product.
                                                <br />
                                                Machine learning is widely applied to business problems, reducing costs and increasing customer satisfaction. ML algorithms can be used in applications across practically any industry or sector – from eCommerce to finance, healthcare to education, and cybersecurity to charity services.
                                            </p>
                                        </div>
                                        <div id="three" className="container-fluid approch-subpart px-5">
                                            <h1 className="px-4 ">Are machine learning services right for your business?</h1>
                                            <p className="px-4 para">Machine learning projects are often high-risk projects due to their complex dependencies on data. That is why top companies offering machine learning services conduct feasibility studies to reduce the risk before engaging in a project. In this way, they ensure that sufficient data is available and that predicted outcomes are in line with the project goals.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="container-fluid content-box">
                                            <h1 className="px-4 mb-3">Content</h1>

                                            <p className="px-4"><a href="#one" className="content-a">What is machine learning?</a></p>
                                            <p className="px-4"><a href="#two" className="content-a">Why do you need machine learning?</a></p>
                                            <p className="px-4"><a href="#three" className="content-a">Are machine learning services right for your business?</a></p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="Expertise" className="serviceTabSection">
                        <Expertise />
                    </section>
                </div>
            </div>
            {/* <CaseStudy /> */}
            <OtherService subTitleone={"Mobile App Development"} paraone={"Grow your business with the right mobile solutions."} subTitletwo={"Node js development"} paratwo={"Build performant web applications based on Javascript – one language for full-stack development."} fcode1={<i class="fas fa-mobile"></i>} fcode2={<i class="fab fa-node"></i>} linkone={"/service/md/MD"} linktwo={"/service/wd/nd"} />
        </>
    )
}

export default AN
